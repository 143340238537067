import { useState } from 'react'
import SignupButton from '../Components/SignupButton/SignupButton'
import '../../src/App.css'
import styles from '../Components/ImgContestPage/ImagePage.module.css'
import Backbutton from '../Components/Assets/Back.svg'
import { useLocation, useNavigate } from 'react-router'
import { useUserAuth } from '../context/UserAuthContext'
import Lottie from 'react-lottie-player'
import loadingAnimation from '../assests/loading.json'
import logo from '../assests/goViralWhiteLogo.svg'

function SignupPage() {
  //
  const navigate = useNavigate()
  const location = useLocation()

  const { previousLocation, pageLoading, urlPath } = useUserAuth()
  const pathSharePageCheck = urlPath.split("/")[3]
  const [popup, setpopup] = useState(false)
  
  return (
    <div className="flex widthController flex-col items-center justify-center h-[100vh] font-inter bg-none">
      <div className="flex mainwidth flex-col justify-center items-center">
        <img onClick={() => {
          window.open(previousLocation, "_self")          
        }} className={`${styles.Backbutton} `} src={Backbutton} alt="" />
        {popup && <img onClick={() => {
          setpopup(false)
        }} className={`${styles.Backbutton} ${!popup && "hidden"}`} src={Backbutton} alt="" />}
        <div className=" ">
          <div className={`${styles.rec1} `}></div>
          <div className={`${styles.rec2} `}></div>
          <div className={`${styles.rec3} `}></div>
        </div>
        <span className={styles.festivity}><img src={logo} alt="GoViral.world" /></span>
        <span className="text-[17px] pb-[30px]" style={{ opacity: 0.4 }}>
          The viral aggregator
        </span>
        {location?.state?.like &&
          <span className="text-[17px] text-center border-solid border-2 border-white-500 p-[20px]" style={{ opacity: 0.4 }}>
            Kindly login to give Likes ❤️,<br /> your likes are Valuable to the participants
          </span>
        }
        {location?.state?.submitForm &&
          <span className="text-[17px] text-center border-solid border-2 border-white-500 p-[20px]" style={{ opacity: 0.4 }}>
            Please login to submit your request
          </span>
        }
        {!pageLoading ? (
          <SignupButton popup={popup} setpopup={setpopup} />
        ) : (
          <div className="w-[100vw] max-w-[600px] flex align-center justify-center">
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              style={{ width: 100, height: 100 }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default SignupPage
