import React from "react";
import Navbar from "../Components/HomeOfficial/Navbar";
import SignupBox from "../Components/SignupBox/SignupBox";
import Footer from "../Components/HomeForNew2.0/Footer";
import { useLocation, useNavigate } from "react-router";
import BackHeader from "../Components/BackHeader/BackHeader";

export default function SignupPageNew() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <div className="w-[100%] h-[100vh] bg-white">
      <BackHeader handleBack={handleNavigateToPrevLocation} heading="" />

      <div className="w-[100%] h-[100%]">
        <div className="w-[100%] h-auto px-[20px] md:px-[15%]">
          <SignupBox />
        </div>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
}
