import React from "react";
import styles from "./LoadingAnimation.module.css";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

function StatusChangerLoading() {
  return (
    <div className={styles.statusLoadingAnimation}>
      <div className="h-[50px] w-[50px]">
        <LoadingCircle />
      </div>
    </div>
  );
}

export default StatusChangerLoading;
