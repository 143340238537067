import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

export default function CustomVideoPlayer({ fileUrl, thumbnail }) {
  const [playing, setPlaying] = useState(thumbnail ? true : false);
  const playerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // If the player is not intersecting with the viewport, pause it
        if (!entry.isIntersecting) {
          setPlaying(false);
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (playerRef.current) {
      observer.observe(playerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(playerRef.current);
      }
    };
  }, []);
  const handleClick = () => {
    setPlaying((prev) => !prev);
  };
  return (
    <div onClick={handleClick} ref={playerRef} className="w-full h-full">
      <ReactPlayer
        config={{
          file: { attributes: { controlsList: "nodownload" } },
        }}
        playing={playing}
        controls={false}
        width="100%"
        height="100%"
        url={fileUrl}
        // light={thumbnail || true}
        playsinline
      />
      {!playing && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="38"
          viewBox="0 0 36 38"
          fill="none"
          className="pointer-events-none absolute mx-auto my-auto inset-0 cursor-pointer z-10"
        >
          <path
            d="M32.2495 14.1472C33.1301 14.6155 33.8666 15.3145 34.3802 16.1693C34.8938 17.0242 35.1652 18.0027 35.1652 19C35.1652 19.9973 34.8938 20.9759 34.3802 21.8307C33.8666 22.6856 33.1301 23.3846 32.2495 23.8529L8.76084 36.6257C4.97867 38.6845 0.333008 36.0079 0.333008 31.7747V6.2272C0.333008 1.9922 4.97867 -0.682632 8.76084 1.37253L32.2495 14.1472Z"
            fill="white"
          />
        </svg>
      )}
    </div>
  );
}
