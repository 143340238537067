import React from "react";
import styles from "./PriceBox.module.css";
import { isEmpty } from "lodash";

export default function PriceBox({ data, formValues, setFormValues }) {
  return (
    <div
      className={`w-full max-w-[600px] rounded-[16px] border-[1px] border-[#4E04C6] p-[16px] shrink-0 flex flex-col cursor-pointer ${styles.priceWrapper}`}
      onClick={() => {
        setFormValues({
          ...formValues,
          packDetails: {
            packName: data?.packName,
            type: data?.type,
          },
        });
      }}
    >
      {!isEmpty(formValues) && (
        <div className="flex items-center">
          <input
            type="radio"
            name="packDetails"
            checked={
              formValues?.packDetails &&
              formValues?.packDetails["type"] == data?.type
            }
          />
          <p>{data?.packName}</p>
        </div>
      )}
      <div className="w-[100%]  mt-[32px] mb-[20px]">
        <h4 className="mb-[16px]">{data?.heading}</h4>
        <p className={styles.packDescription}>{data?.subTitle}</p>
      </div>
      {!isEmpty(data?.rules) && (
        <div>
          <p className={styles.packDescription}>Features</p>
          <ul className="mt-[8px]">
            {data?.rules?.map((el) => {
              return <li className={styles.packFeatures}>{el}</li>;
            })}
          </ul>
        </div>
      )}
      {!isEmpty(data?.extraCost) && (
        <div className="mt-[20px]">
          <p className={styles.packDescription}>Extra Costs</p>
          <ul className="mt-[8px]">
            {data?.extraCost?.map((el) => {
              return <li className={styles.packFeatures}>{el}</li>;
            })}
          </ul>
        </div>
      )}
      <h4 className="mt-[16px]">{data?.price}</h4>
    </div>
  );
}
