import { useRef, useEffect, useState, useContext } from "react";
import "./influencerSponser.css";
import NavBar from "../HomeOfficial/Navbar";
import ReactPlayer from "react-player";
import SponserDisplay from "./SponserDisplay";
import CardOfSponser from "./CardOfSponser";
import Footer from "../HomeForNew2.0/Footer";
import axios from "axios";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import BackHeader from "../BackHeader/BackHeader";
import { useLocation, useNavigate } from "react-router";

function SponserCard() {
  const [campaignList, setCampaignList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generateLinkLoading, setGenerateLinkLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getCampaignList();
  }, []);
  const getCampaignList = async () => {
    setLoading(true);
    try {
      const data = await axios.get(
        // `${process.env.REACT_APP_GET_TRENDING_CAMPAIGNS}`
        `https://storage.googleapis.com/goviral_home_data/sponsors/campaigns.json`
      );
      setCampaignList(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <div>
      <div className="bg-white w-[100%]">
        <BackHeader handleBack={handleNavigateToPrevLocation} heading="" />
        <div className="max-w-[1280px] mx-[auto] pt-[30px] ">
          <div className=" md:mx-[auto] px-[20px] md:px-[0] max-w-max md:max-w-[573px]">
            <p className="clashFont text-[#000000] font-normal text-[14px] md:text-[18px] leading-[20px] md:leading-[22px]">
              for Sponsors
            </p>
            <p className="clashFont mt-[10px] collabTxt">
              Lead Generation through Event Sponsorship: Partner with Us
            </p>
            <p className="mb-[30px] mt-[25px] text-[#787878] font-normal text-[14px] md:text-[17px] leading-[20px] md:leading-[22px]">
              Amplify your lead generation with event sponsorship. Connect,
              engage, and grow your business with tailored packages
            </p>
            <div class="scroller">
              <span className="clashFont mt-[20px] text-[#4E04C6] font-semibold text-[17px] md:text-[24px] leading-[20px] md:leading-[30px]">
                Brand Visibility
                <br />
                Partnerships
                <br />
                Mutual Growth
                <br />
                Sponsors
                <br />
                Collaboration
              </span>
            </div>
            <ReactPlayer
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              playing={true}
              controls={true}
              light="https://i.ytimg.com/vi/BzOyCeXwjco/sddefault.jpg"
              className="videoPlayer"
              width="100%"
              url="https://www.youtube.com/watch?v=BzOyCeXwjco"
            />
          </div>
        </div>
        {/* <SponserDisplay /> */}
        <div className="max-w-[1280px] mx-[auto] pt-[60px]  px-[20px] md:px-[80px]">
          <p className="clashFont text-[#000000] font-semibold text-[14px] md:text-[24px] leading-[20px] md:leading-[30px]">
            Campaigns
          </p>
          {/* <p className="max-w-[567px] mt-[16px] clashFont text-[#787878] font-medium text-[14px] md:text-[15px] leading-[20px] md:leading-[20px]">
            By partnering with us, you can gain access to a highly engaged
            community of supporters, increase brand exposure, and showcase your
            products and our engaged community of supporters
          </p> */}
          <div className="flex justify-start flex-wrap items-center gap-[10px] mt-[20px] pb-[20px] ">
            {/* <div className="fliterChip" style={{ color: "#035BDE" }}>
              All
            </div> */}
          </div>
          {loading && (
            <div className="w-[100%] h-[30px] flex justify-center my-[20px] bg-white">
              <div className="w-[30px] h-[100%]">
                <LoadingCircle />
              </div>
            </div>
          )}
          <div className="">
            {campaignList?.map((obj, index) => {
              return (
                <CardOfSponser
                  data={obj}
                  generateLinkLoading={generateLinkLoading}
                  setGenerateLinkLoading={setGenerateLinkLoading}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SponserCard;
