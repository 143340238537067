import { useState, useEffect, useRef } from "react";
import "./Case.css";
function UserCount({ users, influencerCount, heading, description, demoLink }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isCameToPort, setIsCameToPort] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        setIsCameToPort(true);
      },
      { threshold: 0.5 } // only trigger when at least 50% of element is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  let duration = 1000;

  const [endValue, setEndValue] = useState(users);
  const [UserValue, setUserValue] = useState(influencerCount);
  const [count, setCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  function formatNumber(number) {
    if (number >= 1000) {
      const suffixes = ["", "K", "M", "B", "T"];
      const suffixNum = Math.floor(("" + number).length / 3);
      let shortValue = parseFloat(
        (suffixNum !== 0
          ? number / Math.pow(1000, suffixNum)
          : number
        ).toPrecision(2)
      );
      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    }
    return number.toString();
  }
  function formatNumberPlus(number) {
    if (number >= 1000) {
      if (number > 1000) {
        const roundedNumber = Math.ceil(number / 10) * 10;
        return `${roundedNumber}+`;
      }
      return "1000";
    } else {
      const roundedNumber = Math.ceil(number / 10) * 10 - 10;
      if (roundedNumber !== number) {
        return `${roundedNumber}+`;
      }
      return roundedNumber.toString();
    }
  }
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isVisible === true) {
      let startTimestamp = null;

      const step = (timestamp) => {
        if (!startTimestamp) {
          startTimestamp = timestamp;
        }

        const timeElapsed = timestamp - startTimestamp;
        setProgress(Math.min(timeElapsed / duration, 1));

        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    }
    return () => {
      // cleanup function
      setCount(0);
      setUserCount(0);
    };
  }, [endValue, duration, isVisible]);

  useEffect(() => {
    setCount(Math.floor(progress * endValue));
    setUserCount(Math.floor(progress * UserValue));
    if (progress == 1) {
      setCount(formatNumber(endValue));
      setUserCount(formatNumberPlus(UserValue));
    }
    return () => {
      // cleanup function
      setCount(0);
      setUserCount(0);
    };
  }, [progress]);
  return (
    <div className=" mx-[auto] max-w-[573px] mt-[32px] px-[20px] md:px-[0]">
      <div
        ref={ref}
        className="flex items-end flex-col md:flex-row justify-center gap-[20px]"
      >
        <div
          className="w-[100%] md:w-[214px] px-[20px] py-[25px] md:gap-[0] gap-[30px] h-[161px] md:h-[277px] flex items-start md:flex-col flex-row justify-between"
          style={{ backgroundColor: "#F5CAB8" }}
        >
          <div className="dispatchFlex">
            <p className="text-[42px] text-left clashFont text-[#4E04C6] font-semibold  text-left leading-[37px]">
              {count}
            </p>
            <p className="text-[24px] text-left clashFont text-[#4E04C6] font-semibold mt-[10px] text-left leading-[90%]">
              User Engagement
            </p>
          </div>
          {userCount > 0 && (
            <div className="">
              <p className="text-[42px] text-right md:text-left clashFont text-[#FB6C44] font-semibold  text-left leading-[37px]">
                {userCount}
              </p>
              <p className="text-[24px] text-right md:text-left clashFont text-[#FB6C44] font-semibold mt-[10px] text-left leading-[90%]">
                New Influencers
              </p>
            </div>
          )}
        </div>
        <div>
          <p className="  text-[#4E04C6] font-semibold text-[21px] leading-[25px]">
            {heading}
          </p>
          <p className="mt-[12px]  text-[#787878] font-medium text-[17px] leading-[22px]">
            {description}
          </p>
          {demoLink && (
            <a
              className="mt-[25px]  text-[#4E04C6] font-semibold text-[17px] leading-[21px]"
              href={demoLink}
            >
              View a Demo
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserCount;
