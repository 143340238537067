import React, { useEffect } from "react";
import styles from "./SingleVideoPlayer.module.css";
import closebutton from "../../assests/closebutton.svg";
import ReactPlayer from "react-player";
import PopupWrapper from "../../Layouts/PopupWrapper";
import CustomVideoPlayer from "../CustomVideoPlayer/CustomVideoPlayer";

export default function SingleVideoPlayer({
  setHowToParticipatePopup,
  fileUrl,
  heading,
  thumbnail,
}) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <PopupWrapper>
      <div className={styles.popupWrapper}>
        <div className={styles.parentDiv}>
          <div className={styles.contentDiv}>
            <div className="flex justify-between items-center w-[100%] p-[20px]">
              <p className="text-regular-bold text-[#CDCDCD]">{heading}</p>
              <img
                src={closebutton}
                onClick={() => setHowToParticipatePopup(false)}
                className=" hover:cursor-pointer"
              />
            </div>
            <div className="h-full w-[100%] overflow-hidden flex items-start justify-center p-[20px]">
              <CustomVideoPlayer fileUrl={fileUrl} thumbnail={thumbnail} />
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
