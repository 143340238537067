import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/HomeOfficial/Navbar";
import Footer from "../Components/HomeForNew2.0/Footer";
import CreationSuccessCard from "../Components/CreationSuccessCard/CreationSuccessCard";

export default function CampaignCreationSuccessPage() {
  return (
    <>
      <Navbar />
      <div className="container-one mt-[120px] text-black">
        <CreationSuccessCard />

        <div className="mt-[40px] md:mt-[60px] mb-[32px]">
          <p className="text-largest-clash text-black md:mb-[21px] mb-[16px]">
            Campaign is created{" "}
          </p>
          <p className="text-regular-medium text-[#787878] md:w-[80%] md:mb-[32px] mb-[20px]">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>
          <button className="tempButton">
            <a
              href="http://organizer.goviral.world.s3-website.ap-south-1.amazonaws.com/"
              target="_blank"
            >
              Go to Goviral Dashboard  
            </a>
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
