import styles from '../../Components/FormToParticipate/FormToparti.module.css'
import React, { useState, useContext } from 'react'
import Context from '../../context/Context'
import * as _ from 'lodash'
import axios from 'axios'
import { useNavigate } from 'react-router'
import PaymentSuccess from '../PaymentSuccess/PaymentSuccess'
import PaymentFailed from '../PaymentFailed/PaymentFailed'
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}
function FormtoParticipate() {
  const navigate = useNavigate()
  const {
    contestJson,
    setpaymentData,
    paymentData,
    transactionDetails,
    setTransactionDetails,
    setPaymentFormDetails,
    paymentFormDetails,
  } = useContext(Context)
  const handleChangeDrop = (e) => {
    const object = contestJson && contestJson.contests[e.target.value]
    setdropdownSelection(object)
  }
  // console.log("contestJson",contestJson);
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [participantName, setParticipantName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [email, setEmail] = useState('')
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentFail, setPaymentFail] = useState(false)
  const [dropdownSelection, setdropdownSelection] = useState(
    contestJson && contestJson.contests[0],
  )
  const [agree, setAgree] = useState(false)

  const promiseUploadCall = async (e) => {
    e.preventDefault()
    if (_.isEmpty(participantName)) {
      setErrorMessage('Please enter the applicant name')
      return
    }
    if (_.isEmpty(email)) {
      setErrorMessage('Please enter a valid email')
      return
    }

    if (_.isEmpty(contactNumber)) {
      setErrorMessage('Please enter the contact number')
      return
    }
    if (_.isEmpty(dropdownSelection)) {
      setErrorMessage('Please select the contest')
      return
    }
    if (agree === false) {
      setErrorMessage('Please agree to the Posting Permission Policy ')
      return
    }
    setErrorMessage('')
    setLoading(true)
    try {
      const res = await loadScript('https://payments.open.money/layer')
      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?')
        return
      }
      let formDetails = {
        participantName: participantName,
        email: email,
        contactNumber: contactNumber,
        campaignName: dropdownSelection.campaignName,
        contestName: dropdownSelection.contestName,
        campaignId: dropdownSelection.campaignId,
        contestId: dropdownSelection.contestId,
      }
      setPaymentFormDetails(formDetails)
      let config1 = {
        method: 'post',
        url: 'https://us-central1-tripsmagic.cloudfunctions.net/goviralPayment',
        data: formDetails,
      }
      const response1 = await axios(config1)
      setpaymentData(response1.data)
      setLoading(false)

      const paymentObject = new window.Layer.checkout(
        {
          token: response1.data.layer_pay_token_id,
          accesskey: response1.data.layer_params.accesskey,
        },
        function (response) {
          setTransactionDetails(response)

          if (response.status === 'captured') {
            // response.payment_token_id
            // response.payment_id
            setPaymentFail(false)
            setPaymentSuccess(true)
          } else if (response.status === 'created') {
          } else if (response.status === 'pending') {
          } else if (response.status === 'failed') {
            // navigate('/payment/failed')
            setPaymentSuccess(false)
            setPaymentFail(true)
          } else if (response.status === 'cancelled') {
            // navigate('/payment/failed')
            setPaymentSuccess(false)
            setPaymentFail(true)
          }
        },
        function (err) {
          //integration errors
        },
      )
      paymentObject()
    } catch (error) {
      console.log(error.message)
    }
  }


  return (
    <div className={styles.backgroundcntr}>
      <div className={styles.formdiv}>
        <div>
          <p className={styles.heads}>Nava Durga Samarpanam Registration</p>
          <p className={styles.Entry}>Competition Entry Form</p>
          <p className={styles.Please}>
            Please fill the form below to complete your registration
          </p>
          <div className={styles.formikk}>
            <div className={styles.formonly}>
              <span>
                <p className={styles.formmi}>
                  Applicant name<p className={styles.applic}>*</p>
                </p>
              </span>
              <input
                placeholder="Enter your Full Name"
                className={styles.inputs}
                type="text"
                value={participantName}
                onChange={(e) => setParticipantName(e.target.value)}
              />
            </div>
            <div className={styles.formonly}>
              <span>
                <p className={styles.formmi}>
                  Applicant email<p className={styles.applic}>*</p>
                </p>
              </span>
              <input
                placeholder="Enter your Email Address"
                className={styles.inputs}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.formonly}>
              <span>
                <p className={styles.formmi}>
                  Phone number<p className={styles.applic}>*</p>
                </p>
              </span>
              <input
                placeholder="Enter your Mobile Number"
                className={styles.inputs}
                type="text"
                value={contactNumber}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </div>

            <div className={styles.formonly}>
              <span>
                <p className={styles.formmi}>
                  Age Group<p className={styles.applic}>*</p>
                </p>
              </span>
              <select
                className={styles.inputs}
                id="age"
                name="age"
                onChange={handleChangeDrop}
              >
                <option value="" disabled selected hidden>
                  Choose Age Group
                </option>
                {contestJson?.contests.map((obj, ind) => {
                  return (
                    <option className={styles.inputs} value={ind}>
                      {obj.ageGroup}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div>
            <p className={styles.posting}>Posting Permission </p>
            <p className={styles.postingDetails}>
              If the entry is selected as Winner, can goviral share the
              entry on their website and Social media page? You need to keep the
              entry accessible and not delete at least for few months.
            </p>
            <label class="container">
              <input
                type="checkbox"
                onChange={() => (agree ? setAgree(false) : setAgree(true))}
              />
              <span class="checkmark"></span> Yes I agree to the Posting
              Permission Policy
            </label>
          </div>
          <div className={styles.flexxx}>
            <p className={styles.entryfree}>Contest Entry fee </p>
            <div className={styles.prize}>
              <p>Rs 1000.00</p>
            </div>
          </div>
          <div className={styles.margintopp}>
            <ol className={styles.list}>

              <li className={styles.rulestoparti}>
                <p>
                  Participants will receive a 1-year subscription and a
                  showcase profile on goviral.{' '}
                </p>
              </li>
              <li className={styles.rulestoparti}>
                <p>
                  An online portfolio in goviral and opportunities to
                  participate in other promotional contest conducted under
                  goviral for free{' '}
                </p>
              </li>
            </ol>
          </div>
          {errorMessage && (
            <p className="text-[#8B0000] text-[18px] mt-[21px]">
              {errorMessage}
            </p>
          )}
          {loading ? (
            <div className={styles.buttonLoading}>
              <p>Processing Payment...</p>
            </div>
          ) : (
            <div className={styles.displist} onClick={promiseUploadCall}>
              <p>Proceed to Payment</p>
            </div>
          )}

          {paymentSuccess && <PaymentSuccess />}
          {paymentFail && <PaymentFailed setPaymentFail={setPaymentFail} />}
        </div>

      </div>
      <div className='mt-[30px]'>
        <p className='md:text-[20px] text-[17px] font-semibold'>Any questions or remarks? Feel free to reach us anytime</p>
        <p className='text-[#787878] text-[16px] mt-[20px]'>Email: support@goviral.world</p>
        <p className='text-[#787878] text-[16px] mt-[20px]'>Phone +91 99866 19796, +91 9567 183 183</p>
      </div>
    </div>
  )
}

export default FormtoParticipate
