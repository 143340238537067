import React from "react";
import Case from "../Components/CaseStudy/Case";

export default function CaseStudyDetailPage() {
  return (
    <div>
      <Case />
    </div>
  );
}
