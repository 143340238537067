import React, { useEffect, useRef, useState } from "react";
import styles from "./BrandCollabPage.module.css";
import axios from "axios";
import ReactPlayer from "react-player";
import PostRequirementPopup from "../../Components/PostRequirementPopup/PostRequirementPopup";

export default function BrandCollabPage() {
  const [postRequirement, setPostRequirement] = useState(false);
  const [data, setdata] = useState({});
  const firstColumnRef = useRef(null);
  const secondColumnRef = useRef(null);
  const customerRef = useRef(null);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const setApi = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/brands/postRequirement.json`
      );
      setdata(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (data?.landing?.influencers) {
      const scrollBounce = (ref, startFromBottom = false) => {
        const element = ref.current;
        let isScrollingDown = !startFromBottom;
        const scrollSpeed = 1; // Pixels per interval
        const intervalTime = 20; // Interval time in ms

        // If start from bottom, scroll to the bottom initially
        if (startFromBottom) {
          element.scrollTop = element.scrollHeight;
        }

        const scroll = () => {
          element.scrollTop += isScrollingDown ? scrollSpeed : -scrollSpeed;

          if (element.scrollTop === 0) {
            isScrollingDown = true;
          } else if (
            element.scrollTop + element.clientHeight >=
            element.scrollHeight
          ) {
            isScrollingDown = false;
          }
        };

        const interval = setInterval(scroll, intervalTime);

        return () => clearInterval(interval);
      };

      // First column scrolls from top to bottom, then bounces up
      const firstScroll = scrollBounce(firstColumnRef, false);

      // Second column scrolls from bottom to top, then bounces down
      const secondScroll = scrollBounce(secondColumnRef, true);

      return () => {
        firstScroll();
        secondScroll();
      };
    }
  }, [data]);
  useEffect(() => {
    if (data?.customers) {
      const scrollBounce = (ref, startFromRight = false) => {
        const element = ref.current;
        let isScrollingRight = !startFromRight;
        const scrollSpeed = 1; // Pixels per interval
        const intervalTime = 20; // Interval time in ms

        // If start from right, scroll to the far right initially
        if (startFromRight) {
          element.scrollLeft = element.scrollWidth;
        }

        const scroll = () => {
          element.scrollLeft += isScrollingRight ? scrollSpeed : -scrollSpeed;

          if (element.scrollLeft === 0) {
            isScrollingRight = true;
          } else if (
            element.scrollLeft + element.clientWidth >=
            element.scrollWidth
          ) {
            isScrollingRight = false;
          }
        };

        const interval = setInterval(scroll, intervalTime);

        return () => clearInterval(interval);
      };

      // Make the div scroll sideways
      const horizontalScroll = scrollBounce(customerRef, false);

      return () => {
        horizontalScroll();
      };
    }
  }, [data]);

  const influencers = data?.landing?.influencers || [];
  const middleIndex = Math.ceil(influencers.length / 2);
  const firstColumn = influencers.slice(0, middleIndex);
  const secondColumn = influencers.slice(middleIndex);

  return (
    <div>
      {postRequirement && (
        <PostRequirementPopup
          showPopup={postRequirement}
          setShowPopup={setPostRequirement}
        />
      )}
      <div className={styles.landing}>
        <div className="max-w-[1280px] h-full w-full px-[20px] lg:px-[78px] mx-auto grid grid-cols-1 sm:grid-cols-2">
          <div className="h-full sm:min-h-[80vh] flex flex-col justify-center pb-[35px] pt-[54px]">
            <h3 className="mb-[12px] sm:mb-0">Goviral for Brands</h3>
            <h1 className="mb-[12px] sm:mb-[16px]">
              Brand meets Influencers here!
            </h1>
            <p className="mb-[24px]">
              Looking to scale your brand with real influencers? You're in the
              right place! We'll help drive growth and amplify your business
              success.
            </p>
            <button
              className="mb-[21px]"
              onClick={() => setPostRequirement(true)}
            >
              Post a requirement
            </button>
            <p className={styles.influencer}>
              Are you an Influencer? <span>Join and Collab</span>
            </p>
          </div>
          <div className="flex justify-center sm:justify-end gap-[27px]">
            <div
              ref={firstColumnRef}
              className="pt-[45px] h-[300px] sm:h-[80vh] overflow-auto hideScrollBar"
            >
              {firstColumn?.map((obj, index) => (
                <div
                  key={index}
                  style={{
                    backgroundImage: `url(${obj?.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className={styles.infCard}
                >
                  <div className="p-[12px] mt-auto h-full flex justify-between items-end bg-black bg-opacity-30">
                    <p>{obj.userId}</p>
                    <p>{obj.infCount}</p>
                  </div>
                </div>
              ))}
            </div>
            <div
              ref={secondColumnRef}
              className="h-[300px] sm:h-[80vh] overflow-auto hideScrollBar"
            >
              {secondColumn?.map((obj, index) => (
                <div
                  key={index}
                  style={{
                    backgroundImage: `url(${obj?.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className={styles.infCard}
                >
                  <div className="p-[12px] mt-auto h-full flex justify-between items-end bg-black bg-opacity-30">
                    <p>{obj.userId}</p>
                    <p>{obj.infCount}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.customerDiv}>
        <div className="max-w-[1280px] h-full w-full px-[0px] lg:px-[78px] mx-auto">
          <h2>Our Happy Customers</h2>
          <div className="flex overflow-auto hideScrollBar gap-[20px] py-[32px] sm:py-[42px] px-[20px]">
            {data?.customers?.map((obj) => (
              <div className={styles.customerCard}>
                <div className="p-[20px]">
                  <img src={obj?.logo} className="mb-[15px]" />
                  <h3>{obj?.name}</h3>
                </div>
                <div
                  style={{ backgroundColor: obj?.color }}
                  className="flex items-center justify-between p-[20px]"
                >
                  <div>
                    <p className="pb-[21px]">TOTAL ENGAGEMENTS</p>
                    <h4>{obj?.engagement}</h4>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="63"
                    viewBox="0 0 86 63"
                    fill="none"
                  >
                    <path
                      d="M11.5562 49.0334C8.60582 54.6445 5.19755 53.4736 7.07802e-05 53.4736L-1.76951e-05 62.0167H82.6882V2.75684C82.0459 5.38223 72.7376 19.3455 68.6432 20.55C64.8054 21.3885 60.8515 18.6098 58.7281 17.8103C54.9634 16.393 50.0429 16.6524 48.0503 19.3455C44.7107 23.859 43.2247 29.7184 39.7068 34.0595C37.2466 37.0954 32.8607 39.1834 29.445 36.4686C26.6148 34.2192 24.1966 32.7277 20.4081 33.7525C14.7403 35.2856 13.909 44.5587 11.5562 49.0334Z"
                      fill="url(#paint0_linear_1933_636)"
                      fill-opacity="0.6"
                    />
                    <circle
                      cx="82.6879"
                      cy="2.75627"
                      r="2.75627"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1933_636"
                        x1="40.6042"
                        y1="5.62697"
                        x2="39.3906"
                        y2="56.7814"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.181689" stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="flex gap-[20px] w-fit mx-auto items-center overflow-auto hideScrollBar"
          ref={customerRef}
        >
          {data?.customers?.map((obj) => (
            <img
              src={obj?.logo}
              className="filter grayscale opacity-40 h-[60px] w-[158px] object-contain shrink-0"
            />
          ))}
        </div>
      </div>
      <div className="bg-[#d3f2fe66] py-[24px] lg:py-[70px]">
        <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
          <div className={styles.videoSection}>
            <h2>It’s easy to make viral contests and giveaways in minutes.</h2>
            <p className="my-[20px] lg:mb-[46pxs ]">Watch a 5 min demo</p>
            <div className="w-full rounded-[24px] aspect-video">
              <ReactPlayer
                config={{
                  file: {
                    attributes: { controlsList: "nodownload" },
                  },
                }}
                playing={false}
                controls={true}
                url={data?.videoUrl}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
