import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import CosmeticsWinner from "../Components/HomeForNew2.0/CosmeticsWinner";
import BackHeader from "../Components/BackHeader/BackHeader";

function UseCases() {
  const { type } = useParams();
  const [data, setData] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let config = {
        method: "get",
        url: `https://storage.googleapis.com/goviral_home_data/homeMedia/caseStudies/useCases.json`,
      };
      const response = await axios(config);
      setData(response?.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <BackHeader
        handleBack={handleNavigateToPrevLocation}
        heading="Use Cases"
      />
      <div className="justify-center bg-[#FAECE4] items-start flex flex-shrink-0">
        <CosmeticsWinner data={data?.campaignIdeas} />
      </div>
    </>
  );
}

export default UseCases;
