import styles from "../../Components/FormToParticipate/FormToparti.module.css";

function RulesOfContest() {
    return (
        <>
            <div className={styles.backgroundcntrddd} id="participate">
                <p className={styles.voucher}>How to participate?</p>
                <p className={styles.voucherRules}>Below are the steps for participation</p>
                <ol className={styles.list}>
                    <li className={styles.voucherRules}>Fill the Competition Entry Form below with the applicant's name, applicant email, and phone number.</li>
                    <li className={styles.voucherRules}>Complete the payment with the gateway link provided.</li>
                    <li className={styles.voucherRules}>User will be given the voucher code for participation.</li>
                    <li className={styles.voucherRules}>The voucher code for participation and link for downloading Nava Devi Bhavas, the song to be choreographed will be shared through mail.</li>
                    <li className={styles.voucherRules}>Participants can submit their own choreography on Bharatanatyam for the above song.</li>
                    <li className={styles.voucherRules}>For submitting the entry, login to <a href="/kalavathyteacher/navadurgasamarpanam" className={styles.link}>www.goviral.world/kalavathyteacher/navadurgasamarpanam</a> either using OTP or using email and upload the video by giving the voucher code.</li>
                </ol>
            </div>
            <div className={styles.backgroundcntrddd}>
                <p className={styles.voucher}>What you get with this voucher?</p>
                <ol className={styles.list}>
                    <li className={styles.voucherRules}>Participants will receive a 1-year subscription and a showcase profile on goviral. </li>
                    <li className={styles.voucherRules}>An online portfolio in goviral and opportunities to participate in other promotional contest conducted under goviral for free</li>
                </ol>
            </div>
        </>
    )
}

export default RulesOfContest