import React from "react";
import Insta from "../../assests/whiteInsta.png";
import { Link } from "react-router-dom";

function InfluencerListingSlider({ data }) {
  return (
    <div>
      <div
        class="uk-position-relative uk-visible-toggle uk-light mt-[16px] md:mt-[32px] "
        tabindex="-1"
        uk-slider="true"
      >
        <ul class="uk-slider-items gap-[10px] md:gap-[30px]">
          {data?.map((obj, index) => {
            <li key={index}>
              <Link to={`/${obj?.uniqueText}`}>
                <div class="uk-panel w-[219px] md:w-[392px] h-[109px] md:h-[196px] flex justify-center items-center">
                  <img
                    className="flex-[50%] w-1/2 h-[100%] object-cover"
                    src={obj?.profilePicture}
                    alt=""
                  />
                  <div
                    className={`flex-[50%] w-1/2 h-[100%] p-[6px] md:p-[12px] relative`}
                    style={{ background: obj?.bgColor }}
                  >
                    <p className="text-[#FFFFFF] font-semibold text-[14px] md:text-[17px] leading-[20px] md:leading-[20px]">
                      {obj?.name}
                    </p>
                    <div className="absolute bottom-[10px]">
                      <div className="flex justify-between gap-[10px] md:gap-[50px] items-baseline">
                        <div>
                          <img
                            className=" w-[16px] md:w-[32px]"
                            src={Insta}
                            alt=""
                          />
                          <p className="md:block hidden text-[#FFFFFF] font-semibold text-[12px] md:text-[12px] leading-[16px] md:leading-[16px]">
                            {obj?.socialMeida}
                          </p>
                        </div>
                        <p className="clashFont text-[#FFFFFF] font-semibold text-[12px] md:text-[32px] leading-[90%] md:leading-[28px]">
                          {obj?.followers}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>;
          })}
        </ul>

        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-previous
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-next
          uk-slider-item="next"
        ></a>
      </div>
    </div>
  );
}

export default InfluencerListingSlider;
