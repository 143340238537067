import React from 'react'
import HostContestDetails from '../HostContestDetails/HostContestDetails'
import FormtoParticipate from './FormtoParticipate'
import RulesOfContest from './RulesOfContest'
import styles from './FormToparti.module.css'

function FormFullSection() {
  return (
    <section className='w-[100%] h-auto bg-[#FFFFFF]'>
      <div className={styles.container}>
        {/* <HostContestDetails/> */}
        <RulesOfContest />
        <FormtoParticipate />


      </div>
    </section>
  )
}

export default FormFullSection