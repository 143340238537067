import { useRef, useEffect, useState, useContext } from "react";
import "./influencerSponser.css";
import JoinAsSponsorPopup from "../JoinAsSponsorPopup/JoinAsSponsorPopup";
import axios from "axios";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import { updateProfile } from "firebase/auth";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { useNavigate } from "react-router";
import PromoterSharePopup from "../PromotorShare/PromoterSharePopup";

function CampaignCard({ data, generateLinkLoading, setGenerateLinkLoading }) {
  const [promotorShare, setPromotorShare] = useState(false);
  const { currentUser, setPreviousLocation } = useUserAuth();
  const { userIdToken, verifyUser } = useContext(Context);
  const [newPromotorId, setNewPromotorId] = useState();
  const token = userIdToken || currentUser?.accessToken;
  const endDate = new Date(data?.endDate);
  const formattedDate = endDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const navigate = useNavigate();
  const handleJoinSponsorRequest = () => {
    if (currentUser) {
      joinRequest();
    } else {
      setPreviousLocation(window.location.pathname);
      navigate("/signup");
    }
  };
  const initialValues = {
    name: currentUser?.displayName ? currentUser?.displayName : "",
    email: currentUser?.email ? currentUser?.email : "",
    phoneNumber: currentUser?.phoneNumber ? currentUser?.phoneNumber : "",
  };
  const [influencerFormValue, setInfluencerFormValue] = useState(initialValues);

  const joinRequest = async () => {
    setGenerateLinkLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_INF_CAMPAIGNS_GENERATE_LINK}/${data?.campaignId}/${data?.orgId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setGenerateLinkLoading(false);
        setNewPromotorId(response?.data);
        setPromotorShare(true);
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };
  const createInfluencer = async () => {
    setGenerateLinkLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_CAMPAIGNS_INFLUENCER}/${data?.campaignId}/${data?.orgId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: influencerFormValue,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setNewPromotorId({
          ...data?._id,
          approvedByAdmin: false,
          newInfluencer: false,
        });
        updateUserssProfile();
        setGenerateLinkLoading(false);
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };
  const updateUserssProfile = async () => {
    try {
      const updateUserProfile = await updateProfile(currentUser, {
        displayName: influencerFormValue?.name,
        phoneNumber: influencerFormValue?.phone,
      });
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_PROFILE_DETAILS}`,
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
          "Content-Type": "application/json",
        },
        data: influencerFormValue,
      };
      const updateProfileDetails = await axios(config);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      {promotorShare && (
        <PromoterSharePopup
          setPromotorShare={setPromotorShare}
          promotionData={data}
          influencerData={newPromotorId}
          influencerFormValue={influencerFormValue}
          setInfluencerFormValue={setInfluencerFormValue}
          createInfluencer={createInfluencer}
          generateLinkLoading={generateLinkLoading}
        />
      )}
      <div className="w-[100%] md:p-[10px] cardOfinf relative pb-[20px]">
        <div className="flex flex-col md:flex-row h-[auto] md:h-[192px]  justify-start items-start ">
          <img
            className=" md:h-[100%] w-[100%] md:w-[144px] h-[170px] object-cover"
            src={data?.coverImage}
            alt=""
          />
          <div className="flex items-start justify-between w-[100%]">
            <div className="md:ml-[12px]">
              <div className="chipInCard mt-[10px] md:mt-[0]">
                Running Campaign
              </div>
              <p className="mt-[6px] md:mt-[18px] text-[#000000] font-medium text-[16px] md:text-[17px] leading-[20px] md:leading-[20px]">
                {data?.campaignName}
              </p>
              <p className="mt-[8px] text-[#787878] font-nomal text-[12px] md:text-[12px] leading-[14px] md:leading-[14px] max-w-[436px]">
                {data?.campaignDescription}
              </p>
              <div className="flex overflow-x-scroll w-[350px]  md:w-[100%] md:overflow-x-hidden mt-[16px] gap-[8px] md:gap-[16px] items-center justify-start">
                {/* <div>
                  <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
                    Targeted audience{" "}
                  </p>
                  <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                    Photograhers
                  </p>
                </div>
                <hr className="divider" /> */}
                {/* <div>
                  <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
                    Demography
                  </p>
                  <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                    India
                  </p>
                </div>
                <hr className="divider" /> */}
                {/* <div>
                  <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
                    Expected participation
                  </p>
                  <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                    100K
                  </p>
                </div> */}
                <hr className="divider" />
                <div>
                  <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
                    Campaign ends on
                  </p>
                  <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                    {formattedDate}
                  </p>
                </div>
                <hr className="divider hidden md:block" />
                {/* <button
                  className="collabrator hidden md:flex "
                  onClick={handleJoinSponsorRequest}
                  disabled={generateLinkLoading}
                >
                  {generateLinkLoading ? <LoadingCircle /> : "Be a Promotor"}
                </button> */}
              </div>
              {/* <button
                className="collabrator md:hidden flex ml-[auto] mt-[20px]"
                onClick={handleJoinSponsorRequest}
                disabled={generateLinkLoading}
              >
                {generateLinkLoading ? <LoadingCircle /> : "Be a Promotor"}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignCard;
