import React, { useContext, useEffect, useRef, useState } from "react";
import PopupWrapper from "../../Layouts/PopupWrapper";
import styles from "./InfCollabPopup.module.css";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import axios from "axios";
import { isEmpty } from "lodash";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import facebook from "../Assets/share/fbLogo.svg";
import whatsapp from "../Assets/share/whatsApp.svg";
import twitter from "../Assets/share/twitterX.svg";
import LinkedIn from "../Assets/share/LinkedIn.svg";
import copyLinkImage from "../Assets/share/copyLinkBlack.svg";
import copySuccess from "../Assets/share/copySuccessBlack.svg";
import { useLocation, useNavigate } from "react-router";
import InfCollabCarousel from "../InfCollabCarousel/InfCollabCarousel";

export default function InfCollabPopup({ data, showPopup, setShowPopup }) {
  const { currentUser, setPreviousLocation } = useUserAuth();
  const { userIdToken, verifyUser } = useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const initialValues = {
    name: currentUser?.displayName ? currentUser?.displayName : "",
    email: currentUser?.email ? currentUser?.email : "",
    phoneNumber: currentUser?.phoneNumber ? currentUser?.phoneNumber : "",
  };
  const [logo, setLogo] = useState("");
  const [step, setStep] = useState("STEP1");
  const [influencerFormValue, setInfluencerFormValue] = useState(initialValues);
  const [logoUploadLoading, setLogoUploadLoading] = useState(false);
  const [generateLinkLoading, setGenerateLinkLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [newPromotorDetails, setNewPromotorDetails] = useState();
  const [errorMessage, setErrorMessage] = useState({});
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const whatsAppNumberRef = useRef(null);
  const infOrgNameRef = useRef(null);
  const aboutRef = useRef(null);
  const formRef = useRef(null);
  useEffect(() => {
    verifyUser();
    if (!isEmpty(currentUser)) {
      getInfDetails();
    }
  }, [currentUser]);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const logoRef = useRef();
  const handleLogoChoose = () => {
    logoRef.current.click();
  };
  const handleFileChange = async (event) => {
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);

    setLogo(url);
    setLogoUploadLoading(true);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_UPLOAD_SPONSOR_LOGO}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    if (upload.status === 200) {
      setInfluencerFormValue((prevValue) => ({
        ...prevValue,
        dop: upload.data.url,
      }));
    }
    setLogoUploadLoading(false);
  };

  const getInfDetails = async () => {
    setGenerateLinkLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_PROMOTOR_GET_DETAILS}/${data?.campaignId}/${data?.orgId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data.status == "success") {
        setGenerateLinkLoading(false);
        setNewPromotorDetails(response?.data);
        if (!isEmpty(response?.data?.infDetails)) {
          setInfluencerFormValue((prevState) => ({
            ...prevState,
            ...response?.data?.infDetails,
          }));
        }
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };
  const handleSignin = () => {
    setPreviousLocation(window.location.pathname);
    navigate("/signup", {
      state: {
        previousLocation: location?.pathname,
        data,
      },
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setInfluencerFormValue({
      ...influencerFormValue,
      [e.target.id]: e.target.value,
    });
  };
  const handleChangeSocialmedialink = (e) => {
    e.preventDefault();
    setInfluencerFormValue((prevState) => ({
      ...prevState,
      socialmedialink: {
        ...prevState?.socialmedialink,
        [e.target.id]: e.target.value,
      },
    }));
  };
  const createInfluencer = async () => {
    setGenerateLinkLoading(true);
    setSubmitLoading(true);
    try {
      let url = "";
      if (newPromotorDetails?.newInfluencer) {
        url = `${process.env.REACT_APP_CREATE_CAMPAIGNS_INFLUENCER}/${data?.campaignId}/${data?.orgId}`;
      } else {
        url = `${process.env.REACT_APP_PROMOTOR_UPDATE_AND_REQUEST}/${data?.campaignId}/${data?.orgId}`;
      }
      let config = {
        method: "post",
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: influencerFormValue,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setNewPromotorDetails({
          ...data?._id,
          approvedByAdmin: false,
          newInfluencer: false,
          isPromoterFound: true,
        });
        // updateUserssProfile();
        setGenerateLinkLoading(false);
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      setGenerateLinkLoading(false);
    }
  };
  const handleSubmit = async () => {
    let errors = {};
    let ref = "";

    if (!influencerFormValue?.name) {
      errors.name = "Please enter your name";
      ref = nameRef;
    }
    if (!influencerFormValue?.email) {
      errors.email = "Please enter your email";
      ref = emailRef;
    }
    if (!influencerFormValue?.phone) {
      errors.phone = "Please enter your contact number";
      ref = phoneRef;
    }
    if (!influencerFormValue?.whatsAppNumber) {
      errors.whatsAppNumber = "Please enter your whatsapp number";
      ref = whatsAppNumberRef;
    }
    if (!influencerFormValue?.infOrgName) {
      errors.infOrgName = "Please enter your / organization name";
      ref = infOrgNameRef;
    }
    if (!influencerFormValue?.about) {
      errors.about =
        "Please enter a short discription about yourself / organizaton";
      ref = aboutRef;
    }
    if (ref) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setErrorMessage(errors);
    if (isEmpty(errors)) {
      await createInfluencer();
    } else {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const copyText = newPromotorDetails?.AML;

  function copyLink() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, [1000]);
    navigator.clipboard.writeText(copyText);
    window.document.execCommand("copy");
    // alert(copyText + "\n copied to clipboard")
  }

  const whatsapplink = () => {
    const encodedContent = encodeURIComponent(copyText);
    const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(
      navigator.userAgent
    );
    if (isMobileDevice) {
      const url = `https://wa.me/?text=${encodedContent}`;
      window.open(url, "_blank");
    } else {
      const url = `https://web.whatsapp.com/send?text=${encodedContent}`;
      window.open(url, "_blank");
    }
  };
  const facebooklink = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${copyText}`);
    return false;
  };
  const shareToTwitter = () => {
    window.open(`http://twitter.com/share?url=${copyText}`);
  };
  const shareToLinkedin = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${copyText}`
    );
  };
  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.popupWrapper}>
        <div className={styles.container}>
          <div className={styles.contentDiv}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <h2>Collaborate with {data?.infCollabData?.name}</h2>
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setShowPopup(false);
                  navigate(null);
                }}
              >
                <path
                  d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                  fill="#979797"
                />
              </svg>
            </div>
            <div className={styles.body}>
              {step == "STEP1" && (
                <div className={styles.infCollab}>
                  <div className="p-[20px] border-b-[1px] md:border-b-[0px] md:border-r-[1px] border-[#D9D9D94D]">
                    {!isEmpty(data?.infCollabData?.images) && (
                      <InfCollabCarousel images={data?.infCollabData?.images} />
                    )}
                    <pre className="mt-[20px]">
                      {data?.infCollabData?.about}{" "}
                    </pre>
                  </div>
                  <div className="flex flex-col gap-[20px] p-[20px]">
                    <pre>{data?.infCollabData?.collabDesciption} </pre>
                    {data?.infCollabData?.deliverables && (
                      <p>
                        <strong>Deliverables:</strong>{" "}
                        {data?.infCollabData?.deliverables}
                      </p>
                    )}
                    {data?.infCollabData?.eligibility && (
                      <p>
                        <strong>Eligibility:</strong>{" "}
                        {data?.infCollabData?.eligibility}
                      </p>
                    )}
                    {data?.infCollabData?.rewards && (
                      <p>
                        <strong>Rewards:</strong> {data?.infCollabData?.rewards}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {/* <div className="w-full h-[1px] opacity-30 bg-[#D9D9D9] my-[5px] px-[30px]" /> */}
              {step == "STEP2" && (
                <div className="w-full flex flex-col" ref={formRef}>
                  <div
                    className="px-[20px] pt-[20px]"
                    onClick={() => setStep("STEP1")}
                  >
                    <button className="border-2 w-[40px] text-center items-center h-[40px] border-[#D9D9D9] rounded-full">
                      <svg
                        className="ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M4.375 9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H4.375C4.20924 10.625 4.05027 10.5592 3.93306 10.4419C3.81585 10.3247 3.75 10.1658 3.75 10C3.75 9.83424 3.81585 9.67527 3.93306 9.55806C4.05027 9.44085 4.20924 9.375 4.375 9.375Z"
                          fill="#161616"
                        />
                        <path
                          d="M4.63365 10.0001L9.8174 15.1826C9.93475 15.2999 10.0007 15.4591 10.0007 15.6251C10.0007 15.791 9.93475 15.9502 9.8174 16.0676C9.70004 16.1849 9.54087 16.2508 9.3749 16.2508C9.20893 16.2508 9.04975 16.1849 8.9324 16.0676L3.3074 10.4426C3.24919 10.3845 3.20301 10.3155 3.17151 10.2396C3.14 10.1637 3.12378 10.0823 3.12378 10.0001C3.12378 9.91785 3.14 9.83645 3.17151 9.76052C3.20301 9.68458 3.24919 9.61561 3.3074 9.55756L8.9324 3.93256C9.04975 3.8152 9.20893 3.74927 9.3749 3.74927C9.54087 3.74927 9.70004 3.8152 9.8174 3.93256C9.93475 4.04992 10.0007 4.20909 10.0007 4.37506C10.0007 4.54103 9.93475 4.7002 9.8174 4.81756L4.63365 10.0001Z"
                          fill="#161616"
                        />
                      </svg>
                    </button>
                  </div>
                  {currentUser ? (
                    <>
                      {generateLinkLoading ? (
                        <div className="w-full h-full flex flex-col items-center gap-[30px] justify-center">
                          <div className="w-[50px] h-[50px]">
                            <LoadingCircle />
                          </div>
                          {submitLoading ? (
                            <h4>Submitting Details..</h4>
                          ) : (
                            <h4>Almost there! Fetching your details...</h4>
                          )}
                        </div>
                      ) : newPromotorDetails?.approvedByAdmin == true ? (
                        <div className="p-[20px] w-full h-full flex flex-col items-center gap-[30px] justify-center">
                          <h3 className="text-center w-[90%]">
                            Congratulations! Your influencer request has been
                            approved. Please share the link as needed to fulfill
                            your deliverables
                          </h3>
                          {copyText && (
                            <div className="w-full lg:w-[50%]">
                              <div className="flex justify-between w-[100%] text-[#000] text-small w-full">
                                <div
                                  className="flex flex-col items-center gap-[6px] cursor-pointer"
                                  onClick={facebooklink}
                                >
                                  <img
                                    src={facebook}
                                    alt=""
                                    className="w-[46px] h-[46px]"
                                  />
                                  <p>Facebook</p>
                                </div>
                                <div
                                  className="flex flex-col items-center gap-[6px] cursor-pointer"
                                  onClick={whatsapplink}
                                >
                                  <img
                                    src={whatsapp}
                                    alt=""
                                    className="w-[46px] h-[46px]"
                                  />
                                  <p>Whatsapp</p>
                                </div>
                                <div
                                  className="flex flex-col items-center gap-[6px] cursor-pointer"
                                  onClick={shareToTwitter}
                                >
                                  <img
                                    src={twitter}
                                    alt=""
                                    className="w-[46px] h-[46px]"
                                  />
                                  <p>Twitter</p>
                                </div>
                                <div
                                  className="flex flex-col items-center gap-[6px] cursor-pointer"
                                  onClick={shareToLinkedin}
                                >
                                  <img
                                    src={LinkedIn}
                                    alt=""
                                    className="w-[46px] h-[46px]"
                                  />
                                  <p>Linkedin</p>
                                </div>
                              </div>
                              <div className="mt-[20px] border-[1px] rounded-[5px] py-[5px] px-[20px] w-full">
                                <div
                                  className="flex items-center justify-between w-full"
                                  onClick={copyLink}
                                  tooltip="Copy to clipboard"
                                >
                                  <div className="w-[calc(100%-50px)]">
                                    <p className="text-regular-medium text-black">
                                      Copy the link{" "}
                                    </p>
                                    <p className="text-small break-all text_1Line text-[#CDCDCD]">
                                      {copyText}
                                    </p>
                                  </div>
                                  <div className="w-[24px] h-[24px]">
                                    {copied ? (
                                      <img src={copySuccess} className="" />
                                    ) : (
                                      <img src={copyLinkImage} className="" />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : newPromotorDetails?.approvedByAdmin == false ||
                        newPromotorDetails?.isPromoterFound == true ? (
                        <div className="p-[20px] w-full h-full flex flex-col items-center gap-[30px] justify-center">
                          <h3 className="text-center w-[90%]">
                            Thank you for submitting your request to create an
                            affiliate link. Once the request is approved by the
                            administrator, you will receive the link via email
                          </h3>
                          <h3 className="text-center w-[90%]">
                            In the meantime, you can monitor the progress
                            through your dashboard
                          </h3>
                        </div>
                      ) : (
                        <div className={styles.formGrid}>
                          <div className={styles.form} ref={nameRef}>
                            <p>
                              Name
                              <span className="text-red-600">*</span>
                            </p>
                            <input
                              type="text"
                              id="name"
                              onChange={handleChange}
                              value={influencerFormValue?.name}
                              className={errorMessage?.name ? styles.error : ""}
                            />
                            {errorMessage?.name && (
                              <p className={styles.errorText}>
                                {errorMessage?.name}
                              </p>
                            )}
                          </div>
                          <div className={styles.form} ref={emailRef}>
                            <p>
                              Email
                              <span className="text-red-600">*</span>
                            </p>
                            <input
                              type="text"
                              id="email"
                              onChange={handleChange}
                              value={influencerFormValue?.email}
                              className={
                                errorMessage?.email ? styles.error : ""
                              }
                            />
                            {errorMessage?.email && (
                              <p className={styles.errorText}>
                                {errorMessage?.email}
                              </p>
                            )}
                          </div>
                          <div className={styles.form} ref={phoneRef}>
                            <p>
                              Contact number{" "}
                              <span className="text-red-600">*</span>
                            </p>
                            <input
                              type="text"
                              id="phone"
                              onChange={handleChange}
                              value={influencerFormValue?.phone}
                              className={
                                errorMessage?.phone ? styles.error : ""
                              }
                            />
                            {errorMessage?.phone && (
                              <p className={styles.errorText}>
                                {errorMessage?.phone}
                              </p>
                            )}
                          </div>
                          <div className={styles.form} ref={whatsAppNumberRef}>
                            <p>
                              Whatsapp Number
                              <span className="text-red-600">*</span>
                            </p>
                            <input
                              type="text"
                              id="whatsAppNumber"
                              onChange={handleChange}
                              placeholder="For updates, Please share your number"
                              value={influencerFormValue?.whatsAppNumber}
                              className={
                                errorMessage?.whatsAppNumber ? styles.error : ""
                              }
                            />
                            {errorMessage?.whatsAppNumber && (
                              <p className={styles.errorText}>
                                {errorMessage?.whatsAppNumber}
                              </p>
                            )}
                          </div>
                          <div className={styles.form} ref={infOrgNameRef}>
                            <p>
                              Name your ( Company/Marketing Agency/College
                              Ambassador/Individual )
                              <span className="text-[#FA6B6B]">*</span>
                            </p>
                            <input
                              type="text"
                              id="infOrgName"
                              onChange={handleChange}
                              value={influencerFormValue?.infOrgName}
                              className={
                                errorMessage?.infOrgName ? styles.error : ""
                              }
                            />
                            {errorMessage?.infOrgName && (
                              <p className={styles.errorText}>
                                {errorMessage?.infOrgName}
                              </p>
                            )}
                          </div>
                          <div
                            className={`col-span-2 ${styles.form}`}
                            ref={aboutRef}
                          >
                            <p>
                              Tell us something about you?{" "}
                              <span className="text-[#FA6B6B]">*</span>
                            </p>
                            <div className="relative">
                              <textarea
                                type="text"
                                id="about"
                                maxlength="500"
                                onChange={handleChange}
                                value={influencerFormValue?.about}
                                className={`no-scrollbar ${
                                  errorMessage?.about ? "error" : ""
                                }`}
                              />
                              {influencerFormValue?.about?.length > 0 && (
                                <span className="text-black w-[98%] absolute flex bottom-2 justify-end opacity-[.6]">
                                  {influencerFormValue?.about?.length}/500
                                </span>
                              )}
                            </div>
                            {errorMessage?.about && (
                              <p className={styles.errorText}>
                                {errorMessage?.about}
                              </p>
                            )}
                          </div>
                          <div className={styles.form}>
                            <p>Instagram</p>
                            <input
                              type="text"
                              id="instagram"
                              onChange={handleChangeSocialmedialink}
                              value={
                                influencerFormValue?.socialmedialink?.instagram
                              }
                            />
                          </div>
                          <div className={styles.form}>
                            <p>Facebook</p>
                            <input
                              type="text"
                              id="facebook"
                              onChange={handleChangeSocialmedialink}
                              value={
                                influencerFormValue?.socialmedialink?.facebook
                              }
                            />
                          </div>
                          <div className={styles.form}>
                            <p>Website</p>
                            <input
                              type="text"
                              id="website"
                              onChange={handleChangeSocialmedialink}
                              value={
                                influencerFormValue?.socialmedialink?.website
                              }
                            />
                          </div>
                          {/* <div
                  className={`col-span-2 ${styles.form}`}
                  onClick={handleLogoChoose}
                >
                  <p>
                    Upload Profile Picture
                    <span className="text-red-600">*</span>
                  </p>
                  <div className={styles.imageContainer}>
                    <input
                      ref={logoRef}
                      className="hidden"
                      type="file"
                      onChange={handleFileChange}
                      accept=".jpg,.jpeg,.png,.svg"
                      id="logo"
                    />
                    <div className="w-[100%] min-h-[60px] h-full overflow-hidden cursor-pointer flex items-center justify-center">
                      {logo ? (
                        <>
                          <div className=" h-[100px] overflow-hidden flex items-center justify-center">
                            <img
                              src={logo}
                              alt=""
                              className="object-cover w-full h-full"
                            />
                          </div>

                          <div className={styles.changeImageButton}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M2.36532 13.9588H13.632C13.7204 13.9588 13.8052 13.9237 13.8677 13.8612C13.9302 13.7987 13.9653 13.7139 13.9653 13.6255C13.9653 13.5371 13.9302 13.4523 13.8677 13.3898C13.8052 13.3273 13.7204 13.2922 13.632 13.2922H2.36532C2.27691 13.2922 2.19213 13.3273 2.12961 13.3898C2.0671 13.4523 2.03198 13.5371 2.03198 13.6255C2.03198 13.7139 2.0671 13.7987 2.12961 13.8612C2.19213 13.9237 2.27691 13.9588 2.36532 13.9588ZM6.47332 11.4535C6.75492 11.3747 7.01185 11.2256 7.21998 11.0202L13.58 4.66018C13.7978 4.44152 13.9201 4.14547 13.9201 3.83684C13.9201 3.52822 13.7978 3.23217 13.58 3.01351L12.9533 2.39351C12.7315 2.18168 12.4367 2.06348 12.13 2.06348C11.8233 2.06348 11.5284 2.18168 11.3066 2.39351L4.94665 8.74684C4.74088 8.9536 4.59364 9.21126 4.51998 9.49351L4.02665 11.3335C4.00324 11.4176 4.00264 11.5063 4.0249 11.5907C4.04716 11.675 4.09149 11.752 4.15332 11.8135C4.24768 11.9063 4.37433 11.9588 4.50665 11.9602L6.47332 11.4535ZM6.74665 10.5468C6.62375 10.672 6.46948 10.7618 6.29998 10.8068L5.65332 10.9802L4.98665 10.3135L5.15998 9.66684C5.20661 9.498 5.29619 9.34411 5.41998 9.22018L5.67332 8.97351L6.99998 10.3002L6.74665 10.5468ZM7.47332 9.82684L6.14665 8.50018L10.6333 4.01351L11.96 5.34018L7.47332 9.82684ZM13.1066 4.19351L12.4333 4.86684L11.1066 3.54018L11.78 2.86018C11.8263 2.81365 11.8814 2.77674 11.942 2.75155C12.0026 2.72636 12.0677 2.7134 12.1333 2.7134C12.199 2.7134 12.264 2.72636 12.3246 2.75155C12.3853 2.77674 12.4403 2.81365 12.4866 2.86018L13.1066 3.48684C13.1996 3.58094 13.2518 3.70789 13.2518 3.84018C13.2518 3.97246 13.1996 4.09942 13.1066 4.19351Z"
                                fill="#161616"
                              />
                            </svg>
                          </div>
                        </>
                      ) : (
                        <button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M13 19C13 19.7 13.13 20.37 13.35 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 3.9 3.9 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V13.35C20.37 13.13 19.7 13 19 13V5H5V19H13ZM13.96 12.29L11.21 15.83L9.25 13.47L6.5 17H13.35C13.75 15.88 14.47 14.91 15.4 14.21L13.96 12.29ZM20 18V15H18V18H15V20H18V23H20V20H23V18H20Z"
                              fill="#161616"
                            />
                          </svg>
                          Upload Profile Picture
                        </button>
                      )}
                    </div>
                  </div>
                </div> */}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className={styles.signIn}>
                      <p>
                        To promote this campaign and collaborate, please sign in
                        to our platform and fill the details .
                      </p>
                      <button onClick={handleSignin}>
                        Sign in to continue
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={styles.buttonWrapper}>
              {!isEmpty(errorMessage) && (
                <p className="w-full text-red-600">
                  {newPromotorDetails?.approvedByAdmin == true ||
                  newPromotorDetails?.approvedByAdmin == false ||
                  newPromotorDetails?.isPromoterFound == true
                    ? ""
                    : "*Please fill out all mandatory fields"}
                </p>
              )}
              {step == "STEP1" ? (
                <div className="flex w-full items-center justify-end gap-[12px]">
                  <button onClick={() => setStep("STEP2")}>Next</button>
                </div>
              ) : (
                currentUser && (
                  <div className="flex w-full items-center justify-end gap-[12px]">
                    {newPromotorDetails?.approvedByAdmin == true ||
                    newPromotorDetails?.approvedByAdmin == false ||
                    newPromotorDetails?.isPromoterFound == true ? (
                      <>
                        <button className={styles.dbButton}>
                          <a
                            href="https://dashboard.goviral.world/inf"
                            target="_blank"
                          >
                            View dashboard
                          </a>
                        </button>
                        <button onClick={() => setShowPopup(false)}>
                          Close
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        disabled={generateLinkLoading || logoUploadLoading}
                      >
                        {generateLinkLoading ? "Loading" : "Collaborate"}
                      </button>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
