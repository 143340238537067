import React, { useContext, useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import styles from "./CampaignCreationForm.module.css";
import flag from "../../assests/newHome/ind.png";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router";
import RichtextEditor from "../RichtextEditor/RichtextEditor";
import HelpPopup from "../HelpPopup/HelpPopup";
import axios from "axios";
import Context from "../../context/Context";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import CreationCompletionBanner from "../CreationCompletionBanner/CreationCompletionBanner";
import PriceBox from "../PriceBox/PriceBox";

const CamapignCreationForm = ({
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  helpData,
}) => {
  const { currentUser, setPreviousLocation } = useUserAuth();
  const { userIdToken, verifyUser } = useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [orgNameClicked, setOrgNameClicked] = useState({});
  const [orgDomainAvailable, setOrgDomainAvailable] = useState("");
  const [campaignTextAvailable, setCampaignTextAvailable] = useState("");
  const [step3Details, setStep3Details] = useState([]);
  const [allValueEntered, setAllValueEntered] = useState(false);

  const navigate = useNavigate();
  const formRef = useRef(null);
  const textInputCampaignName = useRef(null);
  const textInputEmail = useRef(null);
  const textInputCampaignDescription = useRef(null);
  const textInputAboutOrganiser = useRef(null);
  const textInputAddress = useRef(null);
  const textInputContactNumber = useRef(null);
  const textInputContestType = useRef(null);
  const textInputPrivateOrPublic = useRef(null);
  const textInputGifts = useRef(null);
  const textInputTandc = useRef(null);
  const formOrganizationNameRef = useRef(null);
  const formOrganizationIdRef = useRef(null);
  const formCampaignUniqueTextRef = useRef(null);
  const formPackRef = useRef(null);

  useEffect(() => {
    // formRef.current.scrollIntoView({  behavior: "smooth" });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    verifyUser();
    loadStep3();
    getorganizationList();
  }, []);
  const validateEmail = (emailAdress) => {
    // eslint-disable-next-line no-useless-escape
    let regexEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };
  const validatePhone = (number) => {
    let regexmobile =
      // eslint-disable-next-line no-useless-escape
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (number.match(regexmobile)) {
      return true;
    } else {
      return false;
    }
  };
  const loadStep3 = async () => {
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/homeMedia/homeDatas/packDetails.json`
      );
      setStep3Details(data?.data);
      setFormValues((prevState) => ({
        ...prevState,
        packDetails: {
          packName: data?.data?.pack[0]?.packName,
          type: data?.data?.pack[0]?.type,
        },
      }));
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };

  useEffect(() => {
    instantValidaion();
  }, [formValues]);
  const instantValidaion = () => {
    if (
      !_.isEmpty(formValues.campaignName) &&
      !_.isEmpty(formValues.campaignDescription) &&
      formValues.sponsorShipStatus &&
      !_.isEmpty(formValues.name) &&
      !_.isEmpty(formValues.orgDomain) &&
      !_.isEmpty(formValues.campaignUniqueText) &&
      !_.isEmpty(formValues.email) &&
      !_.isEmpty(formValues.description) &&
      !_.isEmpty(formValues.phone) &&
      !_.isEmpty(formValues.packDetails)
    ) {
      setAllValueEntered(true);
    } else {
      setAllValueEntered(false);
    }
  };
  const promiseUploadCall = async (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (
      !_.isEmpty(formValues.name) &&
      !_.isEmpty(formValues.orgDomain) &&
      !_.isEmpty(formValues.campaignUniqueText) &&
      !_.isEmpty(formValues.packDetails) &&
      !_.isEmpty(formValues.campaignName) &&
      !_.isEmpty(formValues.email) &&
      !_.isEmpty(formValues.campaignDescription) &&
      !_.isEmpty(formValues.description) &&
      // !_.isEmpty(formValues.address) &&
      !_.isEmpty(formValues.phone) &&
      // !_.isEmpty(formValues.tandc) &&
      // !_.isEmpty(formValues.giftDetails) &&
      formValues.sponsorShipStatus &&
      validateEmail(formValues.email) !== false &&
      validatePhone(formValues.phone) !== false
    ) {
      setOrgDomainAvailable("");
      setCampaignTextAvailable("");
      if (_.isEmpty(orgNameClicked)) {
        const orgDomainAvailable = await orgDomainCheck();
        if (orgDomainAvailable.orgDomainFound) {
          setOrgDomainAvailable("Unique organisation ID not available");
          formOrganizationIdRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
          return;
        }
      }
      const campaignUniqueTextAvailable = await campaignUniqueIdCheck();
      if (campaignUniqueTextAvailable?.domainFound) {
        setCampaignTextAvailable("Unique Campaign ID not available");
        formCampaignUniqueTextRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
        return;
      }
      setOrgDomainAvailable("");
      setCampaignTextAvailable("");
      setLoading(true);
      try {
        setLoading(true);
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_CREATE_CAMPAIGN}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: formValues,
        };

        const response = await axios(config);
        let camapignDetails = {
          campaignName: formValues?.campaignName,
          campaignDescription: formValues?.campaignDescription,
        };
        if (response) {
          localStorage.removeItem(`campaignCreationForm`);
          localStorage.removeItem(`campaignCreationCart`);
          localStorage.setItem(
            "campaignDetils",
            JSON.stringify(camapignDetails)
          );
          setLoading(false);
          navigate("/campaigns/create/success");
        }
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    }
  };
  const validate = (values) => {
    const errors = {};

    // if (_.isEmpty(values.isPrivate)) {
    //   errors.isPrivate = "This field is required  ";
    //   textInputPrivateOrPublic.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "start",
    //   });
    // }
    if (_.isEmpty(values.packDetails)) {
      errors.packDetails = "Please select a plan  ";
      formPackRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }

    if (_.isEmpty(values.phone)) {
      errors.phone = "This field is required  ";
      textInputContactNumber.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!validatePhone(values.phone)) {
      errors.phone = "Please enter a valid phone number";
      textInputContactNumber.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    // if (_.isEmpty(values.address)) {
    //   errors.address = "This field is required";
    //   textInputAddress.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "start",
    //   });
    // }
    if (_.isEmpty(values.description)) {
      errors.description = "This field is required";
      textInputAboutOrganiser.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }

    if (_.isEmpty(values.email)) {
      errors.email = "This field is required  ";
      textInputEmail.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!validateEmail(values.email)) {
      errors.email = "Please enter a valid Email";
      textInputEmail.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }

    if (_.isEmpty(values.campaignUniqueText)) {
      errors.campaignUniqueText = "This field is required  ";
      formCampaignUniqueTextRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    if (_.isEmpty(values.orgDomain)) {
      errors.orgDomain = "This field is required  ";
      formOrganizationIdRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }

    if (_.isEmpty(values.name)) {
      errors.name = "This field is required  ";
      formOrganizationNameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    if (!values.sponsorShipStatus) {
      errors.sponsorShipStatus = "This field is required  ";
      textInputContestType.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    if (_.isEmpty(values.pin) && values.isPrivate) {
      errors.pin = "This field is required  ";
    }
    // if (values.tandc === "<p></p>\n" || _.isEmpty(values.tandc)) {
    //   errors.tandc = "This field is required  ";
    //   textInputTandc.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "start",
    //   });
    // }
    // if (_.isEmpty(values.giftDetails)) {
    //   errors.giftDetails = "This field is required  ";
    //   textInputGifts.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "start",
    //   });
    // }
    if (_.isEmpty(values.campaignDescription)) {
      errors.campaignDescription = "This field is required";
      textInputCampaignDescription.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    if (_.isEmpty(values.campaignName)) {
      errors.campaignName = "This field is required";
      textInputCampaignName.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    // window.scrollTo({ top: 0, behavior: "smooth" });
    return errors;
  };
  const orgDomainCheck = async () => {
    setLoading(true);
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_ORGDOMAIN_CHECK}?domain=${formValues?.orgDomain}`
      );
      setLoading(false);

      return data?.data;
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      //navigate("/error")
    }
  };
  const campaignUniqueIdCheck = async () => {
    setLoading(true);
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_CAMPAIGN_DOMAIN_CHECK}/${formValues?.orgDomain}/${formValues?.campaignUniqueText}`
      );
      setLoading(false);
      return data?.data?.data;
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      //navigate("/error")
    }
  };

  const getorganizationList = async () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_GET_ORGANIZATION_LIST}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);

    setOrganizationList(response?.data?.data);
  };
  const handleOrgNameClick = (e, el) => {
    e.preventDefault();

    if (el?._id === orgNameClicked?._id) {
      setOrgNameClicked({});
      setFormValues({
        ...formValues,
        name: "",
        orgDomain: "",
        organizationId: "",
      });
    } else {
      setOrgNameClicked(el);
      setFormValues({
        ...formValues,
        name: el?.organizationName,
        orgDomain: el?.orgUniqueText,
        organizationId: el?._id,
        email: el?.email,
        description: el?.description,
        phone: el?.phone,
      });
    }
  };
  const handleClearSelection = () => {
    setOrgNameClicked({});
    setFormValues({
      ...formValues,
      name: "",
      orgDomain: "",
      organizationId: "",
    });
  };
  const formatString = (value) => {
    let temp = value;
    temp = temp.replace(/\s+/g, "").substring(0, 20);
    setFormValues({
      ...formValues,
      orgDomain: temp,
    });
    return temp;
  };

  return (
    <>
      {allValueEntered && (
        <CreationCompletionBanner
          onClick={promiseUploadCall}
          loading={loading}
        />
      )}
      <div className="pt-[10px] text-black" ref={formRef}>
        <div className={styles.wrapper}>
          <p className={` mb-[16px] ${styles.heading1}`}>Campaign creation</p>
          <p className={`${styles.desciption1} max-w-[550px]`}>
            Please fill the form below to complete the process of creating the
            campaign.
          </p>
          <div className="seperator w-[100%] my-[24px]" />
          <form action="" className={styles.form}>
            <h4>Details about Campaign</h4>
            {/* CAMPAIGN NAME  */}
            <div className="  w-[100%] mt-[30px]">
              <div className="flex items-center relative">
                <p>
                  Campaign name<span className="text-red-600">*</span>
                </p>
                {helpData?.campaignName && (
                  <HelpPopup content={helpData?.campaignName} />
                )}
              </div>
              <input
                ref={textInputCampaignName}
                type="text"
                className={formErrors.campaignName && styles.inputError}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    campaignName: e.target.value,
                  })
                }
                value={formValues?.campaignName}
              />
              {formErrors.campaignName && (
                <p className={styles.error}>{formErrors.campaignName}</p>
              )}
            </div>
            {/* CAMPAIGN DESCIPTION  */}
            <div className="w-[100%] mt-[24px]">
              <div className="flex items-center relative">
                <p>
                  Campaign description<span className="text-red-600">*</span>
                </p>
                {helpData?.campaignDescription && (
                  <HelpPopup content={helpData?.campaignDescription} />
                )}
              </div>
              <textarea
                ref={textInputCampaignDescription}
                type="text"
                className={formErrors.campaignDescription && styles.inputError}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    campaignDescription: e.target.value,
                  })
                }
                value={formValues?.campaignDescription}
              />
              {formErrors.campaignDescription && (
                <p className={styles.error}>{formErrors.campaignDescription}</p>
              )}
            </div>
            {/* 
          <div className="seperator w-[100%] my-[30px]" />
          <h4>Details about your Campaign Prizes</h4> */}

            {/* ABOUT GIFTS  */}
            {/* <div className="w-[100%]  mt-[24px]">
            <div className="flex flex-col">
              <div className="flex items-center relative">
                <p className={`flex`}>
                  Campaign Gifts & Deatails
                  <span className="text-red-600">*</span>
                </p>
                {helpData?.giftDetails && (
                  <HelpPopup content={helpData?.giftDetails} />
                )}
              </div>
            </div>
            <textarea
              ref={textInputGifts}
              className={`outline-none h-[160px] w-[100%] border-2 rounded-[5px] p-[16px] mt-[20px] ${
                styles.textarea
              } ${formErrors.giftDetails && styles.inputError}`}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  giftDetails: e.target.value,
                })
              }
              value={formValues?.giftDetails}
            />
            {formErrors.giftDetails && (
              <p className={styles.error}>{formErrors.giftDetails}</p>
            )}
          </div> */}
            {/* 
          <div className="seperator w-[100%] my-[30px]" />
          <h4>Terms & Conditions</h4> */}
            {/* TERMS AND CONDITIONS  */}
            {/* <div className="w-[100%]  mt-[24px]">
            <div className="flex flex-col">
              <div className="flex items-center relative">
                <p className={`flex`}>
                  Wirte down all your terms and condition on your campaign
                  <span className="text-red-600">*</span>
                </p>
                {helpData?.tandc && <HelpPopup content={helpData?.tandc} />}
              </div>
            </div>
            <div
              className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] mt-[20px] 
              ${formErrors.tandc && styles.inputError}`}
              ref={textInputTandc}
            >
              <RichtextEditor
                setFormValues={setFormValues}
                formValues={formValues}
              />
            </div>
            {formErrors.tandc && (
              <p className={styles.error}>{formErrors.tandc}</p>
            )}
          </div> */}

            <div className="seperator w-[100%] my-[30px]" />
            <h4>Do you want to create this campaign Public or private? </h4>
            {/* PRIVATE OF PUBLIC  */}
            <div className="w-[100%]  mt-[24px]">
              <div
                className="flex items-center relative"
                ref={textInputPrivateOrPublic}
              >
                {/* <p className={`mt-[10px] max-w-[605px] `}>
                Do you want to create this contest Public or Private?
                <span className="text-red-600">*</span>
              </p> */}
                {helpData?.isPrivate && (
                  <HelpPopup content={helpData?.isPrivate} />
                )}
              </div>
              <div className="">
                <div className="flex items-center gap-[20px]">
                  <div className="h-[18px] w-[18px]">
                    <input
                      type="radio"
                      className="outline-none h-[18px] w-[18px]"
                      name="public"
                      id="public"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          isPrivate: false,
                        })
                      }
                      checked={!formValues?.isPrivate}
                    />
                  </div>
                  <label
                    htmlFor="public"
                    className={`hover:cursor-pointer ${styles.formdesc}`}
                  >
                    YES (Anyone can participate)
                  </label>
                </div>
                <div className="flex items-center mt-[30px] gap-[20px]">
                  <div className="h-[18px] w-[18px]">
                    <input
                      type="radio"
                      className="outline-none h-[18px] w-[18px]"
                      name="public"
                      id="public2"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          isPrivate: true,
                        })
                      }
                      checked={formValues?.isPrivate}
                    />
                  </div>
                  <label
                    htmlFor="public2"
                    className={`hover:cursor-pointer ${styles.formdesc}`}
                  >
                    NO (Need pin to participate)
                  </label>
                </div>
                {formErrors.isPrivate && (
                  <p className={styles.error}>{formErrors.isPrivate}</p>
                )}
              </div>
            </div>
            {/* PIN  */}
            {formValues.isPrivate && (
              <div className="w-[100%]  mt-[24px]">
                <p className={`mt-[10px] w-[185px]`}>Pin</p>

                <input
                  type="number"
                  min="0"
                  className={`outline-none border-[1px]  p-[10px] mt-[9px] rounded-[5px] bg-white w-[400px] ${styles.forminput} z-[999]`}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      pin: e.target.value,
                    })
                  }
                  value={formValues?.pin}
                />
                {formErrors.pin && (
                  <p className={styles.error}>{formErrors.pin}</p>
                )}
              </div>
            )}

            <div className="seperator w-[100%] my-[30px]" />
            <h4> Do you have any sponsors for this campaign?</h4>
            {/* SPONSOR  */}
            <div className="w-[100%]  mt-[24px]">
              <div
                className="flex items-center relative"
                ref={textInputContestType}
              >
                {/* <p className={`mt-[10px] max-w-[605px]`}>
                Do you have any sponsors for this campaign
                <span className="text-red-600">*</span>
              </p> */}
                {helpData?.sponsorShipStatus && (
                  <HelpPopup content={helpData?.sponsorShipStatus} />
                )}
              </div>
              <div className="">
                <div className="flex items-center gap-[20px]">
                  <div className="h-[18px] w-[18px]">
                    <input
                      type="radio"
                      className="outline-none h-[18px] w-[18px]"
                      name="sponsor"
                      id="sponsor"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          sponsorShipStatus: "ALREADY_HAVE",
                        })
                      }
                      checked={formValues?.sponsorShipStatus == "ALREADY_HAVE"}
                    />
                  </div>
                  <label
                    htmlFor="sponsor"
                    className={`hover:cursor-pointer ${styles.formdesc}`}
                  >
                    YES I have sponsor. (contact our support team to add your
                    sponsor details on your campaign)
                  </label>
                </div>
                <div className="flex items-center mt-[30px] gap-[20px]">
                  <div className="h-[18px] w-[18px]">
                    <input
                      type="radio"
                      className="outline-none h-[18px] w-[18px]"
                      name="sponsor"
                      id="sponsor1"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          sponsorShipStatus: "NOT_INTERESTED",
                        })
                      }
                      checked={
                        formValues?.sponsorShipStatus == "NOT_INTERESTED"
                      }
                    />
                  </div>
                  <label
                    htmlFor="sponsor1"
                    className={`hover:cursor-pointer ${styles.formdesc}`}
                  >
                    NO, I don’t want sponsors
                  </label>
                </div>
                <div className="flex items-center mt-[30px] gap-[20px]">
                  <div className="h-[18px] w-[18px]">
                    <input
                      type="radio"
                      className="outline-none h-[18px] w-[18px]"
                      name="sponsor"
                      id="sponsor2"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          sponsorShipStatus: "REQUESTED",
                        })
                      }
                      checked={formValues?.sponsorShipStatus == "REQUESTED"}
                    />
                  </div>
                  <label
                    htmlFor="sponsor2"
                    className={`hover:cursor-pointer ${styles.formdesc}`}
                  >
                    NO, But we are open for sponsorship
                  </label>
                </div>
                {formErrors.sponsorShipStatus && (
                  <p className={styles.error}>{formErrors.sponsorShipStatus}</p>
                )}
              </div>
            </div>
            <div className="seperator w-[100%] my-[30px]" />

            {/* INFLUENCER  */}
            <div className="w-[100%]  mt-[24px]">
              <h4 className="mb-[24px]">
                {" "}
                Do you want to invite influencers for promoting this campaign?
              </h4>
              <div
                className="flex items-center relative"
                ref={textInputContestType}
              >
                {helpData?.lookingForInfluencer && (
                  <HelpPopup content={helpData?.lookingForInfluencer} />
                )}
              </div>
              <div className="">
                <div className="flex items-center gap-[20px]">
                  <div className="h-[18px] w-[18px]">
                    <input
                      type="radio"
                      className="outline-none h-[18px] w-[18px]"
                      name="lookingForInfluencer"
                      id="lookingForInfluencer"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          lookingForInfluencer: true,
                        })
                      }
                      checked={formValues?.lookingForInfluencer == true}
                    />
                  </div>
                  <label
                    htmlFor="lookingForInfluencer"
                    className={`hover:cursor-pointer ${styles.formdesc}`}
                  >
                    YES
                  </label>
                </div>
                <div className="flex items-center mt-[30px] gap-[20px]">
                  <div className="h-[18px] w-[18px]">
                    <input
                      type="radio"
                      className="outline-none h-[18px] w-[18px]"
                      name="lookingForInfluencer"
                      id="sponsorDetails1"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          lookingForInfluencer: false,
                        })
                      }
                      checked={formValues?.lookingForInfluencer == false}
                    />
                  </div>
                  <label
                    htmlFor="sponsorDetails1"
                    className={`hover:cursor-pointer ${styles.formdesc}`}
                  >
                    NO
                  </label>
                </div>

                {formErrors.lookingForInfluencer && (
                  <p className={styles.error}>
                    {formErrors.lookingForInfluencer}
                  </p>
                )}
              </div>
            </div>
            <div className="seperator w-[100%] my-[30px]" />
            <h4> Details about Organization</h4>

            {!_.isEmpty(organizationList) && (
              <div className=" mt-[20px]">
                <p className={``}>
                  Select an existing organisation or create a new one.
                </p>
                <div className="flex flex-wrap gap-[12px] text-small mt-[12px]">
                  {organizationList?.map((el) => {
                    return (
                      <div
                        className={`{                      
                         ${styles.selectOrgButton}
                         ${
                           el?._id === orgNameClicked?._id &&
                           styles.selectedOrgButton
                         }
                    }`}
                        onClick={(e) => handleOrgNameClick(e, el)}
                      >
                        <p>{el?.organizationName}</p>
                        {/* {el?._id === orgNameClicked?._id && (
                        <img
                          src={closeButton}
                          alt="x"
                          className={styles.selectOrgCloseButton}
                        />
                      )} */}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* 
          {!_.isEmpty(orgNameClicked) && (
            <p
              className={` ${styles.clearSelectionInWeb}`}
              onClick={handleClearSelection}
            >
              Clear Selection
            </p>
          )} */}
            <div className="flex flex-wrap w-[100%] items-center justify-between mt-[24px]">
              <div
                className={styles.clearSelectionParent}
                ref={formOrganizationNameRef}
              >
                <div className="flex items-center relative">
                  <p className={` ${styles.formheadingtwo} flex`}>
                    Organization Name
                    <span className="text-[red]">*</span>
                  </p>
                  {helpData?.organizationName && (
                    <HelpPopup content={helpData?.organizationName} />
                  )}
                </div>
                {!_.isEmpty(orgNameClicked) && (
                  <p
                    className={` ${styles.clearSelectionInMobile}`}
                    onClick={handleClearSelection}
                  >
                    Clear Selection
                  </p>
                )}
              </div>
              <input
                type="text"
                className={`${
                  !_.isEmpty(orgNameClicked) ? "text-[#4E04C6]" : ""
                } ${formErrors.name && styles.inputError}`}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    name: e.target.value,
                  })
                }
                value={formValues.name}
                readOnly={!_.isEmpty(orgNameClicked)}
              />
              {formErrors.name && (
                <p className={styles.error}>{formErrors.name}</p>
              )}
            </div>
            <div className="flex flex-wrap w-[100%] items-center justify-between mt-[24px] relative">
              <div
                className="flex items-center relative"
                ref={formOrganizationIdRef}
              >
                <p className={`mt-[10px]  ${styles.formheadingtwo} flex`}>
                  Create a Unique Organization ID
                  <span className="text-[red]">*</span>
                </p>
                {helpData?.organizationId && (
                  <HelpPopup content={helpData?.organizationId} />
                )}
              </div>

              <input
                type="text"
                className={` ${
                  !_.isEmpty(orgNameClicked) ? "text-[#4E04C6]" : ""
                }
              ${
                (formErrors.orgDomain || orgDomainAvailable) &&
                styles.inputError
              }`}
                onChange={(e) => formatString(e.target.value)}
                value={formValues.orgDomain}
                readOnly={!_.isEmpty(orgNameClicked)}
              />
              {formErrors.orgDomain && (
                <p className={styles.error}>{formErrors.orgDomain}</p>
              )}

              {orgDomainAvailable && (
                <p className={styles.error}>{orgDomainAvailable}</p>
              )}
            </div>
            {/* campaign unique text */}
            <div className="flex flex-wrap w-[100%] items-center justify-between mt-[24px]">
              <div
                className={styles.clearSelectionParent}
                ref={formCampaignUniqueTextRef}
              >
                <div className="flex items-center relative">
                  <p className={` ${styles.formheadingtwo} flex`}>
                    Unique Campaign ID
                    <span className="text-[red]">*</span>
                  </p>
                  {helpData?.campaignUniqueText && (
                    <HelpPopup content={helpData?.campaignUniqueText} />
                  )}
                </div>
              </div>
              <input
                type="text"
                className={`
              ${
                (formErrors.campaignUniqueText || campaignTextAvailable) &&
                styles.inputError
              }`}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    campaignUniqueText: e.target.value,
                  })
                }
                value={formValues.campaignUniqueText}
              />
              {formErrors.campaignUniqueText && (
                <p className={styles.error}>{formErrors.campaignUniqueText}</p>
              )}
              {campaignTextAvailable && (
                <p className={styles.error}>{campaignTextAvailable}</p>
              )}
            </div>
            {(!_.isEmpty(formValues.orgDomain) ||
              !_.isEmpty(formValues.campaignUniqueText)) && (
              <p className={styles.note}>
                Url: app.goviral.world/{formValues.orgDomain}/
                {formValues?.campaignUniqueText}
              </p>
            )}
            {/* EMAIL  */}
            <div className="w-[100%]  mt-[24px]">
              <div className="flex items-center relative">
                <p className={``}>
                  Organization Email<span className="text-red-600">*</span>
                </p>
                {helpData?.email && <HelpPopup content={helpData?.email} />}
              </div>
              <input
                ref={textInputEmail}
                type="text"
                className={`outline-none border-2 p-[10px] mt-[10px] rounded-[5px]  w-[100%] sm:w-[50%] ${
                  styles.forminput
                } ${formErrors?.email && styles.inputError}`}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    email: e.target.value,
                  })
                }
                value={formValues?.email}
              />
              {formErrors?.email && (
                <p className={styles.error}>{formErrors.email}</p>
              )}
            </div>

            {/* ABOUT ORGANIZER  */}
            <div className="w-[100%]  mt-[24px]">
              <div className="flex items-center relative">
                <p className={`flex`}>
                  About organization<span className="text-red-600">*</span>
                </p>
                {helpData?.aboutOrganizer && (
                  <HelpPopup content={helpData?.aboutOrganizer} />
                )}
              </div>
              <textarea
                ref={textInputAboutOrganiser}
                className={`${formErrors.description && styles.inputError}`}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    description: e.target.value,
                  })
                }
                value={formValues?.description}
              />
              {formErrors.description && (
                <p className={styles.error}>{formErrors.description}</p>
              )}
            </div>

            {/* ADDRESS  */}
            {/* <div className="w-[100%]  mt-[24px]">
            <div className="flex items-center relative">
              <p className={`mt-[10px]  flex`}>
                Address<span className="text-red-600">*</span>
              </p>
              {helpData?.address && <HelpPopup content={helpData?.address} />}
            </div>
            <input
              ref={textInputAddress}
              type="text"
              className={`outline-none border-2 p-[10px] mt-[10px] rounded-[5px] w-[100%] sm:w-[50%] ${
                styles.forminput
              } ${formErrors.address && styles.inputError}`}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  address: e.target.value,
                })
              }
              value={formValues?.address}
            />
            {formErrors.address && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.address}
              </span>
            )}
          </div> */}

            {/* CONTACT NUMBER  */}
            <div className="w-[100%]  mt-[24px]">
              <div className="flex items-center relative">
                <p className={` flex`}>
                  Contact Number<span className="text-red-600">*</span>
                </p>
                {helpData?.phone && <HelpPopup content={helpData?.phone} />}
              </div>
              {/* <div className='relative'></div> */}
              {/* <div className='absolute top-[15px] left-[5px] flex justify-start items-center pr-[15px]'>
              <img src={flag} alt="" className='w-[18px] h-[18px] mr-[10px] ' />
              +91
            </div> */}
              <input
                ref={textInputContactNumber}
                type="text"
                className={`outline-none border-[1px]  p-[10px] mt-[9px] rounded-[5px] bg-white w-[100%] sm:w-[50%] ${
                  styles.forminput
                } z-[999] ${formErrors.phone && styles.inputError}`}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    phone: e.target.value,
                  })
                }
                value={formValues?.phone}
              />
              {formErrors.phone && (
                <p className={styles.error}>{formErrors.phone}</p>
              )}
            </div>

            <div className="seperator w-[100%] my-[30px]" />
            <h4> Choose a plan to start</h4>
            {formErrors.packDetails && (
              <p className={styles.error}>{formErrors.packDetails}</p>
            )}
            <div className={styles.packWrapper} ref={formPackRef}>
              {/* {step3Details?.pack?.map((obj, index) => {
                return (
                  <div
                    className="w-[260px] rounded-[16px] border-[1px] border-[#4E04C6] p-[16px] shrink-0 flex flex-col cursor-pointer"
                    key={index}
                    onClick={() => {
                      setFormValues({
                        ...formValues,
                        packDetails: {
                          packName: obj?.packName,
                          type: obj?.type,
                        },
                      });
                    }}
                  >
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="packDetails"
                        checked={
                          formValues?.packDetails &&
                          formValues?.packDetails["type"] == obj?.type
                        }
                      />
                      <p>{obj?.packName}</p>
                    </div>
                    <div className="w-[100%]  mt-[32px] mb-[25px]">
                      <h4 className="mb-[16px]">{obj?.heading}</h4>
                      <p className={styles.packDescription}>{obj?.subTitle}</p>
                    </div>

                    <div className="mt-auto flex flex-col gap-[16px]">
                      {obj?.rules?.map((el) => {
                        return <p className={styles.packFeatures}>{el}</p>;
                      })}
                    </div>
                  </div>
                );
              })} */}
              {step3Details?.pack?.map((obj) => (
                <PriceBox
                  data={obj}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              ))}
            </div>

            <div className="seperator w-[100%] my-[30px]" />
            <h4>You will get a dashboard to manage the camapign</h4>
            {/* <p
              className={`${styles.desciption2} text-[#787878] mt-[20px] w-[85%]`}
            >
              You can experience this campaign with selected plan. Understand
              the interest of your participations and proceed the payment
              further if need.{" "}
            </p> */}
            <button
              className="mt-[24px]"
              onClick={promiseUploadCall}
              disabled={loading}
            >
              {loading ? <LoadingCircle /> : "Create campaign"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CamapignCreationForm;
