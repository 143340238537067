import DanceUi from './DanceUi/DanceUi'
import MovieUi from './MovieUI/MovieUi'
import HomeFooter from '../Home/HomePageFooter/HomeFooter';
import React, { useRef, useState, useEffect, useContext } from "react";
import Context from "../../context/Context";
import OrgnizerPage from '../FifaOrganizer/OrgnizerPage';

function  TypeOfContest() {
  const { ContestTypeFuction, typeOfContest } = useContext(Context);
  const org = window.location.pathname.split('/')[1]
  const campaign = window.location.pathname.split('/')[2]
  useEffect(() => {
    ContestTypeFuction(org,campaign)
  }, [])
  console.log(typeOfContest);

  return (
    <div>
      {typeOfContest?.type === 'Sports' && <MovieUi data={typeOfContest}/> }
      {typeOfContest?.type === 'HTL' && <MovieUi data={typeOfContest} />}
      {typeOfContest?.type === 'Mov' && <MovieUi data={typeOfContest} />}
      {typeOfContest?.type === 'Da' && <DanceUi data={typeOfContest} />}
    </div>
  )
}

export default TypeOfContest