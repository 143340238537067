import React, { useState } from 'react'
import { useNavigate } from 'react-router'

import Backbutton from "../Components/Assets/Back.svg";
import styles from '../Components/ImgContestPage/ImagePage.module.css'

function Error() {
    const [signedUrl, setSignedUrl] = new useState(true)
    const [networkError, setNetworkError] = new useState(false)
    const organizerName_fromURL = window.location.pathname.split('/')[1]
    const campaignName_fromURL = window.location.pathname.split('/')[2]
    const contestName_fromURL = window.location.pathname.split('/')[3]
    const navigate = useNavigate();

    return (
        <>
            <img onClick={() => {
                navigate(`/${organizerName_fromURL}/${campaignName_fromURL}/${contestName_fromURL}`)
            }} className={styles.Backbutton} src={Backbutton} alt="" />

            <div className='flex flex-col items-center justify-center  h-[100vh] w-[100%]'>
                <button className='w-[280px] sm:w-[358px] h-[35px] bg-[#AE0927] mx-5 text-[14px]' onClick={() => navigate(`/${organizerName_fromURL}/${campaignName_fromURL}/${contestName_fromURL}`)}>Upload failed</button>
                <a className='text-[#2489FF] underline pt-[20px] text-[17px]' onClick={() => navigate(`/${organizerName_fromURL}/${campaignName_fromURL}/${contestName_fromURL}/participate`)}>Please retry</a>
                <div className='sm:w-[390px] mx-3 text-center text-[#B5B5B5] font-inter text-[17px] pt-[40px]'>
                    {networkError && <p>Sorry for the inconvenience. We understand that you are facing difficulties uploading. Please make sure that your internet connection is stable, and try again.
                        If the error persists, contact us on support@goviral.world.</p>}
                    {signedUrl && <p>We understand that you are facing difficulties in uploading, and we apologise for the delay. Please be patient and try uploading after some time.

                        Meanwhile, you can also reach out to our support team on support@goviral.world or whatsapp : 9567183183.</p>}
                </div>
            </div>
        </>
    )
}

export default Error 