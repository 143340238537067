import React, { createContext, useState } from 'react'

const ViewContext = createContext()

export const ViewContextProvider = ({ children }) => {
  const [ImageApi, setImageApi] = useState()

  return (
    <ViewContext.Provider value={{ ImageApi, setImageApi }}>
      {children}
    </ViewContext.Provider>
  )
}

export default ViewContext
