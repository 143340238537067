import styles from "./styles/DynamicGallery.module.css";
import Play from '../../assests/media.svg'
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import LoveLike from "./../../assests/LoveLike.svg";
import Eye from "./../../assests/Eyeview.svg";
import TitleDescription from "./TitleDescription";
import PopUpModel from "../../Components/Reusable/PopUpModel";

function DynamicGallery({ galleryApi }) {
  const [gallery, setgallery] = useState("");
  const galleryFuction = async (campaign) => {
    try {
      const setApi = await axios.get(galleryApi);
      setgallery(setApi.data);
    } catch (err) {
      console.log(err.message);
      setgallery("");
    }
  };
  useEffect(() => {
    galleryFuction();
  }, []);
  console.log(gallery);
  const [popnowImgOrVideo, setpopnowImgOrVideo] = useState("");
  const [indexNumber, setindexNumber] = useState();
  const [popnow, setpopnow] = useState(false);
  const handlePrevious = (e) => {
    setpopnowImgOrVideo(gallery && gallery[indexNumber - 1]);
    setindexNumber(indexNumber - 1);
    if (indexNumber === 0) {
      setpopnowImgOrVideo(
        gallery && gallery[gallery?.length - 1]
      );
      setindexNumber(gallery && gallery.length - 1);
    }
  };

  const handleNext = (e) => {
    setpopnowImgOrVideo(gallery && gallery[indexNumber + 1]);
    setindexNumber(indexNumber + 1);
    if (indexNumber === gallery.length - 1) {
      setindexNumber(0);
      setpopnowImgOrVideo(gallery && gallery[0]);
    }
  };
  return (
    <div id="Showcase" className=" py-[60px]">
      <div className="md:pb-[130px] pb-[60px]">
        <h1 >Viral Showcase</h1>
        <h2 >The viral showcase of goviral, where all the showcased entries from different campaigns </h2>
      </div>
      <div className={styles.coponetHt}>
        <div className={styles.gallery}>
          {gallery && gallery?.map((obj, index) => {
            return (
              <div className={styles.imgDiv}
                onClick={() => {
                  setpopnow(true);
                  setpopnowImgOrVideo(obj);
                  setindexNumber(index);
                }}
              >
                <img
                  className={styles.eachImg}
                  src={obj?.thumbnailUrl}
                  alt=""
                />
                <img className={styles.play} src={Play} alt="" />
                <p className={styles.campaign}>{obj?.likesCount} LIKES • {obj?.viewsCount} VIEWS</p>
                {/* <p className={styles.decript}>{obj?.location}</p> */}
                {/* <div className={styles.tint}>

                </div> */}
                {/* <div className={styles.absol}>
                  <div className={styles.flexme}>
                    <div className="flexio" style={{ gap: "8px" }}>
                      <img src={LoveLike} alt="" />
                      <p>{obj?.likesCount}</p>
                    </div>
                    <div className="flexio" style={{ gap: "8px" }}>
                      <img src={Eye} alt="" />
                      <p>{obj?.viewsCount}</p>
                    </div>
                  </div>
                </div> */}
              </div>
            )
          })}

        </div>
      </div>
      {popnow == true && (
        <PopUpModel
          handlePrevious={handlePrevious}
          indexNumber={indexNumber}
          handleNext={handleNext}
          setpopnow={setpopnow}
          popnowImgOrVideo={popnowImgOrVideo}
        />
      )}
    </div>
  );
}

export default DynamicGallery;
