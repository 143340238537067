import { useRef, useEffect, useState, useContext } from "react";
import "./influencerSponser.css";

import InfluencerListingSlider from "./InfluencerListingSlider";

function NumberOfInfkuencer() {
  let blu = "yellow";
  return (
    <div className="bg-[#032972] w-[100%] mt-[30px] md:mt-[60px]  pb-[24px] md:pb-[104px]">
      <div className="max-w-[1280px]  mx-[auto] pt-[24px] md:pt-[60px]">
        <p className=" max-w-[580px] text-[#FFFFFF] font-semibold text-[21px] md:text-[24px] leading-[24px] md:leading-[30px]">
          We are connected to a wide range of influencers across various domains
        </p>
        <InfluencerListingSlider />
      </div>
    </div>
  );
}

export default NumberOfInfkuencer;
