import React, { useState } from 'react'
import styles from '../../Components/Phonevalidation/Phone.module.css'
import loadingAnimation from '../../assests/loading.json'
import Otp from '../Otp/Otp'
import { useUserAuth } from '../../context/UserAuthContext'
import Lottie from 'react-lottie-player'
import Green from '../../assests/greeinTick.svg'
import * as _ from 'lodash'

function Phone() {
  const { sendOtp, otpShow, numberVerificationLoading } = useUserAuth()
  const [number, setnumber] = useState()
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [numberObj, setNumberObj] = useState({
    countryCode: null,
    number: null,
  })

  const validatePhone = (code) => {
    let regexPhone =
      // eslint-disable-next-line no-useless-escape
      /^\+(?:[0-9]●?){6,14}[0-9]$/

    if (code.match(regexPhone)) {
      setInvalidPhone(false)
      return true
    } else {
      setInvalidPhone(true)
      return false
    }
  }

  const inputvalidation = (e) => {
    setnumber(e)
  }

  const handleSubmitOtp = (e) => {
    e.preventDefault()
   
    if (_.isEmpty(numberObj.countryCode)) {
      setInvalidPhone(true)
      return
    }
    if (_.isEmpty(numberObj.number)) return
    let temp = numberObj.countryCode + numberObj.number
    temp = temp.replace(/\s+/g, '')


    const validateRespose = validatePhone(temp)
    if (!validateRespose) {
      return
    }
    sendOtp(`${temp}`)
  }
  return (
    <div>
      <div className="flex flex-col items-center justify-center  mt-[196px] relative">
        <label
          htmlFor="participantName"
          className="text-[#B5B5B5] mb-[10px] align-items-start font-Inter font-[400] text-[17px] leading-[24px] self-start"
        >
          <span className={styles.phnum}>Phone Number</span>
        </label>
        <div className="flex gap-[10px]">
          <input
            type="text"
            required
            // onChange={(e) => inputvalidation(e.target.value)}
            // onInput={(e) => (e.target.value = e.target.value.slice(0, 13))}
            onChange={(e) =>
              setNumberObj({
                ...numberObj,
                countryCode: e.target.value,
              })
            }
            defaultValue={'+'}
            // className="w-[358px] h-[60px] rounded-[5px]  bg-[#B5B5B5] focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
            className={styles.phoneCountryTag}
          />
          {/* <p className={styles.countrycode}>+91</p> */}
          <input
            type="number"
            required
            // onChange={(e) => inputvalidation(e.target.value)}
            // onInput={(e) => (e.target.value = e.target.value.slice(0, 13))}
            onChange={(e) =>
              setNumberObj({
                ...numberObj,
                number: e.target.value,
              })
            }
            // className="w-[358px] h-[60px] rounded-[5px]  bg-[#B5B5B5] focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
            className={styles.phoneTag}
          />
        </div>
        {invalidPhone && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            Invalid Phone number
          </span>
        )}
        {number && number.length !== 10 && (
          <img className={styles.greenTick} src={Green} alt="" />
        )}
        {numberVerificationLoading ? (
          <div className={styles.radiosBueLoading}>
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              style={{ width: 100, height: 100 }}
            />
          </div>
        ) : (
          <button className={styles.otpSubmit} onClick={handleSubmitOtp}>
            <p className={styles.rewqq}>Request OTP</p>
          </button>
        )}
      </div>
      <div id="recaptcha-container"></div>
      {otpShow === true && <Otp number={number} />}
    </div>
  )
}

export default Phone
