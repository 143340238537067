import { useRef, useEffect, useState, useContext } from "react";
import "./influencerSponser.css";
import JoinAsSponsorPopup from "../JoinAsSponsorPopup/JoinAsSponsorPopup";
import axios from "axios";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import { updateProfile } from "firebase/auth";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { useLocation, useNavigate } from "react-router";
import HelpPopupWithProps from "../HelpPopupWithProps/HelpPopupWithProps";
import PriceBox from "../PriceBox/PriceBox";
import StatusChangerLoading from "../Loading/StatusChangerLoading";
import { isEmpty } from "lodash";
import styles from "./CardOfSponser.module.css";

function CardOfSponser({ data, generateLinkLoading, setGenerateLinkLoading }) {
  const [sponsorPopup, setSponsorPopup] = useState(false);
  const [sponsorFormValue, setSponsorFormValue] = useState({});
  const [response, setResponse] = useState({});
  const { currentUser, setPreviousLocation } = useUserAuth();
  const { userIdToken, verifyUser } = useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const endDate = new Date(data?.endDate);
  const formattedDate = endDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const currentDate = new Date();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const cmpData = location?.state?.data;
    if (!isEmpty(cmpData)) {
      if (cmpData?._id == data?._id) {
        handleJoinSponsorRequest();
        navigate(null);
      }
    }
  }, []);
  const handleJoinSponsorRequest = () => {
    if (currentUser) {
      joinSponsorRequest();
    } else {
      // setPreviousLocation(window.location.pathname);
      // navigate("/signup");
    }
  };
  const joinSponsorRequest = async () => {
    setSponsorPopup(true);
    setGenerateLinkLoading(true);
    try {
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_SPONSOR_CHECK}/${data?.campaignId}/${data?.orgId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        setResponse(response?.data);
        if (response?.data?.ExistingSponsor) {
          const { description, email, logo, name, phone } =
            response?.data?.data;
          setSponsorFormValue({
            description,
            email,
            logo,
            name,
            phone,
          });
        } else {
          setSponsorFormValue({});
        }
        setGenerateLinkLoading(false);
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };

  const createSponsor = async () => {
    setGenerateLinkLoading(true);
    try {
      let url = "";
      if (response?.newSponsor) {
        url = `${process.env.REACT_APP_CREATE_SPONSOR}/${data?.campaignId}/${data?.orgId}`;
      } else {
        url = `${process.env.REACT_APP_UPDATE_SPONSOR}/${data?.campaignId}/${data?.orgId}`;
      }
      let config = {
        method: "post",
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: sponsorFormValue,
      };
      const apiResponse = await axios(config);
      if (apiResponse?.data?.status === "success") {
        setResponse({
          approvedByAdmin: false,
          newSponsor: false,
        });
        // updateUserssProfile();
        setGenerateLinkLoading(false);
      }
    } catch (error) {
      console.log(error?.message);
      setGenerateLinkLoading(false);
    }
  };
  // const updateUserssProfile = async () => {
  //   try {
  //     const updateUserProfile = await updateProfile(currentUser, {
  //       displayName: sponsorFormValue?.name,
  //       phoneNumber: sponsorFormValue?.phone,
  //     });
  //     let config = {
  //       method: "PATCH",
  //       url: `${process.env.REACT_APP_UPDATE_PROFILE_DETAILS}`,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       data: sponsorFormValue,
  //     };
  //     const updateProfileDetails = await axios(config);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  return (
    <>
      {sponsorPopup && (
        <JoinAsSponsorPopup
          sponsorPopup={sponsorPopup}
          setSponsorPopup={setSponsorPopup}
          response={response}
          formValue={sponsorFormValue}
          setFormValue={setSponsorFormValue}
          createSponsor={createSponsor}
          generateLinkLoading={generateLinkLoading}
          payment={data?.sponsorShipAmt}
          data={data}
        />
      )}

      <div className="lg:grid grid-cols-5 gap-[30px] shrink-0 w-full ">
        <div className={styles.campaignCard}>
          <div>
            <div className="flex flex-col lg:flex-row gap-[20px] justify-between">
              <div className="flex-col md:flex-row gap-[18px]  w-[70%] hidden lg:flex">
                <img src={data?.coverImage} />
                <div>
                  <h2 className="mb-[9px]">{data?.campaignName}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${data?.description?.replace(/<[^>]+>/g, "")}`,
                    }}
                  />
                </div>
              </div>
              <div className="block lg:hidden">
                <div className="flex gap-[16px] mb-[9px]">
                  <img src={data?.coverImage} />
                  <h2>{data?.campaignName}</h2>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${data?.description?.replace(/<[^>]+>/g, "")}`,
                  }}
                />
              </div>
              {data?.url && (
                <a href={data?.url} target="_blank" className="shrink-0">
                  <button className={styles.viewCampaignButton}>
                    View campaign
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="10"
                      viewBox="0 0 7 10"
                      fill="none"
                    >
                      <path
                        d="M1 1L5 5L1 9"
                        stroke="#161616"
                        stroke-width="2"
                      />
                    </svg>
                  </button>
                </a>
              )}
            </div>
            <div className="w-full h-[1px] opacity-30 bg-[#D9D9D9] my-[16px]" />
            <p className="py-[10px]">
              Organiser <span>{data?.orgName}</span>
            </p>
            <div className="flex flex-col md:flex-row justify-between md:items-center gap-[20px]">
              <div className="flex flex-col md:flex-row gap-[10px] md:gap-[20%]">
                {data?.campaignType && (
                  <p>
                    Type <span>{data?.campaignType}</span>
                  </p>
                )}
                {data?.timePeriod && (
                  <p>
                    Time Period <span>{data?.timePeriod}</span>
                  </p>
                )}
              </div>
              <button
                onClick={() => {
                  handleJoinSponsorRequest();
                  setSponsorPopup(true);
                }}
              >
                View More Details
              </button>
            </div>
          </div>
        </div>
        <div className={styles.promoterCard}>
          <h5>Sponsors</h5>
          <div className="flex">
            {data?.sponsors?.map((obj2) => (
              <div className={styles.promotorDp}>
                <img src={obj2?.logo} />
              </div>
            ))}
          </div>
          {isEmpty(data?.sponsors) ? (
            <p className={styles.emptyPromotor}>Be the first sponser!</p>
          ) : (
            <p />
          )}
        </div>
      </div>

      {/* <div className="w-[100%] md:p-[10px] cardOfinf relative mb-[10px] border-[1px] border-[#e6e6e6]">
        <div className="flex flex-col md:flex-row w-full">
          <div className="md:w-[15%] w-full h-[170px] md:h-full shrink-0 aspect-[4/5]">
            <img
              className="w-full h-full object-cover"
              src={data?.coverImage}
              alt=""
            />
          </div>
          <div className="w-[100%] px-[16px] py-[20px]">
            <div className="md:ml-[12px]">
              {currentDate < endDate && (
                <div className="chipInCard mt-[10px] md:mt-[0]">
                  Running Campaign
                </div>
              )}
              <p className="mt-[6px] md:mt-[18px] text-[#000000] font-medium text-[16px] md:text-[17px] leading-[20px] md:leading-[20px]">
                {data?.campaignName}
              </p>
              <p className="mt-[8px] text-[#787878] font-nomal text-[12px] md:text-[12px] leading-[14px] md:leading-[14px] max-w-[436px]">
                {data?.campaignDescription}
              </p>
              <div className="flex w-full md:w-[100%]  mt-[16px] gap-[8px] md:gap-[16px] flex-wrap items-center justify-start">
                <div>
                  <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
                    Targeted audience{" "}
                  </p>
                  <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                    Photograhers
                  </p>
                </div>
                <hr className="divider" />
                <div>
                  <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
                    Demography
                  </p>
                  <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                    India
                  </p>
                </div>
                <hr className="divider" />
                <div>
                  <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
                    Expected participation
                  </p>
                  <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                    100K
                  </p>
                </div>
                <hr className="divider" />
                <div>
                  <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
                    Campaign ends on
                  </p>
                  <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                    {formattedDate}
                  </p>
                </div>
                {data?.sponsorShipAmt && (
                  <>
                    <hr className="divider" />
                    <div>
                      <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px] flex gap-[5px] relative">
                        Sponsorship amount{" "}
                        <HelpPopupWithProps text="View Deliverables">
                          <PriceBox data={data?.sponsorPackage} />
                        </HelpPopupWithProps>
                      </p>
                      <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
                        ₹ {data?.sponsorShipAmt}
                      </p>
                    </div>
                  </>
                )}
                <hr className="divider hidden md:block" />
                <div className="flex gap-[10px] w-full justify-end">
                  <a href={data?.sponsorRedirectLink} target="_blank">
                    <button
                      className="collabrator"
                      style={{
                        background: "#FFF",
                        color: "#035bde",
                        border: "1px solid #035bde",
                      }}
                      disabled={generateLinkLoading}
                    >
                      View Campaign
                    </button>
                  </a>
                  <button
                    className="collabrator"
                    onClick={handleJoinSponsorRequest}
                    disabled={generateLinkLoading}
                  >
                    {generateLinkLoading ? <LoadingCircle /> : "Be a sponsor"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default CardOfSponser;
