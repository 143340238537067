import styles from "./styles/ContestBanner.module.css";
import Fifa from "../../assests/WorldCup.jpg";
import Plus from "../../assests/plus.svg";
import Cup from "../../assests/cup.svg";
import FifaBanner from "../../assests/FifaBanner.png";
import FIFAText from "../../assests/FIFAText.svg";
import { useNavigate } from "react-router-dom";

function ContestBanner() {
  const navigate = useNavigate();
  return (
    <div className=" relative mb-[50px] lg:mb-[120px]">
      <img src={Fifa} className={`noMobie ${styles.imgBannerNew}`} alt="" />
      <div className={styles.tint}></div>
      <img src={Fifa} className={`mobileOnly ${styles.imgBanner}`} alt="" />
      <div className={styles.mobleAbs}>
        <img className={styles.cupico} src={Cup} alt="xxx" />
        <img className={`${styles.quter} mx-[auto]`} src={FIFAText} alt="" />
        <p className={styles.create}>CREATE FIFA WORLDCUP 2022 CONTESTS </p>
        <div className={styles.butun}>
          <div onClick={() => navigate("/campaigns/fifa2022")} className="mx-[auto]">
            <img
              className={`mx-[auto]  ${styles.plusMe}`}
              src={Plus}
              alt=""
            />
            <p className={`mt-[10px] ${styles.creatContest}`}>Create your contest</p>
          </div>
        </div>
      </div>

      <div className={`noMobie ${styles.inWriter}`}>
        <div className={styles.fflexToCentre}>
          <img className="w-[94px]" src={Cup} alt="xxx" />
          <img
            className={`${styles.quter} mx-[auto] pt-[20px]`}
            src={FIFAText}
            alt=""
          />
          <p className={`${styles.create} mx-[auto]`}>
            CREATE FIFA WORLDCUP 2022 CONTESTS{" "}
          </p>
          <div onClick={() => navigate("/campaigns/fifa2022")} className="mx-[auto] gap-[10px]">

            <img
              className={`mx-[auto] ${styles.plusMe}`}
              src={Plus}
              alt=""
            />
            <p className={` ${styles.creatContest} mt-[10px]`}>Create your contest</p>
          </div>
        </div>
      </div>

      {/* <div className={`noMobie ${styles.inWriter}`}>
        <div className={styles.fflex}>
          <div className={styles.fifaFlex}>
            <img src={Cup} alt="" />
            <div>
              <img className={styles.quter} src={FIFAText} alt="" />
              <p className={styles.create}>
                Create FIFA World Cup 2022 Contest{" "}
              </p>
            </div>
          </div>

          <div className={styles.butun}  onClick={()=>navigate('/campaigns/fifa2022')} >
            <img src={Plus} alt="" />
            <p>Create FIFA contest</p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ContestBanner;
