import React, { useContext, useEffect, useState } from "react";
import LoadingCircle from "../../Components/LoadingCircle/LoadingCircle";
import axios from "axios";
import CardOfInfluencer from "../../Components/InfluencerAndSponser/CardOfInfluencer";
import Context from "../../context/Context";
import BackHeader from "../../Components/BackHeader/BackHeader";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

function BrandPage() {
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { calendlyLink } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getbrandList();
  }, []);
  const getbrandList = async () => {
    setLoading(true);
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/brands/data.json`
      );
      setBrandList(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <BackHeader handleBack={handleNavigateToPrevLocation} heading="Clients" />
      <div>
        <div className="max-w-[1280px] mx-[auto] pt-[30px]">
          <div className=" md:mx-[auto] px-[20px] md:px-[0] max-w-max md:max-w-[573px]">
            <p className="clashFont  collabTxt">
              Elevate Your Brand with Proven Marketing Solutions
            </p>
            <p className="mb-[30px] mt-[25px] text-[#787878] font-normal text-[14px] md:text-[17px] leading-[20px] md:leading-[22px]">
              Partner with us to unlock new avenues for brand exposure, customer
              engagement, and sustainable growth.
            </p>
            <div class="scroller">
              <span className="clashFont mt-[20px] text-[#4E04C6] font-semibold text-[17px] md:text-[24px] leading-[20px] md:leading-[30px]">
                Marketing Solutions
                <br />
                Brand Growth
                <br />
                Collaborations
                <br />
                Success Stories
                <br />
                Partnering Opportunities
              </span>
            </div>
            <p className="mt-[20px] max-w-[513px] text-[#000000] font-medium text-[14px] md:text-[17px] leading-[20px] md:leading-[22px]">
              Let us assist you in discovering the most exceptional advisors to
              elevate your product to new heights.
            </p>
            <Link
              to="/contact-us"
              state={{ previousLocation: location?.pathname }}
            >
              <button className="talkToUs mt-[26px]">Talk to Us</button>
            </Link>
          </div>
          <div className="px-[16px]">
            <p className="mt-[30px] mb-[20px] text-[#000000] font-semibold text-[14px] md:text-[21px] leading-[20px] md:leading-[24px]">
              Clients
            </p>
            {loading && (
              <div className="w-[100%] h-[30px] flex justify-center my-[20px] bg-white">
                <div className="w-[30px] h-[100%]">
                  <LoadingCircle />
                </div>
              </div>
            )}
            {brandList?.map((obj, index) => {
              return <CardOfInfluencer data={obj} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandPage;
