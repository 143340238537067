import React from "react";
import PopupWrapper from "../../Layouts/PopupWrapper";
import styles from "./PostRequirementPopup.module.css";

export default function PostRequirementPopup({ showPopup, setShowPopup }) {
  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.popupWrapper}>
        <div className={styles.container}>
          <div className={styles.contentDiv}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <h2>Post a Requirement</h2>
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowPopup(false)}
              >
                <path
                  d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                  fill="#979797"
                />
              </svg>
            </div>
            <div className={styles.body}></div>
            <div className={styles.buttonWrapper}>
              <button>Post a requirement</button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
