import React, { useState } from "react";
import styles from "./HelpPopupWithProps.module.css";
import helpIcon from "../../assests/newHome/helpIcon.png";
import helpIconArrow from "../../assests/newHome/helpIconArrow.png";
import closeButton from "../../assests/newHome/closeButton.png";

function HelpPopupWithProps({ text, children }) {
  const [show, setShow] = useState(false);

  return (
    <div
      className="sm:relative ml-[10px] cursor-pointer"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      {text ? (
        <p className="text-[#035bde] underline">{text}</p>
      ) : (
        <img src={helpIcon} onClick={() => setShow(!show)} />
      )}
      {show && (
        <div className={styles.helpPopup}>
          {/* <img src={helpIconArrow} className={styles.helpIconArrow} /> */}
          <img
            src={closeButton}
            className={styles.closeButton}
            onClick={() => setShow(false)}
          />
          {children}
        </div>
      )}
    </div>
  );
}

export default HelpPopupWithProps;
