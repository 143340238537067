import { useRef, useEffect, useState, useContext } from "react";
import "./HomePageOne.css";
import { Link, useNavigate } from "react-router-dom";
import Context from "../../context/Context";

function TrendingCampaign({ data }) {
  const containerRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const dragTimeoutRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    const container = containerRef.current;
    container.classList.add("active");
    const startX = e.pageX - container.offsetLeft;
    const initialScrollLeft = container.scrollLeft;

    const handleMouseMove = (e) => {
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 3; // adjust scrolling speed
      container.scrollLeft = initialScrollLeft - walk;
      setScrollLeft(container.scrollLeft);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      container.classList.remove("active");
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    // wait for 100ms before initiating drag scroll
    dragTimeoutRef.current = setTimeout(() => {
      container.style.scrollBehavior = "unset";
      container.style.overflowX = "scroll";
      container.style.cursor = "-webkit-grabbing";
      container.style.cursor = "grabbing";
      container.style.zIndex = "9999";
    }, 100);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    clearTimeout(dragTimeoutRef.current);
    const container = containerRef.current;
    container.style.scrollBehavior = "smooth";
    container.style.overflowX = "auto";
    container.style.cursor = "auto";
    container.style.zIndex = "unset";
  };

  const handleMoveLeft = () => {
    const container = containerRef.current;
    setScrollLeft(container.scrollLeft - container.offsetWidth);
  };

  const handleMoveRight = () => {
    const container = containerRef.current;
    setScrollLeft(container.scrollLeft + container.offsetWidth);
  };
  let awsUrl = "https://s3.ap-south-1.amazonaws.com/goviral.world/home";
  const { homeFuction, homeData, userList } = useContext(Context);

  useEffect(() => {
    homeFuction();
  }, []);
  const navigate = useNavigate();
  let imgUrlBase = "https://s3.ap-south-1.amazonaws.com/goviral.world/home";
  console.log(userList);
  return (
    <div className="bg-[#1C002B] w-[100%] mt-[85px] md:px-[0] px-[43px]">
      <div className="max-w-[897px] mx-[auto] pt-[24px] md:pt-[80px]">
        <p className="clashFont md:text-left text-center text-[white] font-medium text-[21px] md:text-[28px] leading-[34px] ">
          Trending Campaigns
        </p>
        <p className="mt-[5px] mb-[40px] md:mb-[60px] text-[#787878] md:text-left text-center font-medium text-[14px] md:text-[17px] leading-[22px] md:max-w-[483px]">
          When an unknown printer took a galley of type and scrambled it to make
          a type specimen book.
        </p>
        {/* <div
          className="slider  flex overflow-x-auto"
          ref={containerRef}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleMouseDown}
        >
          {homeData?.campaigns?.map((obj, i) => {
            return (
              <div
                // onClick={() =>
                //   navigate(`/${obj?.organizerName}/${obj?.campaignName}`)
                // }
                className="flex-shrink-0 relative w-1/3 mr-[20px]"
              >
                <p className="absoDes">{obj?.description}</p>
                <img
                  className="w-[100%] object-cover h-[387px]"
                  src={obj?.image}
                  alt=""
                />
              </div>
            );
          })}
        </div> */}
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
          uk-slider="true"
        >
          <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@m gap-[20px]">
            {data?.map((item, i) => {
              return (
                <a target="_blank" className="" href={item?.link}>
                  <div class="uk-panel relative">
                    {/* <p className="absoDes">{obj?.description}</p> */}
                    <img
                      src={item?.image}
                      className="w-[100%] object-cover h-[387px]"
                      alt=""
                    />
                  </div>
                </a>
              );
            })}
          </ul>

          <a
            class="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          ></a>
        </div>
        {/* <div className="flex items-center justify-center md:justify-start mt-[32px]">
          {userList?.slice(0,5).map((obj, i) => {
            return (
              <div className="overMe">
                <img
                  className="rounded-full w-[31px] h-[31px] object-cover"
                  src={obj?.image}
                  alt=""
                />
              </div>
            );
          })}
          <div className="rounded-full ml-[-10px] text-[#787878] font-medium text-[17px] leading-[22px] w-[31px] h-[31px] bg-[#414141] flex items-center justify-center">
            +{userList?.length}
          </div>
        </div> */}
        <div className="flex items-center flex-col md:flex-row justify-between pb-[60px] mt-[20px]">
          <p className=" text-[#787878] mb-[20px] md:mb-[0] md:text-left text-center font-medium text-[14px] md:text-[17px] leading-[22px] md:max-w-[515px]">
            When an unknown printer took a galley of type, Participate to stand
            out from the crowd and go viral
          </p>
          <a href={"https://goviral.world/campaigns"} target="_blank">
            <div className="viewContedt">View All Contest</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default TrendingCampaign;
