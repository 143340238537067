import React, { useState, createContext, useContext, useEffect } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { authentication } from "../store/firebase-config";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  //otp variable
  const [otpError, setOtpError] = useState("");
  const [otpResult, setOtpResult] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [numberVerificationLoading, setNumberVerificationLoading] =
    useState(false);
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const [urlPath, setUrlPath] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState();
  const [previousLocation, setPreviousLocation] = useState();
  const [guestModeEnabled, setGuestModeEnabled] = useState(true);
  const previousLocationFromUrl = urlPath.split("/")[3];
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const campaignUniqueText = localStorage.getItem("campaignUniqueText");

  useEffect(() => {
    setUrlPath(window.location.href);
    // eslint-disable-next-line
  }, []);
  const userId = localStorage.getItem("userId");
  const loginChecker = async (accessToken) => {
    let config1 = {
      method: "post",
      url: `${process.env.REACT_APP_CREATE_USERS}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const loginCheck = await axios(config1);
    localStorage.setItem("userId", loginCheck.data?.userId);
  };
  useEffect(() => {
    const unsubscribe = authentication.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user !== null) {
        setGuestModeEnabled(false);
        if (userId == null || userId == "undefined") {
          loginChecker(user.accessToken);
        }
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);
  const guestUserSignin = () => {
    setGuestModeEnabled(true);
    if (previousLocationFromUrl === "signup") {
      window.location.href = `/`;
    } else {
      if (previousLocation == null) {
        setPageLoading(false);
        window.location.href = urlPath;
      } else {
        setPageLoading(false);
        window.location.href = previousLocation;
      }
    }
  };
  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      setPageLoading(true);
      const login = await signInWithPopup(authentication, provider);
      let config = {};

      config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_ORGANIZER}`,
        headers: {
          Authorization: `Bearer ${login._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };

      const loginCheck = await axios(config);
      const campaignUniqueText = localStorage.getItem("campaignUniqueText");
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setGuestModeEnabled(false);
        if (location?.state?.previousLocation) {
          navigate(
            {
              pathname: location?.state?.previousLocation,
              search: `${searchParams ? createSearchParams(searchParams) : ""}`,
            },
            { state: location?.state }
          );
        } else {
          if (previousLocationFromUrl === "signup") {
            window.location.href = `/`;
            setPageLoading(false);
          } else {
            if (previousLocation == null) {
              if (location?.state?.nextStage == "step3") {
                navigate("/campaigns/create");
              } else {
                navigate(urlPath);
              }
            } else {
              if (location?.state?.nextStage == "step3") {
                navigate("/campaigns/create");
              } else {
                navigate(previousLocation);
              }
            }
          }
        }
        setPageLoading(false);
      } else {
        setPageLoading(false);
        navigate(`/signup`);
      }
    } catch (error) {
      setPageLoading(false);
      navigate(`/signup`);
    }
  };

  // second step
  const updateUserssProfile = async (name, photoUrl, setEditProfile) => {
    try {
      const updateUserProfile = await updateProfile(currentUser, {
        displayName: name,
        photoURL: photoUrl,
      });
      setEditProfile(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  //otp signin
  const otpSignIn = async (number) => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        authentication
      );
      window.recaptchaVerifier.render();
      const otpData = await signInWithPhoneNumber(
        authentication,
        number,
        window.recaptchaVerifier
      );
      return otpData;
    } catch (error) {
      console.log("error", error);
    }
  };

  //Send OTP
  const sendOtp = async (phoneNumber) => {
    setNumberVerificationLoading(true);
    setOtpError("");
    if (phoneNumber === "" || phoneNumber === undefined)
      return setOtpError("Please enter a valid phone number!");
    try {
      const response = await otpSignIn(phoneNumber);

      setOtpResult(response);
      setNumberVerificationLoading(false);
      setOtpShow(true);
    } catch (err) {
      setOtpError(err.message);
    }
  };

  // Resend OTP
  const resendOtp = async (phoneNumber) => {
    signInWithPhoneNumber(
      authentication,
      phoneNumber,
      window.recaptchaVerifier
    );
  };

  //Verify Otp
  const verifyOtp = async (result, otp) => {
    setOtpError("");
    setOtpVerificationLoading(true);
    if (otp === "" || otp === null) return;
    try {
      const otpResponse = await result.confirm(otp);
      let config2 = {};

      config2 = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_ORGANIZER}`,
        headers: {
          Authorization: `Bearer ${otpResponse._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };

      const loginCheck = await axios(config2);
      const campaignUniqueText = localStorage.getItem("campaignUniqueText");
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setGuestModeEnabled(false);
        if (previousLocationFromUrl === "signup") {
          window.location.href = `/`;
          setOtpVerificationLoading(false);
        } else {
          if (previousLocation == null) {
            if (location?.state?.nextStage == "step3") {
              navigate("/campaigns/create");
            } else {
              navigate(urlPath);
            }

            setOtpVerificationLoading(false);
          } else {
            if (location?.state?.nextStage == "step3") {
              navigate("/campaigns/create");
            } else {
              navigate(previousLocation);
            }

            setOtpVerificationLoading(false);
          }
        }
      } else {
        navigate(`/signup`);
        setOtpVerificationLoading(false);
      }
    } catch (err) {
      setOtpError(err.message);
      setOtpVerificationLoading(false);
    }
  };

  return (
    <userAuthContext.Provider
      value={{
        guestUserSignin,
        googleSignIn,
        updateUserssProfile,
        currentUser,
        pageLoading,
        setPageLoading,
        setPreviousLocation,
        previousLocation,
        loading,
        updateUserssProfile,
        otpSignIn,
        verifyOtp,
        otpError,
        setOtpError,
        otpResult,
        setOtpResult,
        sendOtp,
        otpShow,
        setOtpShow,
        numberVerificationLoading,
        otpVerificationLoading,
        urlPath,
        resendOtp,
        guestModeEnabled,
      }}
    >
      {!loading && children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
