import React, { useState, useEffect, useContext } from "react";
import black from "./styles/MyCampigns.module.css";
import white from "./stylesWhite/MyCampigns_White.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import InfluNav from "./InfluNav";
import BigArrow from "../../assests/BigArrow.png";
import circle from "../../assests/circle.png";
import Context from "../../context/Context";

function MyCampigns() {
  let { influencer } = useParams();
  const { setIsHomePage, influencerFuction, influencerHomeData } = useContext(Context);
  const navigate = useNavigate();
  const [cmpgListing, setCmpgListing] = useState();
  const influAWSurl =
    "https://s3.ap-south-1.amazonaws.com/goviral.world/influencer";
  const cmpgFunction = async () => {
    try {
      const setApi = await axios.get(
        `https://s3.ap-south-1.amazonaws.com/goviral.world/influencer/${influencer}/campignHosted/data.json`
      );
      setCmpgListing(setApi.data);
    } catch (err) {
      console.log(err.message);
      setCmpgListing("");
    }
  };
  useEffect(() => {
    cmpgFunction();
    influencerFuction(influencer)
    setIsHomePage(false);
  }, []);
  const [styles, setstyles] = useState("")

  const cssSwitcher = () => {
    if (influencerHomeData?.templeteColor === 'white') {
      setstyles(white)

    }
    if (influencerHomeData?.templeteColor === 'black') {
      setstyles(black)

    }
  }
  useEffect(() => {
    cssSwitcher()
  }, [influencerHomeData])
  return (
    <div className="InfluencermobWidth">
      <InfluNav data={influencerHomeData} />
      <div className={styles.bgMe} >
        <div className="relative ">
          <img className={styles.TheCircle} src={circle} alt="" />
          <img
            className={`block md:hidden ${styles.profileMe}`}
            src={influAWSurl + "/" + influencer + cmpgListing?.profilePicture}
            alt=""
          />
          <img
            className={`hidden md:block ${styles.profileMe}`}
            src={
              influAWSurl + "/" + influencer + cmpgListing?.profilePictureDesk
            }
            alt=""
          />
        </div>
        <div className=" pt-[30px]">
          <p className={styles.cpmg}>GOVIRAL CAMPIGNS</p>
          <h3
            className="md:mt-[16px] md:max-w-[952px] md:text-[26px] md:leading-[31px] font-semibold px-[26px] md:px-[100px]"
            style={{ color: influencerHomeData?.templeteColor === 'white' ? "#120A08" : "#ffffff", fontWeight: "500", opacity: "1" }}
          >
            Explore the most popular contests lorem ipsum is simply dummy text
            by {influencer}.
          </h3>
          <h3 style={{ color: influencerHomeData?.templeteColor === 'white' ? "#120A08" : "#ffffff", fontWeight: "500" }} className="mt-[12px] md:max-w-[987px] px-[26px] md:px-[100px]">
            {cmpgListing?.cmpgNote}
          </h3>
          <div className={styles.gridxxx}>
            {influencerHomeData?.hostedCampaigns?.map((obj, i) => {
              return (
                <div className="">
                  <div className="px-[26px] md:px-[0px]">
                    <img
                      className={styles.poster}
                      src="https://images.unsplash.com/photo-1675693383068-5226fcd68c77?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60"
                      alt=""
                    />
                    <p className={styles.cmpgName}>{obj?.campaignName}</p>
                    <h3 style={{ color: influencerHomeData?.templeteColor === 'white' ? "#120A08" : "#ffffff", fontWeight: "500" }} className="mt-[12px]  pb-[30px] md:pb-[0] md:px-[0px]">
                      {obj?.campaignUniqueText}
                    </h3>
                    <p className={styles.viewContestText}>
                      {" "}
                      View contest details
                    </p>
                  </div>
                  <div className="block md:hidden">
                    <div className="pb-[60px]">
                      <div className={styles.explore}>
                        <p className={styles.exportxt}>
                          VIEW CONTEST AND{" "}
                          <span
                            style={{ fontStyle: "italic", fontWeight: "400" }}
                          >
                            PARTICIPATE
                          </span>
                        </p>
                        <img src={BigArrow} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCampigns;
