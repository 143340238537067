import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import "./Collabrator.css";
import { useUserAuth } from "../../context/UserAuthContext";
import axios from "axios";
import Context from "../../context/Context";
import PromoterSharePopup from "../PromotorShare/PromoterSharePopup";
import { updateProfile } from "firebase/auth";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { useNavigate } from "react-router";

function CollabratorCard({
  data,
  orgDetails,
  generateLinkLoading,
  setGenerateLinkLoading,
}) {
  const { currentUser, setPreviousLocation } = useUserAuth();
  const { userIdToken, verifyUser } = useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const [promotorShare, setPromotorShare] = useState(false);
  const [newPromotorId, setNewPromotorId] = useState();

  const initialValues = {
    name: currentUser?.displayName ? currentUser?.displayName : "",
    email: currentUser?.email ? currentUser?.email : "",
    phoneNumber: currentUser?.phoneNumber ? currentUser?.phoneNumber : "",
  };
  const navigate = useNavigate();
  const [influencerFormValue, setInfluencerFormValue] = useState(initialValues);
  const handleShareLink = async () => {
    setGenerateLinkLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_PROMOTOR_GENERATE_LINK}/${data?._id}/${orgDetails?._id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setGenerateLinkLoading(false);
        setNewPromotorId(response?.data);
        setPromotorShare(true);
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };
  const handleJoinasInfluencer = () => {
    if (currentUser) {
      handleShareLink();
    } else {
      setPreviousLocation(window.location.pathname);
      navigate("/signup");
    }
  };
  const createInfluencer = async () => {
    setGenerateLinkLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_INFLUENCER}/${data?._id}/${orgDetails?._id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: influencerFormValue,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setNewPromotorId({
          ...data?._id,
          approvedByAdmin: false,
          newInfluencer: false,
        });
        updateUserssProfile();
        setGenerateLinkLoading(false);
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };
  const updateUserssProfile = async () => {
    try {
      const updateUserProfile = await updateProfile(currentUser, {
        displayName: influencerFormValue?.name,
        phoneNumber: influencerFormValue?.phone,
      });
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_PROFILE_DETAILS}`,
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
          "Content-Type": "application/json",
        },
        data: influencerFormValue,
      };
      const updateProfileDetails = await axios(config);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="flex items-start flex-col justify-between w-[100%]">
      <img
        className="w-[100%] h-[210px] object-cover"
        src={data?.fileUrl}
        alt=""
      />
      <div className="md:mt-[12px] p-[10px] boxColcard">
        {/* <div className="chipInCard mt-[10px] md:mt-[0]">Running Campaign</div> */}
        <p className="mt-[6px] md:mt-[18px] text-[#000000] font-medium text-[16px] md:text-[17px] leading-[20px] md:leading-[20px]">
          {data?.promotionHeader?.promotionName}
        </p>
        <p className="mt-[8px] text-[#787878] font-nomal text-[12px] md:text-[12px] leading-[14px] md:leading-[14px] max-w-[100%]">
          {data?.description}
        </p>
        <div className="flex   md:w-[100%] mt-[16px] gap-[8px] md:gap-[16px] items-center justify-between md:justify-start">
          {/* <div>
            <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
              Participation
            </p>
            <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
              3.7k
            </p>
          </div>
          <hr className="divider" /> */}
          {/* <div>
            <p className="text-[#000000] font-medium text-[10px] md:text-[12px] leading-[16px] md:leading-[14px]">
              Promotors
            </p>
            <p className="clashFont mt-[4px] md:mt-[10px] text-[#035BDE] font-normal text-[14px] md:text-[18px] leading-[17px] md:leading-[22px]">
              100+
            </p>
          </div> */}
          {/* <button
            className="flex joinCol"
            onClick={handleJoinasInfluencer}
            disabled={generateLinkLoading}
          >
            {generateLinkLoading ? <LoadingCircle /> : "Be a promotor "}
          </button> */}
        </div>
      </div>
      {promotorShare && (
        <PromoterSharePopup
          setPromotorShare={setPromotorShare}
          promotionData={data}
          influencerData={newPromotorId}
          influencerFormValue={influencerFormValue}
          setInfluencerFormValue={setInfluencerFormValue}
          createInfluencer={createInfluencer}
          generateLinkLoading={generateLinkLoading}
        />
      )}
    </div>
  );
}

export default CollabratorCard;
