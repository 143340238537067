import React from "react";
import CoverData from "./CoverData";
import styles from "../Home/Home.module.css";
import NavBar from "../../Components/NavBar/NavBar";
import CampaignGid from "./CampaignGid";
import Top10Shocase from "../Top10Showcase//Top10Shocase";
import HomeFooter from "./HomePageFooter/HomeFooter";
import Data from "../../../src/Components/data.json"

function Home() {
  return (
    <div>
      <NavBar />
      <div className={styles.background}>
        <CoverData />
        <CampaignGid />
      </div>
      <Top10Shocase
        title={"Winners"}
        top10Url={'https://s3.ap-south-1.amazonaws.com/goviral.world/cpgn/jayajayahey/winners/data.json'}
      />
      <Top10Shocase
        title={"Showcase"}
        top10Url={`${process.env.REACT_APP_HOME_SHOWCASE_URL}/mainShowcase.json`}
      />
      <HomeFooter />
    </div>
  );
}

export default Home;
