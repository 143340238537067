import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import styles from "../Top10Showcase/Top10Showcase.module.css";
import Play from "../../../src/assests/play.svg";
import Context from "../../context/Context";
import PopUpModel from "../../Components/Reusable/PopUpModel";
import { useNavigate } from 'react-router-dom'


function Top10Shocase({ top10Url, title }) {
  const navigate = useNavigate()
  const [top10Showcase, setTop10Showcase] = useState("");
  const top10Fuction = async (campaign) => {
    try {
      const setApi = await axios.get(top10Url);
      setTop10Showcase(setApi.data);
    } catch (err) {
      console.log(err.message);
      setTop10Showcase("");
    }
  };
  useEffect(() => {
    top10Fuction();
  }, []);
  const [popnowImgOrVideo, setpopnowImgOrVideo] = useState("");
  const [indexNumber, setindexNumber] = useState();
  const [popnow, setpopnow] = useState(false);
  const handlePrevious = (e) => {
    setpopnowImgOrVideo(top10Showcase && top10Showcase[indexNumber - 1]);
    setindexNumber(indexNumber - 1);
    if (indexNumber === 0) {
      setpopnowImgOrVideo(
        top10Showcase && top10Showcase[top10Showcase?.length - 1]
      );
      setindexNumber(top10Showcase && top10Showcase.length - 1);
    }
  };

  const handleNext = (e) => {
    setpopnowImgOrVideo(top10Showcase && top10Showcase[indexNumber + 1]);
    setindexNumber(indexNumber + 1);
    if (indexNumber === top10Showcase.length - 1) {
      setindexNumber(0);
      setpopnowImgOrVideo(top10Showcase && top10Showcase[0]);
    }
  };
  return (
    <div>
      {top10Showcase.length >= 1 && 
      <div className={styles.background}>
        <div className="desktopWidthController">
          <p className={styles.title}>{title}</p>
          <div>
            <div className={styles.coloumncontroller}>
              {top10Showcase &&
                top10Showcase?.map((obj, index) => {
                  return (
                    <div style={{ position: "relative" }} key={obj._id}>
                      <div className={styles.absoliieee}>
                        <p className={styles.insideColurc}>
                          {obj?.campaignName}
                        </p>
                        <p className={obj?.shareUrl ? styles.WithLink : styles.insidePart} onClick={() => window.open(obj?.shareUrl && obj?.shareUrl)}>
                          {obj?.participantName}
                        </p>
                      </div>
                      <img
                        className={
                          obj.thumbnailUrl ? styles.thumbnailUrl : styles.imgUrl
                        }
                        onClick={() => {
                          setpopnow(true);
                          setpopnowImgOrVideo(obj);
                          setindexNumber(index);
                        }}
                        src={obj.thumbnailUrl ? obj.thumbnailUrl : obj.fileUrl}
                        alt=""
                      />
                      {obj.fileType == "video" && (
                        <img className={styles.playbtnShow} src={Play} alt="" />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
}
      {popnow == true && (
        <PopUpModel
          handlePrevious={handlePrevious}
          indexNumber={indexNumber}
          handleNext={handleNext}
          setpopnow={setpopnow}
          popnowImgOrVideo={popnowImgOrVideo}
        />
      )}
    </div>
  );
}

export default Top10Shocase;
