import axios from 'axios'
import React, { useEffect, useContext, useState } from 'react'
import Lottie from 'react-lottie-player'
import Context from '../../context/Context'
import loadingAnimation from '../../assests/loading.json'
import success from '../../assests/success.png'
import { useNavigate } from 'react-router'
export default function PaymentSuccess() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { paymentData, transactionDetails, paymentFormDetails } = useContext(
    Context,
  )
  
  const [voucher, setVoucher] = useState([])
  useEffect(() => {
    gettingVoucher(paymentData.tranid, paymentData.layer_pay_token_id)
  }, [])
  const gettingVoucher = async (tranid, layer_pay_token_id) => {
    try {
      setLoading(true)
      let config = {
        method: 'patch',
        url: `https://us-central1-tripsmagic.cloudfunctions.net/goviralVoucher/api/goviral/voucher/${tranid}/${layer_pay_token_id}`,
      }
      const getToken = await axios(config)
      
      setVoucher(getToken && getToken.data && getToken.data.voucherCode)
      setLoading(false)
    } catch (error) {
      console.log('err', error)
      setLoading(false)
    }
  }
  
  return (
    //   {/* <h1>Voucher Code Is : {voucher.data.voucherCode}</h1> */}
    //   <h1>Applicant Name : {paymentFormDetails.participantName}</h1>
    //   <h1>Applicant Email : {paymentData.email}</h1>
    //   <h1>Transaction Id : {paymentData.layer_pay_token_id}</h1>
    <div>
      <div className='fixed top-0 left-0 h-[100vh] w-[100vw] bg-black flex flex-col items-center justify-center backdrop-opacity-10'>
        <div className=' h-auto w-[400px] bg-white rounded-xl text-black px-[16px] mt-[55px] pb-[20px]'>
          {loading ? (<div className=" flex items-center justify-center ">
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              style={{ width: 100, height: 100 }}
            />
          </div>) : (
            <>
              <div className=' w-[100%] flex flex-col items-center  pt-[24px] '>
                <img src={success} alt="success" className='h-[40px] w-[40px]'/>
                <span className='text-[21px] text-[#016629] font-semibold pt-[10px]'>Payment Successfull!</span>
                <span className='pt-[30px]'>YOUR CONTEST ENTRY VOUCHER CODE IS <span className='text-blue-500'>{voucher}</span></span>
              </div>
              <div className='h-[60%] w-[100%]  flex flex-col py-[20px] justify-between pt-[30px]'>
                <div className=' '>
                  <span className='opacity-[0.68] font-medium text-[17px]'>APPLICANT NAME</span><br />
                  
                  <span className='text-[17px] mt-[10px]'>{paymentFormDetails.participantName}</span>
                </div>
                <div className=''>
                  <span className='opacity-[0.68] font-medium text-[17px]'>APPLICANT EMAIL</span><br />
                  
                  <span className='text-[17px]'>{paymentData.email}</span>
                </div>
                <div className=' '>
                  <span className='opacity-[0.68] font-medium text-[17px]'>TRANSACTION ID</span><br />
                  
                  <span className='text-[17px]'>{paymentData.layer_pay_token_id}</span>
                </div>
                <span className='text-[#787878]'> <span className='font-bold'>Congrats!</span> Now you can participate on goViral contents. Also you will recieve a email that include <span className='font-bold'>VOUCHER CODE</span> for participation and <span className='font-bold'>Link to</span> download the song to be choreographed. </span>

              </div>
              <div className='h-[56px] w-[100%] bg-[#3080F8] px-6 py-2 rounded-[12px] text-[16px] text-white hover:cursor-pointer flex items-center justify-center mb-[20px]' onClick={() => { window.open("/kalavathyteacher/navadurgasamarpanam","_self") }}>Participate</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
