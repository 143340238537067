import styles from '../HostContestDetails/HostContestDetails.module.css'
import FormForContest from '../FormToParticipate/FormFullSection'
import React, { useState, useEffect, useContext } from 'react'
import Context from '../../context/Context'
import NavBar from '../NavBar/NavBar'

function HostContestDetails() {
  const {
    profiledata,
    profileDataFunction,
    awsBaseURL,
    ContestDataFuction,
    contestJson,
  } = useContext(Context)
  useEffect(() => {
    profileDataFunction()
    ContestDataFuction()
  }, [])

  var coverStyle = {
    backgroundImage: 'url(' + contestJson?.contests[0].campaignPoster2 + ')', 
  }
  return (
    <div
    // className='container'
    >
      <NavBar />
      <div className={styles.backgroundcntr}  >
        <div style={coverStyle} className={styles.cover}>
          <div className={styles.cover} >
            <div className={styles.coverTextParent}>
              <a className={styles.linkss} href="https://kalavathyteacher.com/">
                kalavathyteacher.com
              </a>
              <p className={styles.prest}> Presents</p>
              <p className={styles.nametitle}>{profiledata?.contestName} </p>
              <p className={styles.cntestype}>{profiledata?.contest_TYPE}</p>
              <p className={styles.detslsdes}>{profiledata?.contestDetaiils}</p>
            </div>
            {/* <div>
            <div className={styles.coverImageParent}>
              <img className={styles.imapgeabsolute} src={contestJson?.coverImage} alt="" />
            </div>
            <div className={styles.imgdarking}></div>
          <div className={styles.imgdarkingow}></div>
          </div> */}
          </div>
          <div className={styles.heads}>
            <p>
              Winner will be featured in the upcoming album from
              kalavathyteacher.com.
            </p>
          </div>
        </div>
        <div className={styles.cotnesttdetals}>
          <div className={styles.detailsParent}>
            {profiledata?.contestFullDetails?.map((el) => {
              return (
                <div className={styles.contestregarding}>
                  <p className={styles.wheniscon}>{el.q1}</p>
                  {el.date.split("|").map((para) => {
                    return (
                      <p className={styles.dateofoc}>
                        {para}
                      </p>
                    )
                  })}
                </div>
              )

            })}
          </div>
        </div>
      </div>
      <FormForContest />
    </div>
  )
}

export default HostContestDetails
