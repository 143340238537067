import styles from "../HomeOfficial/styles/Slider.module.css";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../context/Context";
import Trend from '../../assests/trending.svg'
import TitleDescription from "./TitleDescription";

function Slider() {
  const { homeFuction, homeData } = useContext(Context);
  useEffect(() => {
    homeFuction();
  }, []);
  const navigate = useNavigate();
  console.log(homeData?.campaigns);
  let imgUrlBase = "https://s3.ap-south-1.amazonaws.com/goviral.world/home";
  return (
    <div id="campaigns" className="my-[60px] md:my-[120px] mx-[auto]">
      <div className="homeWidthController" style={{ gap: "12px" }}>
        {/* <img src={Trend} alt="" /> */}
        <div className="pb-[30px] lg:pb-[130px] md:pb-[60px]">
          <h1>TRENDING CAMPIGNS</h1>
          <h2>Be Yourself and set the Trend</h2>
        </div>
      </div>
      <div className={styles.theSliderGrid}>
        {homeData?.campaigns?.map((obj) => {
          return (
            <div className={styles.theSliderEach} onClick={() =>
              navigate(`/${obj?.organizerName}/${obj?.campaignName}`)
            }>
              <img
                // className={styles.imgfull}
                src={imgUrlBase + obj?.image}
                alt=""
              />
              <p className={styles.campaign}>{obj?.campaignCode}</p>
              <p className={styles.decript}>{obj?.description}</p>
            </div>
          );
        })}

      </div>
      {/* <div className="homeWidthController">
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
          uk-slider="autoplay: false"
        >
          <ul className="uk-slider-items uk-child-width-1-2 gapOfSlideForSlide uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-small">
            {homeData?.campaigns?.map((obj) => {
              return (
                <>
                  <div
                    onClick={() =>
                      navigate(`/${obj?.organizerName}/${obj?.campaignName}`)
                    }
                    className={` ${styles.oneGrid}`}
                  >
                    <img
                      className={styles.imgfull}
                      src={imgUrlBase + obj?.image}
                      alt=""
                    />
                    <div className={styles.glass}>
                      <p className={styles.campaign}>{obj?.campaignCode}</p>
                      <p className={styles.decript}>{obj?.description}</p>
                      <p></p>
                    </div>
                  </div>
                </>
              );
            })}
          </ul>
          <a
            className="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          ></a>
          <a
            className="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          ></a>
        </div>
      </div> */}
    </div>
  );
}

export default Slider;
