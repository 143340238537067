import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";
import InfluencerListingComponent from "../Components/influencerListingComponent/InfluencerListingComponent";
import BackHeader from "../Components/BackHeader/BackHeader";

function PortfolioListingPage() {
  const { secId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();

  const getInfluencersData = async () => {
    try {
      let config = {
        method: "get",
        url: `https://storage.googleapis.com/goviral_magazine_data/influencers/SEC5.json`,
      };
      let result = await axios(config);
      setData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfluencersData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div
        style={{ color: data?.sectionProps?.colorTheme?.textColor }}
        className="w-[100%] min-h-[100vh] mx-auto clashFont"
      >
        <BackHeader
          handleBack={handleNavigateToPrevLocation}
          heading="Public Figures"
        />
        <div className=" md:mx-[auto] px-[20px] mt-[30px] md:px-[0] max-w-max md:max-w-[573px]">
          <p className="clashFont  collabTxt">
            Spotlight on Public Figures: Our Esteemed Network
          </p>
          <p className="mb-[15px] mt-[10px] text-[#787878] font-normal text-[14px] md:text-[17px] leading-[20px] md:leading-[22px]">
            Explore our lineup of prominent public figures who have collaborated
            with us to elevate brands and engage audiences.
          </p>
          <div class="scroller">
            <span className="clashFont mt-[20px] text-[#4E04C6] font-semibold text-[17px] md:text-[24px] leading-[20px] md:leading-[30px]">
              Public Figures
              <br />
              Engagement
              <br />
              Collaborations
              <br />
              Network
              <br />
              Partnering Opportunities
            </span>
          </div>
        </div>
        {data?.sectionData?.influencers ? (
          <>
            <InfluencerListingComponent
              secId={secId}
              data={data?.sectionData?.influencers}
            />
          </>
        ) : (
          <div className="w-[100%] h-[30px] flex justify-center my-[20px] bg-white">
            <div className="w-[30px] h-[100%]">
              <LoadingCircle />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PortfolioListingPage;
