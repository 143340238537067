import styles from "../Home/Home.module.css";
import React, { useState, useEffect, useContext } from "react";
import Context from "../../context/Context";
import { useNavigate } from "react-router-dom";


function CampaignGid() {
  const navigate = useNavigate();
  const { homeFuction, homeData } = useContext(Context);
  useEffect(() => {
    homeFuction();
  }, []);
  console.log(homeData);
  let imgUrlBase = "https://s3.ap-south-1.amazonaws.com/goviral.world/home";
  console.log(homeData.campaigns);
  return (
    <div className="desktopWidthController">
      <p className={styles.campaignHead}>Trending Campaigns</p>
      <div className={styles.thegrid}>
        {homeData?.campaigns?.map((obj) => {
          return (
            <div
              onClick={() =>
                navigate(`/org/${obj?.campaignName}`)
              }
              className={styles.oneGrid}
              style={{ background: obj?.backgroundColorCode }}
            >
              <div className={styles.firstImg}>
                <img
                  className={styles.imgiNgRID}
                  src={imgUrlBase + obj?.image}
                  alt=""
                />
              </div>

              {/* {obj?.organizerLogo && <img
                className={styles.orglogo}
                src={imgUrlBase + obj?.organizerLogo}
                alt=""
              />} */}
              <div
                className={`p-4 ${styles.absoluite}`}
              // style={{ background: obj?.backgroundColorCode }}
              >
                {console.log(obj.backgroundColorCode)}
                <p className={styles.gridHead}>{obj?.campaignCode}</p>
                <p className={styles.gridPara}>{obj?.description}</p>
                <div className="mt-[20px]">
                  <div
                    // onClick={() =>
                    //   navigate(
                    //     `/cmpg/${obj?.organizerName}/${obj?.campaignName}`
                    //   )
                    // }
                    className={styles.buttun}
                  >
                    <p>View More</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>

  );
}

export default CampaignGid;
