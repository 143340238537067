import React, { useEffect, useState } from "react";
import BackHeader from "../Components/BackHeader/BackHeader";
import playBlck from "../assests/playBlck.png";
import { useLocation, useNavigate, useParams } from "react-router";
import SingleVideoPlayer from "../Components/SingleVideoPlayer/SingleVideoPlayer";
import axios from "axios";
import styles from "./styles/DemoCampaignsListingPage.module.css";

export default function DemoCampaignsListingPage() {
  const [howItWorksPopup, setHowItWorksPopup] = useState(false);
  const [data, setData] = useState({});
  const { category } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    getDemoData();
  }, []);

  const getDemoData = async () => {
    try {
      const setApi = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/demoCampaignData.json`
      );
      setData(setApi.data);
    } catch (err) {
      console.log(err.message);
      setData({});
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/campaigns");
    }
  };

  return (
    <div className="bg-white w-[100%] min-h-[60vh]">
      {howItWorksPopup && (
        <SingleVideoPlayer
          setHowToParticipatePopup={setHowItWorksPopup}
          fileUrl={data?.howItWorks}
        />
      )}
      <BackHeader
        handleBack={handleNavigateToPrevLocation}
        heading="Demo Campaigns"
      />
      <div className="max-w-[1280px] mx-[auto] py-[30px] px-[20px] md:px-[80px]">
        <div className=" md:mx-[auto] px-[20px] md:px-[0] max-w-max md:max-w-[573px]">
          <p className="clashFont  collabTxt">{data?.heading}</p>
          <p className="mb-[15px] mt-[10px] text-[#787878] font-normal text-[14px] md:text-[17px] leading-[20px] md:leading-[22px]">
            {data?.description}
          </p>
          <div className=" md:max-w-[573px] mx-[auto]">
            <div
              className="mt-[20px] md:mt-[50px] px-[22px] md:px-[0] flex items-center justify-start gap-[11px] cursor-pointer"
              onClick={() => setHowItWorksPopup(true)}
            >
              <img className=" object-cover " src={playBlck} alt="" />
              <p className="text-[black] font-medium text-[14px] md:text-[17px] leading-[20px] md:leading-[22px] whitespace-nowrap">
                Watch this
              </p>
              <p className="text-[#4E04C6] font-semibold text-[14px] md:text-[17px] leading-[20px] md:leading-[22px]">
                How it’s works on your Product
              </p>
            </div>
          </div>
        </div>
        {data?.filterData?.length > 1 && (
          <div className="flex overflow-x-auto gap-[10px] hideScrollBar mt-[20px] md:mt-[32px] px-[20px] md:px-[15%]">
            {data?.filterData?.map((el, index) => {
              return (
                <div
                  className={`whitespace-nowrap w-auto py-[8px] px-[16px] flex items-center border-[1px] border-[#4E04C6] rounded-[4px] text-small transition-colors duration-300 ease-in ${
                    category == el?.path
                      ? "bg-[#4E04C6] text-white"
                      : "bg-inherit text-[#4E04C6]"
                  } hover:cursor-pointer`}
                  key={index}
                  onClick={() => {
                    navigate(`/campaigns/demo/${el?.path}`);
                  }}
                >
                  {el?.displayKey}
                </div>
              );
            })}
          </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[30px] hideScrollBar mt-[24px] md:mt-[30px] text-black px-[20px] md:px-[15%]">
          {data?.data &&
            data?.data[category] &&
            data?.data[category]?.map((obj, index) => {
              return (
                <a
                  href={`https://app.goviral.world/${obj?.orgDomain}/${obj?.campaignUniqueText}`}
                  target="_blank"
                >
                  <div className={styles.campaignCard} key={index}>
                    <img
                      src={obj?.coverImage}
                      alt=""
                      className="h-[275px] w-[100%] object-cover rounded-t-[16px]"
                    />
                    <div className="h-auto w-[100%] p-[12px] flex flex-col gap-[10px]">
                      <p className="text-large-clash text_Ell">
                        {obj?.campaignName}
                      </p>
                      <p className="text_Ell text-smallest">
                        {obj?.description}
                      </p>
                    </div>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
}
