import styles from "../MovieUI/MovieUi.module.css";
import { useNavigate } from "react-router-dom";
import NavBar from "../../HomeOfficial/Navbar";
import Top10Shocase from "../../Top10Showcase/Top10Shocase";
import Context from "../../../context/Context";
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";

function MovieUi({ data }) {
  const { setIsHomePage } = useContext(Context);
  useEffect(() => {
    setIsHomePage(false)
  }, [])
  
  console.log(data);
  const navigate = useNavigate();
  const imgBseUrl =   "https://s3.ap-south-1.amazonaws.com/goviral.world/cpgn";
console.log(data);
  return (
    <div>
      <NavBar />
      <div
        className={styles.background}
        style={{ background: data?.background }}
      >
        <div className={styles.main} style={{ background: data?.background }}>
          {data?.posterOne && (
            <div className="flexico">
              <img
                className={`noMob ${styles.posterOne}`}
                src={imgBseUrl + data?.posterOne}
                alt=""
              />
              <img
                className={`noMob ${styles.posterTwo}`}
                src={imgBseUrl + data?.posterTwo}
                alt=""
              />
              <img
                className={`noDesk ${styles.posterTwo} `}
                src={data?.mobilePoster? imgBseUrl +'/'+ data.organizerName+data.mobilePoster :data?.posterOne}
                alt=""
              />
            </div>
          )}
          {data?.singlePoster && (
            <div className={styles.singleflexcio}>
              {/* <img className={styles.orgLogo} src={imgBseUrl + data?.organizerLogo} alt="" /> */}
              <img
                className={styles.SingleimgWidths}
                src={imgBseUrl + data?.singlePoster}
                alt=""
              />
            </div>
          )}
          <div className={styles.flexico}>
            <div>
              <p className={styles.welcome}>{data?.contestTitle}</p>
              <p className={`yesToMobile ${styles.givway}`}>{data?.extraGift}</p>  
              <div className="yesToMobile">

                <div
                 onClick={() =>
                  (window.location = `https://app.goviral.world/${data?.organizerName}/${data?.contestName}`)
                }
                  className={styles.buttun}
                >
                  <p>View More Details</p>{" "}
                </div>
              </div>
              <p className={styles.welcomePara}>{data?.contestDetaiils}</p>
              <p className={styles.dateOfprgrm}>{data?.contestDateFrom}</p>
            </div>

            <div className={styles.tabgiveaway}>
              <p className={`noMobie ${styles.givway}`} >{data?.extraGift}</p>
              <div className="noMobie">
                <div
                  onClick={() =>
                    (window.location = `https://app.goviral.world/${data?.organizerName}/${data?.contestName}`)
                  }
                  className={styles.buttun}
                >
                  <p>View More Details</p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data?.showCase === true && (
        <Top10Shocase
          title={"Showcase"}
          top10Url={`${process.env.REACT_APP_CPGN_DATA_URL}/${data?.orgId}/${data?.cmpId}/showcase.json`}
        />
      )}
    </div>
  );
}

export default MovieUi;
