import styles from "./styles/Testimoniel.module.css";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../context/Context";
import TitleDescription from "./TitleDescription";
import Play from '../../assests/media.svg'
import PopUpModel from "../../Components/Reusable/PopUpModel";
import Quotes from '../../Json/Quotes.json'

function Testimoniel() {
  const { TestiMonialFuction, testimoniel } = useContext(Context);
  useEffect(() => {
    TestiMonialFuction();
  }, []);
  const navigate = useNavigate();
  console.log(testimoniel);
  let imgUrlBase = "https://s3.ap-south-1.amazonaws.com/goviral.world/home";



  const [popnowImgOrVideo, setpopnowImgOrVideo] = useState("");
  const [indexNumber, setindexNumber] = useState();
  const [popnow, setpopnow] = useState(false);
  const handlePrevious = (e) => {
    setpopnowImgOrVideo(testimoniel?.testimony && testimoniel?.testimony[indexNumber - 1]);
    setindexNumber(indexNumber - 1);
    if (indexNumber === 0) {
      setpopnowImgOrVideo(
        testimoniel?.testimony && testimoniel?.testimony[testimoniel?.testimony?.length - 1]
      );
      setindexNumber(testimoniel?.testimony && testimoniel?.testimony.length - 1);
    }
  };

  const handleNext = (e) => {
    setpopnowImgOrVideo(testimoniel?.testimony && testimoniel?.testimony[indexNumber + 1]);
    setindexNumber(indexNumber + 1);
    if (indexNumber === testimoniel?.testimony.length - 1) {
      setindexNumber(0);
      setpopnowImgOrVideo(testimoniel?.testimony && testimoniel?.testimony[0]);
    }
  };
  return (
    <div id="testimonial" className="mt-[60px] py-[60px] md:pt-[170px] md:pb-[140px]" style={{ background: "black" }}>
      {/* <TitleDescription
        Txtcolor={"white"}
        title={"See What Others Are Saying!"}
        Decription={
          "Look who is here to talk about Go Viral.Roopesh peethambaran who is a director and actor in malayalam film industry talks about GoViral"
        }
      /> */}
        <div className="pb-[120px] ">
          <h1 style={{color:"white"}}>See What Others Are Saying!</h1>
          <h2 style={{color:"white"}}>Look who is here to talk about Go Viral.Roopesh peethambaran who is a director and actor in malayalam film industry talks about GoViral</h2>
        </div>
      <div className={styles.theSliderGrid}>
        {testimoniel?.testimony?.map((obj, index) => {
          return (
            <div>
              <div onClick={() => {
                setpopnow(true);
                setpopnowImgOrVideo(obj);
                setindexNumber(index);
              }}
                className={styles.theSliderEach} >
                <img
              className={styles.eachImg}
                  src={obj?.thumbnail}
                  alt=""
                />
                <img className={styles.play} src={Play} alt="" />
                <p className={styles.campaign}>{obj?.name}</p>
                <p className={styles.decript}>{obj?.location}</p>
              </div>
            </div>
          );
        })}
        {/* {Quotes && Quotes?.Testimony?.map((obj) => {
          return (
            <div className={styles.theSliderEach}>
              <div className={styles.theSliderEachTemplate} style={{ background: obj?.background}} >
                <p className={styles.quotes}>{obj?.quotes}</p>
              </div>
              <p className={styles.campaign}>{obj?.heading}</p>
              <p className={styles.decript}>{obj?.subtitle}</p>
            </div>

          )
        })} */}

      </div>
      {/* <div className="homeWidthController  mt-[30px]">
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
          uk-slider="autoplay: true"
        >
          <ul className="uk-slider-items gapOfSlide uk-child-width-1-2 uk-child-width-1-4@m uk-child-width-1-2@s uk-grid-small">
            {testimoniel?.testimony?.map((obj, index) => {
              return (
                < >
                  <div
                    onClick={() => {
                      setpopnow(true);
                      setpopnowImgOrVideo(obj);
                      setindexNumber(index);
                    }}
                    className={` ${styles.oneGrid}`}
                  >
                    <img
                      className={styles.imgfull}
                      src={obj?.thumbnail}
                      alt=""
                    />
                    <img className={styles.play} src={Play} alt="" />
                    <div className={styles.glass}>
                      <div className={styles.flexmm}>
                        <img className={styles.imgCircle} src={obj?.dpImage} alt="" />
                        <div>
                          <p className={styles.name}>{obj?.name}</p>
                          <p className={styles.location}>{obj?.location}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </ul>
          <a
            className="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          ></a>
          <a
            className="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          ></a>
        </div>
      </div> */}
      {popnow == true && (
        <PopUpModel
          handlePrevious={handlePrevious}
          indexNumber={indexNumber}
          handleNext={handleNext}
          setpopnow={setpopnow}
          popnowImgOrVideo={popnowImgOrVideo}
        />
      )}
    </div>
  );
}

export default Testimoniel;
