import React from "react";
import Star from "../../assests/Star.png";
import Leaves from "../../assests/rightSideLeves.png";
import "./HomePageOne.css";
import { type } from "@testing-library/user-event/dist/type";
import { useNavigate, useParams } from "react-router";

function CosmeticsWinner({ data }) {
  const navigate = useNavigate();
  const { type } = useParams();
  return (
    <div className="px-[20px] pb-10 relative">
      {/* <p className="text-[#000000] text-[28px] font-semibold leading-[32px] pt-[50px]">
        Use Cases
      </p> */}
      <p className="max-w-[549px] text-[#787878] text-[14px] md:text-[17px] font-noraml leading-[20px] md:leading-[24px] mt-[20px]">
        {data?.subtitle}
      </p>
      {/* this is the box flex */}
      {data?.winner?.map((obj, i) => {
        return (
          <div
            onClick={() => navigate(`/usecases/${obj?.uniqueText}`)}
            className="justify-center items-start  gap-10   grid md:flex pt-[34px] md:pt-[72px]"
          >
            <div className=" w-[100%]  md:w-[275px] h-[197px] md:h-[275px]  bg-[#fff] justify-center items-center  text-center hidden md:flex">
              <p className="text-[#111] text-[30px] font-[600]">
                {obj?.mainText}
              </p>
            </div>

            <div className=" flex flex-col md:flex-row justify-start items-start gap-[19px]">
              <div className="relative w-[100%]  md:w-[275px] h-[197px] md:h-[275px]">
                {/* <img className='w-[71px] h-[73px] absolute top-[-30px] left-[-17px] md:left-[-40px]' src={Star} alt="" /> */}
                <img
                  className="w-[100%] h-[100%] md:aspect-square object-cover"
                  src={obj?.profilePicture}
                  alt=""
                />
              </div>
              <div>
                <p className="clashFont text-[#000000] text-[24px] font-semibold leading-[30px]">
                  {obj?.heading}
                </p>
                <p className="max-w-[342px] md:max-w-[406px] text-[#787878] text-[12px] md:text-[14px] font-noraml leading-[16px] md:leading-[20px] mt-[12px]">
                  {obj?.subtitle}
                </p>
                <div className="grid grid-cols-3 gap-[10px] mt-[10px] md:mt-[38px] relative">
                  <div className="w-full md:w-[135px] aspect-square p-[10px] bg-[white]">
                    <p className="text-[12px] md:text-[15px] font-semibold leading-[16px] md:leading-[20px] text-[#787878]">
                      Duration{" "}
                    </p>
                    <div className="absolute bottom-[10px]">
                      <p className="clashFont text-[#000000] text-[21px] md:text-[32px] font-semibold leading-[24px] md:leading-[30px] max-w-[95px]">
                        {obj?.duration}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-[135px] aspect-square p-[10px] bg-[white]">
                    <p className="text-[12px] md:text-[15px] font-semibold leading-[16px] md:leading-[20px] text-[#787878]">
                      Expected Coverage
                    </p>
                    <div className="absolute bottom-[10px]">
                      <p className="clashFont text-[#000000] text-[21px] md:text-[32px] font-semibold leading-[24px] md:leading-[30px]">
                        {obj?.expectedCoverge}{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    className="w-full md:w-[135px] aspect-square p-[10px] "
                    style={{ background: obj?.costBg }}
                  >
                    <p className="text-[12px] md:text-[15px] font-semibold leading-[16px] md:leading-[20px] text-[#787878]">
                      Campaign Cost
                    </p>
                    <div className="absolute bottom-[10px]">
                      <p className="clashFont text-[#000000] text-[21px] md:text-[32px] font-semibold leading-[24px] md:leading-[30px]">
                        {obj?.cost}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CosmeticsWinner;
