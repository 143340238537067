import styles from "../DanceUi/DanceUi.module.css";
import Musiq from "../../../assests/musiq.svg";
import Arrow from "../../../assests/rightrow.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BLKARROW from "../../../assests/profile/BLKARROW.svg";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import React, { useRef, useState, useEffect, useContext } from "react";
import Context from "../../../context/Context";
import NavBar from "../../NavBar/NavBar";
import logo from "../../../assests/goViralWhiteLogo.svg";
import play from "../../../assests/profile/play.svg";
import pause from "../../../assests/profile/pause.svg";
import playListen from "../../../assests/profile/listen now.svg";
import pauseListen from "../../../assests/profile/pause now.svg";

function DanceUi({ data }) {
  // console.log(data);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [isPlaying, setIsplaying] = useState(false);
  const audioElem = useRef();
  const { profiledata, profileDataFunction, awsBaseURL } = useContext(Context);
  useEffect(() => {
    if (isPlaying) {
      audioElem?.current?.play();
    } else {
      audioElem?.current?.pause();
    }
  }, [isPlaying]);
  const PlayPause = () => {
    setIsplaying(!isPlaying);
  };
  useEffect(() => {
    profileDataFunction();
  }, []);

  const handleCardClick = () => {
    window.location.href = "navadurgasamarpanam";
  };
  return (
    <div>
      <NavBar />
      {profiledata?.isContest === true && (
        <div className={styles.backgroundcntr}>
          <div className={styles.contestflexico}>
            <div className={styles.explantion}>
              <img
                className={styles.imgofexplain}
                src={awsBaseURL + profiledata?.contestProfilePik}
                alt=""
                onClick={handleCardClick}
              />
              <div className={styles.contestText}>
                <p className={styles.headings}>{data?.programName}</p>
                <p className={styles.dataOfcontest}>{data?.contestDateFrom}</p>
                <span onClick={handleCardClick}>
                  <a
                    className={styles.descriptionofLink}
                    href={profiledata?.contestComposerLink}
                  >
                    {profiledata?.contestComposerLinkName}
                  </a>
                  <p className={styles.descriptionoftext}>
                    {" "}
                    {data?.contestComposerDetails}
                  </p>
                </span>

                <div className={styles.yestophone}>
                  {profiledata?.contestSongs.map((obj) => {
                    return (
                      <div className={styles.flla}>
                        <div className={styles.song}>
                          <img
                            className={styles.musicIcon}
                            src={Musiq}
                            alt=""
                          />
                          <p>{obj?.songName}</p>
                        </div>
                        <audio src={obj?.fileUrl} ref={audioElem} />
                        <div className="cursor-pointer flex  justify-center items-center">
                          {isPlaying ? (
                            <img
                              src={pause}
                              alt=""
                              className="w-[36px] h-[36px]"
                              onClick={PlayPause}
                            />
                          ) : (
                            <img
                              src={play}
                              alt=""
                              className="w-[36px] h-[36px]"
                              onClick={PlayPause}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div>
              <p className={styles.titles}>{profiledata?.contestTitle}</p>
              <p className={styles.titlesdata}>{data?.contestDescription}</p>
              <div className={styles.notophone}>
                {profiledata?.contestSongs.map((obj) => {
                  return (
                    <div className={styles.song}>
                      <img className={styles.musicIcon} src={Musiq} alt="" />
                      <p className={styles.songname}>{obj?.songName}</p>
                      <audio src={obj?.fileUrl} ref={audioElem} />
                      <div className={styles.listenpostining}>
                        {isPlaying ? (
                          <img
                            src={pauseListen}
                            alt=""
                            className={styles.listenPlay}
                            onClick={PlayPause}
                          />
                        ) : (
                          <img
                            src={playListen}
                            alt=""
                            className={styles.listenPlay}
                            onClick={PlayPause}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <div className={styles.ribbon}>
              <p className={` ${styles.regtttxtt}`}>
                Register first and upload entry
              </p>
              <div className={styles.flexico}>
                <div className={styles.notophonereg}>
                  <div
                    className={styles.particpate}
                    onClick={() => window.open("/payment")}
                  >
                    <p>Register to participate</p>
                    {/* <img src={Arrow} alt="" /> */}
                  </div>
                </div>
                <div
                  className={styles.gotovirl}
                  onClick={() =>
                    (window.location = `/${data?.organizerName}/${data?.contestName}`)
                  }
                // onClick={() =>
                //   window.open(`/${data?.organizerName}/${data?.contestName}`)
                // }
                >
                  <p>Upload and share your Entry </p>
                  {/* <img src={BLKARROW} alt="" /> */}
                </div>
              </div>

            </div>

            {/* <div>
              <p className={styles.regtttxt}>
                To continue with the registration process.
              </p>
              <div className={styles.notophonereg}>
                <div
                  className={styles.particpate}
                  onClick={() => window.open("/payment")}
                >
                  <p>Register to participate</p>
                  <img src={Arrow} alt="" />
                </div>
              </div>
            </div> */}
            {/* <div className={styles.yestophonereg}>
              <div
                className={styles.particpate}
                onClick={() => window.open("/payment")}
              >
                <p>Register to participate</p>
           
              </div>
            </div> */}
            {/* <div className={styles.uplood}>
              <p className={styles.uploadtext}>Upload and share your Entry</p>
              <p className={styles.thetxt}>
                Explore, Participate and Share the Nava Durga Samarpanam Contest
              </p>
              <div
                className={styles.gotovirl}
                onClick={() =>
                  (window.location = `https://goviral.art/${data?.organizerName}/${data?.contestName}`)
                }
              >
                <p>Upload and share your Entry </p>
                <img src={BLKARROW} alt="" />
              </div>
            </div> */}
            <div className={styles.aboutCenter}>
              <p className={styles.aboutContest}>About the contest</p>
              <Carousel responsive={responsive}>
                <div className={styles.videoprofile}>
                  {data?.contestVideo?.map((videos) => {
                    return (
                      <div className={styles.videox}>
                        <ReactPlayer
                          // light={true}
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          playing={false}
                          controls={true}
                          className={styles.videoWidthHt}
                          url={videos.fileUrl}

                        // className="file"
                        />
                      </div>
                    );
                  })}
                </div>
              </Carousel>
            </div>
          </div>
          <div className={styles.footer}>
            <div className="flex flex-col">
              <img className={styles.logogoviral} src={logo} alt="" />
              <p className="self-end">© goviral.world All rights reserved</p>
              <div className={styles.footerTerms}>
                <a
                  href="https://eguidancelabs.com/goviral-privacy-policy.html"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                <a
                  className="ml-[20px]"
                  href="https://goviral.world/termsAndConditions.html"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DanceUi;
