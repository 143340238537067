import "../HomeForNew2.0/HomePageOne.css";

function SugestedCampigns({ data }) {
  return (
    <div className="mx-[auto] pt-[60px] md:pt-[80px] ">
      <div className="px-[20px] md:px-[0]">
        <p className="text-[28px] leading-[32px] clashFont text-black font-semibold mt-[8px] text-left">
          Suggested Contest & Campaigns.
        </p>
        <p className="mt-[12px] mb-[20px] text-[#787878] font-medium text-[17px] leading-[22px] max-w-[688px]">
          {data?.description}
        </p>
      </div>

      <div
        class="uk-position-relative uk-visible-toggle uk-light"
        tabindex="-1"
        uk-slider="true"
      >
        <ul class="uk-slider-items gap-[10px] uk-child-width-1-1 uk-child-width-1-3@m md:gap-[20px]">
          {data?.campaigns?.map((obj, i) => {
            return (
              <a href={obj?.url}>
                <li key={i}>
                  <div class="uk-panel ">
                    <img
                      className="h-[387px] object-cover w-[100%]"
                      src={obj?.poster}
                      alt=""
                    />
                  </div>
                </li>
              </a>
            );
          })}
        </ul>

        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-previous
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-next
          uk-slider-item="next"
        ></a>
      </div>
    </div>
  );
}

export default SugestedCampigns;
