import React, { useContext, useEffect, useState } from "react";
import styles from "./HomePage.module.css";
import bannerWeb from "../../assests/bannerWeb.jpg";
import bannerMobile from "../../assests/bannerMobile.jpg";
import { Link, useLocation } from "react-router-dom";
import ProductCard from "../../Components/ProductCard/ProductCard";
import ReactPlayer from "react-player";
import axios from "axios";
import Context from "../../context/Context";

export default function HomePage() {
  const [data, setdata] = useState({});
  const { calendlyLink } = useContext(Context);
  const location = useLocation();
  const imageRender = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth <= 640) {
      return bannerMobile;
    } else {
      return bannerWeb;
    }
  };
  useEffect(() => {
    getHomepageData();
  }, []);
  const getHomepageData = async () => {
    try {
      const setApi = await axios.get(
        "https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/home.json"
      );
      setdata(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <div className={styles.landing}>
        <h1>{data?.landing?.heading}</h1>
        <p className="mt-[10px]">{data?.landing?.desciption}</p>
        <Link
          to="/collab/influencer"
          state={{ previousLocation: location?.pathname }}
          className="w-full  mt-[10px]"
        >
          <button className={styles.quote}>Show Collaborations</button>
        </Link>
        <img src={imageRender()} className="mt-[30px]" />
        <h3 className="mt-[35px]">
          Find the quote for generating leads trough GoViral in 2 step for your
          brand or product.
        </h3>
        <div className="w-full flex items-center justify-center gap-[15px] sm:gap-[24px] mt-[24px]">
          <a href={calendlyLink} target="_blank">
            <button className={styles.demo}>Book a Demo</button>
          </a>
          {/* <p className="hidden sm:block">OR</p> */}
          {/* <Link to="/quote">
            <button className={styles.quote}>GET A QUOTE</button>
          </Link> */}
        </div>
      </div>
      <div className="bg-[#f0fbff]">
        <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
          <div className={styles.products}>
            <h2>{data?.products?.heading}</h2>
            <p className="mt-[10px]">{data?.products?.desciption}</p>
          </div>
          {data?.products?.items?.map((obj) => (
            <ProductCard data={obj} order={obj?.order} />
          ))}
        </div>
      </div>
      <div className="bg-[#F3F0FF] py-[24px] lg:py-[70px]">
        <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
          <h2 className={styles.stepsHeading}>For Event Attendees</h2>
          <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start flex-wrap gap-[17px] mt-[18px] lg:mt-[35px]">
            {data?.eventAttandees?.map((obj, index) => (
              <>
                <div
                  className={styles.steps}
                  style={{ background: obj?.background }}
                  key={index}
                >
                  {obj?.displayText}
                </div>
                {index + 1 < data?.eventAttandees?.length && (
                  <div className="rounded-full bg-[#6C6A90] p-[10px] w-fit rotate-90 lg:rotate-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3.00001 11.9999C3.00001 12.3978 3.15804 12.7793 3.43935 13.0606C3.72065 13.3419 4.10218 13.4999 4.50001 13.4999L16.38 13.4999L12.44 17.4399C12.175 17.7243 12.0308 18.1004 12.0377 18.489C12.0445 18.8776 12.2019 19.2484 12.4768 19.5232C12.7516 19.798 13.1224 19.9554 13.511 19.9623C13.8996 19.9691 14.2757 19.8249 14.56 19.5599L21.06 13.0599C21.3409 12.7787 21.4987 12.3974 21.4987 11.9999C21.4987 11.6024 21.3409 11.2212 21.06 10.9399L14.56 4.43994C14.4227 4.29257 14.2571 4.17436 14.0731 4.09238C13.8891 4.0104 13.6905 3.96631 13.4891 3.96276C13.2876 3.9592 13.0876 3.99625 12.9008 4.0717C12.714 4.14714 12.5444 4.25943 12.4019 4.40186C12.2595 4.5443 12.1472 4.71397 12.0718 4.90075C11.9963 5.08752 11.9593 5.28758 11.9628 5.48899C11.9664 5.69039 12.0105 5.88902 12.0924 6.07302C12.1744 6.25702 12.2926 6.42262 12.44 6.55994L16.38 10.4999L4.50001 10.4999C3.67201 10.4999 3.00001 11.1719 3.00001 11.9999Z"
                        fill="#F6F6F6"
                      />
                    </svg>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-[#fff] py-[24px] lg:py-[70px]">
        <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
          <div className={styles.uses}>
            {data?.uses?.map((obj, index) => (
              <div className="min-w-[80%]" key={index}>
                <div className="flex flex-col items-center gap-[16px] relative">
                  <div className="bg-[#6c6a90] rounded-full w-[24px] h-[24px] shrink-0 mx-[4%]" />
                  <h4>{obj?.heading}</h4>
                  {index + 1 < data?.uses?.length && (
                    <div className="w-[50%] bg-[#F3F0FF] rounded-[21px] h-[3px] absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2" />
                  )}
                </div>
                <div className="mt-[70px]">
                  {obj?.items?.map((el) => (
                    <div className="w-full p-[10px] bg-[#f3f0ff] text-[#161616] text-center mb-[16px] rounded-[8px]">
                      <p>{el}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-[#d3f2fe66] py-[24px] lg:py-[70px]">
        <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
          <div className={styles.videoSection}>
            <h2>{data?.video?.heading}</h2>
            <p className="my-[20px] lg:mb-[46pxs ]">
              {data?.video?.subHeading}
            </p>
            <div className="w-full rounded-[24px] aspect-video">
              <ReactPlayer
                config={{
                  file: {
                    attributes: { controlsList: "nodownload" },
                  },
                }}
                playing={false}
                controls={true}
                url={data?.video?.url}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
