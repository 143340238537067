import React, { useContext, useEffect, useRef, useState } from "react";
// import "./../SharePopup/SharePopup.css";
import styles from "./JoinAsSponsorPopup.module.css";
import link from "../../assests/linkblack.svg";
import facebook from "../../assests/facebookblack.svg";
import whatsapp from "../../assests/whatsappblack.svg";
import closebutton from "../../assests/closebuttonblack.png";
import qr from "../../assests/qr.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import proShareBg from "../../assests/proShareBg.jpg";
import axios from "axios";
import Context from "../../context/Context";
import { useUserAuth } from "../../context/UserAuthContext";
import { isEmpty } from "lodash";
import InfCollabCarousel from "../InfCollabCarousel/InfCollabCarousel";
import PopupWrapper from "../../Layouts/PopupWrapper";
import { useLocation, useNavigate } from "react-router";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
const JoinAsSponsorPopup = ({
  sponsorPopup,
  setSponsorPopup,
  response,
  formValue,
  setFormValue,
  createSponsor,
  generateLinkLoading,
  payment,
  data,
}) => {
  const a = window.location.href.split("/")[2];
  const { userIdToken, verifyUser } = useContext(Context);
  const { currentUser, setPreviousLocation } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;
  const logoRef = useRef();
  const location = useLocation();
  const [logo, setLogo] = useState(formValue?.logo || "");
  const [step, setStep] = useState("STEP1");
  const [formErrors, setFormErrors] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    verifyUser();
  }, []);

  const handleFileChange = async (event) => {
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);

    setLogo(url);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_UPLOAD_SPONSOR_LOGO}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    if (upload.status === 200) {
      setFormValue((prevValue) => ({
        ...prevValue,
        logo: upload.data.url,
      }));
    }
  };
  const handleLogoChoose = () => {
    logoRef.current.click();
  };
  const handleChange = (e) => {
    e.preventDefault();
    setFormValue({
      ...formValue,
      [e.target.id]: e.target.value,
    });
  };
  const handleSignin = () => {
    setPreviousLocation(window.location.pathname);
    navigate("/signup", {
      state: {
        previousLocation: location?.pathname,
        data,
      },
    });
  };
  function copyLink() {
    navigator.clipboard.writeText("https://dashboard.goviral.world/sp/payment");
    window.document.execCommand("copy");
    // setSponsorPopup(false);
  }
  const handleSubmit = async () => {
    let error = {};
    if (!formValue?.name) {
      error.name = "Please enter your brand name";
    }
    if (!formValue?.email) {
      error.email = "Please enter your Email address";
    }
    if (!formValue?.phone) {
      error.phone = "Please enter your Phone number";
    }
    // if (!formValue?.logo) {
    //   error.logo = "Please upload your brand logo";
    // }
    setFormErrors(error);
    if (isEmpty(error)) {
      setSubmitLoading(true);
      await createSponsor();
      setSubmitLoading(false);
    }
  };

  return (
    <PopupWrapper visible={sponsorPopup}>
      <div className={styles.popupWrapper}>
        <div className={styles.container}>
          <div className={styles.contentDiv}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <h2>Collaborate with {data?.collabData?.name}</h2>
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setSponsorPopup(false);
                  navigate(null);
                }}
              >
                <path
                  d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                  fill="#979797"
                />
              </svg>
            </div>
            <div className={styles.body}>
              {step == "STEP1" && (
                <div className={styles.infCollab}>
                  <div className="p-[20px] border-b-[1px] md:border-b-[0px] md:border-r-[1px] border-[#D9D9D94D]">
                    {!isEmpty(data?.collabData?.images) && (
                      <InfCollabCarousel images={data?.collabData?.images} />
                    )}
                    <pre className="mt-[20px]">
                      {data?.collabData?.about}{" "}
                    </pre>
                  </div>
                  <div className="flex flex-col gap-[20px] p-[20px]">
                    <pre>{data?.collabData?.collabDesciption} </pre>
                    {data?.collabData?.deliverables && (
                      <p>
                        <strong>Deliverables:</strong>{" "}
                        {data?.collabData?.deliverables}
                      </p>
                    )}
                    {data?.collabData?.eligibility && (
                      <p>
                        <strong>Eligibility:</strong>{" "}
                        {data?.collabData?.eligibility}
                      </p>
                    )}
                    {data?.collabData?.rewards && (
                      <p>
                        <strong>Rewards:</strong> {data?.collabData?.rewards}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {step == "STEP2" && (
                <>
                  {currentUser ? (
                    generateLinkLoading ? (
                      <div className="w-full h-full flex flex-col items-center gap-[30px] justify-center">
                        <div className="w-[50px] h-[50px]">
                          <LoadingCircle />
                        </div>
                        {submitLoading ? (
                          <h4>Submitting Details..</h4>
                        ) : (
                          <h4>Almost there! Fetching your details...</h4>
                        )}
                      </div>
                    ) : (
                      <>
                        {!response?.newSponsor &&
                          !response?.ExistingSponsor && (
                            <>
                              <div className="text-center p-[20px] flex flex-col items-center">
                                {/* <p className="font-semibold text-[18px] sm:text-[21px]">
                            Dear Sponsor
                          </p>
                          <p className="font-medium mt-[20px] text-[14px] sm:text-[16px]">
                            Generate Leads, Make an Impact!
                          </p> */}
                                {/* {response?.isSponsorFound && (
                            <p className="mt-[20px] text-[14px] sm:text-[16px] w-[90%]">
                              Thank you for submitting your request . Once the
                              request is approved by the administrator, you will
                              receive the link via email or WhatsApp.
                            </p>
                          )} */}
                                {/* {((!response?.approvedByAdmin &&
                            response?.newSponsor) ||
                            response?.ExistingSponsor) && (
                            <p className="mt-[20px] text-[14px] sm:text-[16px] w-[90%]">
                              Thank you for your interest in becoming a sponsor
                              and generating leads with us! Please fill out the
                              form below to submit your request. Once approved
                              by the administrator, you'll receive a
                              confirmation email.
                            </p>
                          )} */}
                                {/* {!response?.approvedByAdmin &&
                            !response?.newSponsor &&
                            !response?.ExistingSponsor && (
                              <p className="mt-[20px] text-[14px] sm:text-[16px]">
                                Thank you for submitting your request. Once
                                approved by the administrator, you'll receive a
                                confirmation email. Check your request status on
                                the dashboard.{" "}
                                {payment &&
                                  "If payment is pending, you can conveniently make the payment directly from the dashboard."}
                              </p>
                            )} */}
                                {/* {response?.approvedByAdmin && (
                            <p className="mt-[20px] text-[14px] sm:text-[16px]">
                              Your sponsorship request has been approved by the
                              administrator. You will soon receive a
                              confirmation email
                            </p>
                          )} */}
                              </div>
                              <div className="px-[16px] py-[15px] w-[100%]">
                                <div className="w-full">
                                  {/* <a
                            href="https://dashboard.goviral.world/sp/payment"
                            target="_blank"
                            onClick={copyLink}
                            className="flex gap-[5px] items-center justify-center break-all w-full"
                          >
                            <img
                              src={link}
                              alt=""
                              className="h-[24px] w-[24px]"
                            />
                            https://dashboard.goviral.world/sp/payment
                          </a> */}
                                  {response?.isSponsorFound && (
                                    <h3 className="text-center">
                                      Thank you for submitting your request .
                                      Once the request is approved by the
                                      administrator, you will receive the link
                                      via email or WhatsApp.
                                    </h3>
                                  )}
                                  {!response?.approvedByAdmin &&
                                    !response?.newSponsor &&
                                    !response?.ExistingSponsor && (
                                      <h3 className="text-center">
                                        Thank you for submitting your request.
                                        Once approved by the administrator,
                                        you'll receive a confirmation email.
                                        Check your request status on the
                                        dashboard.{" "}
                                        {/* {payment &&
                                    "If payment is pending, you can conveniently make the payment directly from the dashboard."} */}
                                      </h3>
                                    )}
                                  {response?.approvedByAdmin && (
                                    <h3 className="text-center">
                                      Your sponsorship request has been approved
                                      by the administrator. You will soon
                                      receive a confirmation email
                                    </h3>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        {(response?.newSponsor ||
                          response?.ExistingSponsor) && (
                          <>
                            <div className="text-center p-[20px] flex flex-col items-center">
                              <p className="font-semibold text-[18px] sm:text-[21px]">
                                Dear Sponsor
                              </p>
                              <p className="font-medium mt-[20px] text-[14px] sm:text-[16px]">
                                Generate Leads, Make an Impact!
                              </p>
                              <p className="mt-[20px] text-[14px] sm:text-[16px] w-[90%]">
                                Thank you for your interest in becoming a
                                sponsor and generating leads with us! Please
                                fill out the form below to submit your request.
                                Once approved by the administrator, you'll
                                receive a confirmation email.
                              </p>
                            </div>
                            <div className="w-[100%] h-[1px] bg-black opacity-60" />
                            <div className={styles.formGrid}>
                              <div className={styles.form}>
                                <p>
                                  Name
                                  <span className="text-red-600">*</span>
                                </p>
                                <input
                                  type="text"
                                  id="name"
                                  onChange={handleChange}
                                  value={formValue.name}
                                />
                                {formErrors?.name && (
                                  <p className={styles.errorText}>
                                    {formErrors.name}
                                  </p>
                                )}
                              </div>
                              {/* <div className="mt-[15px] influencerShareGrid">
                            <p>
                              Upload Brand Logo
                              <span className="text-red-600">*</span>
                            </p>
                            <div>
                              <div
                                className="py-[20px] w-[100%] relative flex items-center justify-center border-2 border-dashed"
                                onClick={handleLogoChoose}
                              >
                                <input
                                  ref={logoRef}
                                  className="hidden"
                                  type="file"
                                  onChange={handleFileChange}
                                  accept=".jpg,.jpeg,.png,.svg"
                                  id="logo"
                                />
                                <div className="rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                                  {logo ? (
                                    <div>
                                      <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                                        <img
                                          src={logo}
                                          className="w-auto h-[100%]"
                                        />
                                      </div>
                                      <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                                        Change
                                      </p>
                                    </div>
                                  ) : (
                                    "Select Logo"
                                  )}
                                </div>
                              </div>
                              {formErrors?.logo && (
                                <p className={styles.error}>
                                  {formErrors.logo}
                                </p>
                              )}
                            </div>
                          </div> */}
                              <div className={styles.form}>
                                <p>
                                  Whatsapp Number{" "}
                                  <span className="text-red-600">*</span>
                                </p>
                                <input
                                  type="text"
                                  id="phone"
                                  onChange={handleChange}
                                  value={formValue.phone}
                                />
                                {formErrors?.phone && (
                                  <p className={styles.errorText}>
                                    {formErrors.phone}
                                  </p>
                                )}
                              </div>
                              <div className={styles.form}>
                                <p>
                                  Email <span className="text-red-600">*</span>
                                </p>
                                <input
                                  type="text"
                                  id="email"
                                  onChange={handleChange}
                                  value={formValue.email}
                                />
                                {formErrors?.email && (
                                  <p className={styles.errorText}>
                                    {formErrors.email}
                                  </p>
                                )}
                              </div>
                              <div className={styles.form}>
                                <p>About</p>
                                <textarea
                                  type="text"
                                  id="description"
                                  onChange={handleChange}
                                  value={formValue.description}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )
                  ) : (
                    <div className={styles.signIn}>
                      <p>
                        To promote this campaign and collaborate, please sign in
                        to our platform and fill the details .
                      </p>
                      <button onClick={handleSignin}>
                        Sign in to continue
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.buttonWrapper}>
              {!isEmpty(formErrors) && (
                <p className="w-full text-red-600">
                  {response?.approvedByAdmin == true ||
                  response?.approvedByAdmin == false ||
                  response?.ExistingSponsor == false
                    ? ""
                    : "*Please fill out all mandatory fields"}
                </p>
              )}
              {step == "STEP1" ? (
                <div className="flex w-full items-center justify-end gap-[12px]">
                  <button onClick={() => setStep("STEP2")}>Next</button>
                </div>
              ) : (
                currentUser && (
                  <div className="flex w-full items-center justify-end gap-[12px]">
                    {response?.approvedByAdmin == true ||
                    response?.approvedByAdmin == false ||
                    response?.ExistingSponsor == false ? (
                      <>
                        <button className={styles.dbButton}>
                          <a
                            href="https://dashboard.goviral.world/sp"
                            target="_blank"
                          >
                            View dashboard
                          </a>
                        </button>
                        <button onClick={() => setSponsorPopup(false)}>
                          Close
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        disabled={generateLinkLoading}
                      >
                        {generateLinkLoading ? "Loading" : "Collaborate"}
                      </button>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default JoinAsSponsorPopup;
