import React, { useContext, useEffect, useRef, useState } from "react";
// import "./../SharePopup/SharePopup.css";
import "./PromoterSharePopup.css";

import link from "../../assests/linkblack.svg";
import facebook from "../../assests/facebookblack.svg";
import whatsapp from "../../assests/whatsappblack.svg";
import closebutton from "../../assests/closebuttonblack.png";
import qr from "../../assests/qr.svg";
// import styles from '../SharePopup/SharePopup.module.css'
import { CopyToClipboard } from "react-copy-to-clipboard";
import proShareBg from "../../assests/proShareBg.jpg";
import axios from "axios";
import Context from "../../context/Context";
import { useUserAuth } from "../../context/UserAuthContext";
const PromoterSharePopup = ({
  setPromotorShare,
  fullData,
  promotionData,
  influencerData,
  influencerFormValue,
  setInfluencerFormValue,
  createInfluencer,
  generateLinkLoading,
}) => {
  const a = window.location.href.split("/")[2];
  const { userIdToken, verifyUser } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [logoUploadLoading, setLogoUploadLoading] = useState(false);
  const token = userIdToken || currentUser?.accessToken;
  const logoRef = useRef();
  const [logo, setLogo] = useState("");
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    verifyUser();
    loadUserDetails();
  }, []);
  const loadUserDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_PROFILE_DETAILS}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      let dataFromApi = response?.data?.data;
      setInfluencerFormValue((prevState) => ({
        ...prevState,
        name: dataFromApi?.name,
        email: dataFromApi?.email,
        phone: dataFromApi?.phone,
      }));
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  let copyText = "";
  if (influencerData?.AML) {
    copyText = influencerData?.AML;
  } else {
    copyText =
      "https://" +
      a +
      "/" +
      "pro" +
      promotionData?.organizationId +
      "ent" +
      promotionData?._id +
      "inf" +
      influencerData?.userId;
  }

  function copyLink() {
    navigator.clipboard.writeText(copyText);
    window.document.execCommand("copy");

    // alert(copyText + "\n copied to clipboard")
    setPromotorShare(false);
  }
  const whatsapplink = () => {
    window.open("whatsapp://send?text= " + copyText);
  };
  const facebooklink = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${copyText}`);
    return false;
  };
  const downloadQr = (e) => {
    // window.open(fullData?.qrCodeUrl)
    // e.preventDefault()
    fetch(fullData?.qrCodeUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log('qrCodeUrl' in fullData    );
  const handleChange = (e) => {
    e.preventDefault();
    setInfluencerFormValue({
      ...influencerFormValue,
      [e.target.id]: e.target.value,
    });
  };
  const handleFileChange = async (event) => {
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);

    setLogo(url);
    setLogoUploadLoading(true);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_UPLOAD_SPONSOR_LOGO}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    if (upload.status === 200) {
      setInfluencerFormValue((prevValue) => ({
        ...prevValue,
        dop: upload.data.url,
      }));
    }
    setLogoUploadLoading(false);
  };
  const handleLogoChoose = () => {
    logoRef.current.click();
  };

  return (
    <div className="fixed top-0 left-0 h-[100%] w-[100%] overflow-y-auto no-scrollbar backdrop-blur-xl serviceformbg z-10 px-[20px] pt-[70px] pb-[100px]">
      <div className="flex flex-col bg-[#FFFFFF] shadow-lg my-[20px] mx-auto text-black w-[358px] sm:w-[461px] rounded-lg items-center h-auto ">
        <div
          className="relative w-[100%] rounded-lg"
          style={{ backgroundImage: "url(" + proShareBg + ")" }}
        >
          <div className="flex justify-center w-[100%] h-[60px]">
            {/* <p className="py-[30px]">Share to...</p> */}
          </div>
          <div className="absolute top-6 right-4 hover:cursor-pointer h-[32px] w-[32px] rounded-full bg-white flex items-center justify-center ">
            <img
              src={closebutton}
              onClick={() => setPromotorShare(false)}
              tooltip="Copy to clipboard"
              className="flex h-[14px] w-[14px]"
            />
          </div>
        </div>
        <div className="text-center p-[20px]">
          <p className="font-semibold text-[18px] sm:text-[21px]">
            Dear Influencer, or to be Influencer!
          </p>
          <p className="font-medium mt-[20px] text-[14px] sm:text-[16px]">
            Looks like you liked this product!
          </p>
          {influencerData?.isPromoterFound && (
            <p className="mt-[20px] text-[14px] sm:text-[16px]">
              Thank you for submitting your request to create an affiliate link.
              Once the request is approved by the administrator, you will
              receive the link via email or WhatsApp.
            </p>
          )}
          {!influencerData?.approvedByAdmin && (
            <p className="mt-[20px] text-[14px] sm:text-[16px]">
              Thank you for submitting your request to create an affiliate link.
              Once the request is approved by the administrator, you will
              receive the link via email or WhatsApp.
            </p>
          )}
          {influencerData?.approvedByAdmin && (
            <p className="mt-[20px] text-[14px] sm:text-[16px]">
              Please create your share-url and recommend this to your friends
              and circles so that they also get benefitted. Your recommendations
              will be appreciated by the product and you can contact us to know
              the benefits.
            </p>
          )}
        </div>
        <div className="w-[100%] h-[1px] bg-black opacity-60"></div>
        {influencerData?.approvedByAdmin && (
          <div className="px-[16px] py-[15px] w-[100%]">
            <div className="">
              <div className="flex items-center" onClick={facebooklink}>
                <img
                  src={facebook}
                  alt=""
                  className="h-[24px] w-[24px] mr-[10px]"
                />
                <button
                  className="h-[100%] w-[100%] py-[15px] text-black items-center hover:cursor-pointer flex justify-start"
                  tooltip="Copy to clipboard"
                >
                  Share to Facebook
                </button>
              </div>
            </div>
            <div className="">
              <div className="flex items-center" onClick={whatsapplink}>
                <img
                  src={whatsapp}
                  alt=""
                  className="h-[24px] w-[24px] mr-[10px]"
                />
                <button
                  className="h-[100%] w-[100%] py-[15px] text-black items-center hover:cursor-pointer flex justify-start"
                  tooltip="Copy to clipboard"
                >
                  Share to Whatsapp
                </button>
              </div>
            </div>
            <div className="">
              <CopyToClipboard value={copyText}>
                <div className="flex items-center" onClick={copyLink}>
                  <img
                    src={link}
                    alt=""
                    className="h-[24px] w-[24px] mr-[10px]"
                  />
                  <button
                    className="h-[100%] w-[100%] py-[15px] text-black items-center hover:cursor-pointer flex justify-start"
                    tooltip="Copy to clipboard"
                  >
                    Copy Link
                  </button>
                </div>
              </CopyToClipboard>
            </div>
            {fullData?.qrCodeUrl && (
              <div className="">
                <div
                  className="flex items-center hover:cursor-pointer"
                  onClick={downloadQr}
                >
                  <img
                    src={qr}
                    alt=""
                    className="h-[24px] w-[24px] mr-[10px]"
                  />
                  <button
                    className="h-[100%] w-[100%] py-[15px] text-black items-center hover:cursor-pointer flex justify-start no-underline"
                    tooltip="Copy to clipboard"
                  >
                    Download and share in Instagram
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {influencerData?.newInfluencer && (
          <div className="px-[16px] pb-[15px]  w-[100%]">
            <div className="influencerShareGrid mt-[15px]">
              <p>Name</p>
              <input
                type="text"
                id="name"
                onChange={handleChange}
                value={influencerFormValue.name}
              />
            </div>
            <div
              className="flex flex-col gap-[10px] my-[20px]"
              onClick={handleLogoChoose}
            >
              <label
                htmlFor="logo"
                className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
              >
                Upload Profile Picture
                <span className="text-red-600">*</span>
              </label>
              <input
                ref={logoRef}
                className="hidden"
                type="file"
                onChange={handleFileChange}
                accept=".jpg,.jpeg,.png,.svg"
                id="logo"
              />
              <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]">
                {logo ? (
                  <div>
                    <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                      <img src={logo} className="w-auto h-[100%]" />
                    </div>
                    <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                      Change
                    </p>
                  </div>
                ) : (
                  "Select"
                )}
              </div>
            </div>
            <div className="influencerShareGrid mt-[15px]">
              <p>Whatsapp Number</p>
              <input
                type="text"
                id="phone"
                onChange={handleChange}
                value={influencerFormValue.phone}
              />
            </div>
            <div className="influencerShareGrid mt-[15px]">
              <p>Email</p>
              <input
                type="text"
                id="email"
                onChange={handleChange}
                value={influencerFormValue.email}
              />
            </div>
            <div className="w-[100%] mt-[10px] flex justify-end">
              <button
                className="px-[12px] py-[6px] bg-black text-white rounded-2xl"
                onClick={createInfluencer}
                disabled={generateLinkLoading || logoUploadLoading}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoterSharePopup;
