import Slider from '../HomeOfficial/Slider'
import ContestBanner from './ContestBanner'
import DynamicGallery from './DynamicGallery'
import Navbar from './Navbar'
import ShowCaseSilder from './ShowCaseSilder'
import Testimoniel from './Testimoniel'
import TopBanner from './TopBanner'
import Context from '../../context/Context'
import React, {
    useContext,
    useEffect,
    useState,
    useRef,
    useCallback,
} from "react";

function HomeForNew() {
    const { setIsHomePage } = useContext(Context)
    useEffect(() => {
        setIsHomePage(true)
    }, [])

    return (
        <div className='bgwyt'>
            <Navbar />
            <TopBanner />
            <Slider />
            <ContestBanner />

            <ShowCaseSilder
                title={"Winners"}
                top10Url={'https://s3.ap-south-1.amazonaws.com/goviral.world/home/winners/data.json'} />
            <Testimoniel />
            <DynamicGallery galleryApi={'https://storage.googleapis.com/goviral_showcase/mainShowcase.json'} />
        </div>
    )
}

export default HomeForNew