import styles from "../../Components/Reusable/PopUpModel.module.css";
import Right from "../../assests/Rightreal.svg";
import Left from "../../assests/right.svg";
import close from "../../assests/close.svg";
import ReactPlayer from 'react-player'


function PopUp({ indexNumber, popnowImgOrVideo, setpopnow, handleNext, awsBaseURL, handlePrevious }) {
    console.log(popnowImgOrVideo);
    return (
        <div className={styles.popup}>
            <img className={styles.close} onClick={() => setpopnow(false)} src={close} alt="" />
            <div className={styles.imgmodel}>
                {popnowImgOrVideo?.fileType === 'video' && <ReactPlayer
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        playing={true}
                        controls={true}
                        // muted={true}
                        className={styles.videoplayback}
                        width="100%"
                        url={popnowImgOrVideo?.fileUrl                        }

                    />}
                {popnowImgOrVideo?.fileType === "IMAGE"  && <img className={styles.theimg} src={popnowImgOrVideo?.file} alt="" />}
            </div>
            <img className={styles.previous} onClick={() => handleNext(indexNumber)} src={Right} alt="" />
            <img className={styles.next} onClick={() => handlePrevious(indexNumber)} src={Left} alt="" />
        </div>
    );
}

export default PopUp;