import { useRef, useEffect, useState, useContext } from "react";
import "./influencerSponser.css";
import Insta from "../../assests/whiteInsta.png";

function SponserDisplay() {
  return (
    <div className="bg-[#032972] w-[100%] mt-[30px] md:mt-[60px]  pb-[24px] md:pb-[73px]">
      <div className="max-w-[1280px]   mx-[auto] pt-[24px] md:pt-[60px]">
        <p className=" max-w-[580px] clashFont text-[#FFFFFF] font-semibold text-[21px] md:text-[24px] leading-[24px] md:leading-[30px]">
          Successful Sponsors
        </p>
        <p className="mt-[16px] max-w-[656px] text-[#FFFFFF] font-medium text-[17px] md:text-[17px] leading-[20px] md:leading-[22px] ">
          By partnering with us, you can gain access to a highly engaged
          community of supporters, increase brand exposure, and showcase your
          products and our engaged community of supporters
        </p>
        <div
          class="uk-position-relative uk-visible-toggle uk-light mt-[16px] md:mt-[32px] "
          tabindex="-1"
          uk-slider="true"
        >
          <ul class="uk-slider-items gap-[10px] md:gap-[30px]">
            <li>
              <div class="uk-panel w-[219px] md:w-[392px] h-[109px] md:h-[196px] flex justify-center items-center">
                <img
                  className="flex-[50%] w-1/2 h-[100%] object-cover"
                  src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80"
                  alt=""
                />
                <div
                  className={`flex-[50%] w-1/2 h-[100%] p-[6px] md:p-[12px] relative`}
                  style={{ background: "#4E7EFD" }}
                >
                  <p className="text-[#FFFFFF] font-semibold text-[14px] md:text-[17px] leading-[20px] md:leading-[20px]">
                    Down blue Marketing Company
                  </p>
                  <div className="absolute bottom-[10px]">
                    <div className="flex justify-between gap-[10px] md:gap-[50px] items-baseline">
                      <div>
                        <p className="clashFont text-[#FFFFFF] font-semibold text-[12px] md:text-[32px] leading-[90%] md:leading-[28px]">
                          300+
                        </p>
                        <p className="md:block hidden text-[#FFFFFF] font-semibold text-[12px] md:text-[24px] leading-[16px] md:leading-[24px]">
                          New Costumers
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            
          </ul>

          <a
            class="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          ></a>
        </div>
      </div>
    </div>
  );
}

export default SponserDisplay;
