import React, { useState } from "react";
import Sections from "./HomeForNew2.0/Sections";
import Footer from "./HomeForNew2.0/Footer";
import Navbar from "./HomeOfficial/Navbar";
import { Outlet } from "react-router";

function Layout({ children }) {
  const [filterData, setFilterData] = useState([
    {
      displayKey: "Use Cases",
      urlKey: "usecases",
      url: "https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/cosmetics.json",
      logo: "https://storage.googleapis.com/goviral_home_data/homeMedia/media/caseStudyIcon.svg",
      selectedLogo:
        "https://storage.googleapis.com/goviral_home_data/homeMedia/media/caseStudyIconselected.svg",
    },
    {
      displayKey: "Public Figures",
      urlKey: "Influencers",
      url: "https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/cosmetics.json",
      logo: "https://storage.googleapis.com/goviral_home_data/homeMedia/media/publicFigureIcon.svg",
      selectedLogo:
        "https://storage.googleapis.com/goviral_home_data/homeMedia/media/publicFigureIconselected.svg",
    },
    {
      displayKey: "Clients",
      urlKey: "brands",
      url: "https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/cosmetics.json",
      logo: "https://storage.googleapis.com/goviral_home_data/homeMedia/media/clientsIcon.svg",
      selectedLogo:
        "https://storage.googleapis.com/goviral_home_data/homeMedia/media/clientsIconselected.svg",
    },
  ]);

  return (
    <div>
      <Navbar />
      {/* <Sections filterData={filterData} /> */}
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
