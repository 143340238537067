import React, { useState, useEffect } from "react";
import styles from "./InfCollabCarousel.module.css"; // Import CSS module

const InfCollabCarousel = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the previous slide
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Function to go to the next slide
  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to a specific slide
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  // Set up auto-slide interval
  useEffect(() => {
    const slideInterval = setInterval(() => {
      goToNext();
    }, interval); // Use the interval prop (default: 3000ms)

    // Clean up interval on component unmount
    return () => clearInterval(slideInterval);
  }, [currentIndex, interval]);

  return (
    <div className={styles.carousel}>
      <div className={styles.carouselInner}>
        <button
          className={`${styles.carouselArrow} ${styles.left}`}
          onClick={goToPrevious}
        >
          &lt;
        </button>
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex}`}
          className={styles.carouselImage}
        />
        <button
          className={`${styles.carouselArrow} ${styles.right}`}
          onClick={goToNext}
        >
          &gt;
        </button>
      </div>
      <div className={styles.carouselDots}>
        {images.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              index === currentIndex ? styles.active : ""
            }`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default InfCollabCarousel;
