import React from 'react'
import failed from '../../assests/failed.png'
import { useNavigate } from 'react-router'
export default function PaymentFailed({ setPaymentFail }) {
  const navigate = useNavigate()
  return (
    <div>
      <div className='fixed top-0 left-0 h-[100vh] w-[100vw] bg-black flex flex-col items-center justify-center backdrop-opacity-10'>
        <div className=' h-[480px] w-[400px] bg-white rounded-xl text-black'>
          <div className='h-[100%] w-[100%] flex flex-col items-center justify-around px-[16px]'>
            <div className='flex flex-col items-center justify-center font-bold mt-[20px]'>
              <img src={failed} alt="" className='h-[40px] w-[40px]' />
              <span className='text-[21px] text-[#016629] font-semibold pt-[10px]'>Transaction Failed</span>
            </div>
            <span className='pt-[30px]'>SORRY YOUR CONTEST ENTRY VOUCHER CODE IS NOT GENERATED</span>
            <p className='text-[#787878]'><span className='font-bold'>Sorry,</span> we understand that you are facing some issues in payment. Please make sure you have good internet connection and retry again.</p>
            <p className=''>And if the error stil persists, contact us on <span className='underline'>support@goviral.world</span> or <span className='underline'>+91 99866 19796</span> </p>


            <div className='w-[100%] flex justify-end px-[5%]'>
              <div className='h-[56px] w-[100%] bg-gray-500 px-6 py-2 rounded-[12px] text-[16px] text-white hover:cursor-pointer flex items-center justify-center mb-[20px]' onClick={() => { setPaymentFail(false) }}>Go to Home</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
