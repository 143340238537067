import React from "react";
import styles from "./CreationCompletionBanner.module.css";
import createIcon from "../../assests/creationReview.svg";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

export default function CreationCompletionBanner({ onClick, loading }) {
  return (
    <div className={styles.background}>
      <div className={styles.innerContainer}>
        <div className="flex items-center gap-[9px]">
          <img src={createIcon} alt="cart" />
          <p className="md:block hidden">Your campaign is fully ready...</p>
          <p className="md:hidden block">Your campaign is fully ready...</p>
        </div>

        <button onClick={onClick} disabled={loading}>
          {loading ? <LoadingCircle /> : "Create campaign"}
        </button>
      </div>
    </div>
  );
}
