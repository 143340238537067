import React from 'react'
import RoundedStar from '../../assests/RoundedStar.png'
import LeftLeves from '../../assests/leftLeves.png'


function HighlightAndExperince({ data }) {
    return (
        <div className='w-[100%] bg-[white] relative'>
            <img className='absolute top-[-85px] left-[0] hidden md:block' src={LeftLeves} alt="" />
            <div className='mx-[auto] max-w-[568px] pt-[60px] mt-[60px] md:px-[0] px-[20px]'>
                <p className='max-w-[540px] text-[#000000] text-left text-[21px] md:text-[32px] font-semibold leading-[24px] md:leading-[39px]  clashFont '>Highlights of Goviral campaigns</p>
                {data?.highlight?.map((obj, i) => {
                    return (
                        <div className='flex justify-start items-center gap-[20px] mt-[45px]'>
                            <div className='w-[42px] h-[42px] bg-[#FFE100] aspect-square flex justify-center items-center'>
                                <img src={RoundedStar} alt="" />
                            </div>
                            <p className=' text-[#787878] text-[14px] md:text-[17px] md:leading-[22px] leading-[20px] font-normal '>{obj?.point}</p>
                        </div>
                    )
                })

                }
            </div>
            <div className='mx-[auto] max-w-[568px] mt-[100px] md:px-[0] px-[20px]'>
                <p className='max-w-[540px] text-[#000000] text-left font-semibold text-[21px] md:text-[32px] font-semibold leading-[24px]  clashFont '>Personalize your brand experience with Goviral.</p>
                <p className='max-w-[649px] text-left text-[#787878] text-[14px] md:text-[17px] font-noraml leading-[22px] md:leading-[24px] mt-[16px]'>Goviral is a powerful tool that allows you to customize and personalize your brand experience like never before. With Goviral, you can create targeted and relevant campaigns that speak directly to your audience's needs and interests, increasing engagement, loyalty, and ultimately, your bottom line</p>
                {data?.brandExperince?.map((obj, i) => {
                    return (
                        <div className='flex justify-start items-center gap-[20px] mt-[24px] md:mt-[45px]'>
                            <div className='w-[24px] h-[24px] bg-[#FFE100] rounded-full flex justify-center items-center'>
                                <p className='text-[#000000] text-[12px] font-bold leading-[16px] '>{i + 1}</p>
                            </div>
                            <p className='text-[#000000] text-[14px] md:text-[17px] md:leading-[22px] leading-[20px] font-semibold '>{obj?.point} </p>
                        </div>
                    )
                })}
                <div className='viewMe mt-[52px]'>
                    Let’s get started
                </div>
            </div>
            <div className='w-[100%] bg-[#FFE100] h-[120px] md:h-[170px]'>
                <div className='md:px-[187px] mx-[auto] max-w-[1280px] bg-[#FFE100] mt-[120px]  justify-center w-[100%] flex items-end h-[170px] py-[31px]' >
                    <p className='max-w-[710px] text-[#000000] text-center text-[21px] md:text-[28px] font-semibold md:leading-[32px] leading-[24px] clashFont mx-[auto]'>You can expect 100k participations with the helps and advises from goviral team</p>
                </div>
            </div>
        </div>
    )
}

export default HighlightAndExperince