import React, { useEffect, useState } from "react";
import "./Collabrator.css";
import Linkdin from "../../assests/LinkdinBlue.png";
import WeBlue from "../../assests/WebBlue.png";
import Insta from "../../assests/InstaBlue.png";
import Navbar from "../HomeOfficial/Navbar";
import CollabratorCard from "./CollabratorCard";
import Footer from "../HomeForNew2.0/Footer";
import { useLocation, useNavigate, useParams } from "react-router";
import axios from "axios";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import CampaignCard from "../InfluencerAndSponser/CampaignCard";
import { isEmpty } from "lodash";
import BackHeader from "../BackHeader/BackHeader";

function Collabrator() {
  let { orgUniqueText } = useParams();
  const [brandList, setBrandList] = useState([]);
  const [promotionList, setPromnotionList] = useState([]);
  const [orgDetials, setOrgDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [generateLinkLoading, setGenerateLinkLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    getPromotionList();
  }, []);
  const getPromotionList = async () => {
    setLoading(true);
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_GET_BRANDS_PROMOTIONS}/${orgUniqueText}`
      );
      setPromnotionList(data?.data?.data?.promotions);
      setOrgDetails(data?.data?.data?.orgDetails);
      getbrandList(data?.data?.data?.orgDetails?._id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  const getbrandList = async (orgId) => {
    setCampaignLoading(true);
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_GET_BRANDS_CAMPAIGNS}?type=ORG&orgId=${orgId}`
      );
      setBrandList(data?.data?.data);
      setCampaignLoading(false);
    } catch (error) {
      setCampaignLoading(false);
      console.log(error.message);
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/brands");
    }
  };
  return (
    <div className="bg-white w-[100%]">
      <BackHeader handleBack={handleNavigateToPrevLocation} heading="" />
      {/* <Navbar /> */}
      {loading && (
        <div className="w-[100%] h-[400px] flex justify-center items-center bg-white">
          <div className="w-[30px] h-[30px]">
            <LoadingCircle />
          </div>
        </div>
      )}
      <div className="max-w-[930px] px-[20px] md:px-[0] mx-[auto] pt-[30px] ">
        {!loading && (
          <div>
            <div className="flex gap-[8px] md:gap-[64px] justify-start items-start">
              <div className="w-[52px] md:w-[122px] h-[52px] md:h-[122px] ">
                <img
                  className="w-[100%]  h-[100%] object-cover"
                  src={orgDetials?.logo}
                  alt=""
                />
              </div>
              <div>
                <p className="text-[black] font-semibold text-[18px] md:text-[28px] leading-[26px] md:leading-[39px]">
                  {orgDetials?.organizationName}
                </p>
                <div className="mt-[2px] md:mt-[12px] flex items-center justify-start gap-[10px]">
                  <a
                    className="text-[#5582C4] font-medium text-[10px] md:text-[10px] leading-[14px] md:leading-[14px]"
                    href={orgDetials?.contact?.website}
                  >
                    {orgDetials?.contact?.website}
                  </a>
                  <p className="text-[#8F8F8F] font-medium text-[10px] md:text-[10px] leading-[14px] md:leading-[14px]">
                    {orgDetials?.location}
                  </p>
                </div>
                <p className="mt-[22px] md:mt-[32px] text-[#000000] font-semibold text-[15px] md:text-[17px] leading-[20px] md:leading-[23px] max-w-[399px]">
                  {orgDetials?.tagLine}
                </p>
                <p
                  className="mt-[12px] md:mt-[16px] text-[#787878] font-normal text-[12px] md:text-[12px] leading-[18px] md:leading-[18px] max-w-[464px]"
                  onClick={() => setReadMore((prevState) => !prevState)}
                >
                  {readMore
                    ? orgDetials?.description
                    : orgDetials?.description?.slice(0, 120)}
                  {orgDetials?.description?.length > 120 && !readMore && "..."}
                  {orgDetials?.description?.length > 120 && (
                    <span className="text-[#0F6ABE] whitespace-nowrap">
                      {readMore ? " Read Less" : " Read More"}
                    </span>
                  )}
                </p>
                <div className="flex items-start justify-start gap-[10px] mt-[20px] md:mt-[24px] ">
                  {orgDetials?.contact?.website && (
                    <a href={orgDetials?.contact?.website} target="_blank">
                      <img
                        className="cursor-pointer object-cover w-[32px] h-[32px]"
                        src={WeBlue}
                        alt=""
                      />
                    </a>
                  )}
                  {orgDetials?.contact?.instagram && (
                    <a href={orgDetials?.contact?.instagram} target="_blank">
                      <img
                        className=" cursor-pointer object-cover w-[32px] h-[32px]"
                        src={Insta}
                        alt=""
                      />
                    </a>
                  )}
                  {orgDetials?.contact?.linkedIn && (
                    <a href={orgDetials?.contact?.linkedIn} target="_blank">
                      <img
                        className="cursor-pointer object-cover w-[32px] h-[32px]"
                        src={Linkdin}
                        alt=""
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {campaignLoading ? (
          <div className="w-[100%] h-[400px] flex justify-center items-center bg-white">
            <div className="w-[30px] h-[30px]">
              <LoadingCircle />
            </div>
          </div>
        ) : (
          <>
            {!isEmpty(brandList) && (
              <>
                <p className="clashFont mb-[10px] mt-[20px] md:mt-[70px] text-[black] font-semibold text-[21px] md:text-[28px] leading-[24px] md:leading-[39px]">
                  Campaigns
                </p>
                <div className="">
                  {isEmpty(brandList) ? (
                    <div className="flex w-full p-[30px] items-center justify-center">
                      <p>No Campaigns</p>
                    </div>
                  ) : (
                    <>
                      {brandList?.map((obj, index) => {
                        return (
                          <CampaignCard
                            data={obj}
                            generateLinkLoading={generateLinkLoading}
                            setGenerateLinkLoading={setGenerateLinkLoading}
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </>
            )}
            {!isEmpty(promotionList) && (
              <>
                <p className="clashFont mb-[10px] mt-[20px] md:mt-[70px] text-[black] font-semibold text-[21px] md:text-[28px] leading-[24px] md:leading-[39px]">
                  Promotions
                </p>
                <div className="">
                  {isEmpty(promotionList) ? (
                    <div className="flex w-full p-[30px] items-center justify-center">
                      <p>No Promotions</p>
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] w-full md:mt-[38px] mx-auto">
                      {promotionList?.map((obj) => {
                        return (
                          <CollabratorCard
                            data={obj}
                            orgDetails={orgDetials}
                            generateLinkLoading={generateLinkLoading}
                            setGenerateLinkLoading={setGenerateLinkLoading}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Collabrator;
