import React from "react";
import { Link } from "react-router-dom";
import styles from "./ProductCard.module.css";

export default function ProductCard({ data, order }) {
  return (
    <div className="w-full h-full py-[30px] lg:py-[60px] grid lg:grid-cols-2 lg:gap-[60px] gap-[30px]">
      <div
        className={`w-full h-auto lg:h-[440px] ${
          order == "reverse" ? "lg:order-2" : "lg:order-1"
        }`}
      >
        {data?.image && (
          <img
            src={data?.image}
            alt=""
            className="w-full lg:w-fit mx-auto h-full rounded-[12px] object-contain "
          />
        )}
      </div>
      <div
        className={`${styles.productCard} ${
          order == "reverse" ? "lg:order-1" : "lg:order-2"
        }`}
      >
        <h2 className="font_medium_18 text-[#D23232] ">{data?.heading}</h2>
        <p className="font_normal_16 text-[#000000] mt-[20px]">
          {data?.description}
        </p>

        <div className="flex flex-col gap-[24px] lg:gap-[40px] mt-[30px] lg:mt-[38px]">
          {data?.points?.map((el) => (
            <div className="flex gap-[12px] items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                className="shrink-0"
              >
                <path
                  d="M19 1.5L17.74 4.25L15 5.5L17.74 6.76L19 9.5L20.25 6.76L23 5.5L20.25 4.25M9 4.5L6.5 10L1 12.5L6.5 15L9 20.5L11.5 15L17 12.5L11.5 10M19 15.5L17.74 18.24L15 19.5L17.74 20.75L19 23.5L20.25 20.75L23 19.5L20.25 18.24"
                  fill="#161616"
                />
              </svg>

              <p className={styles.pointText}>{el}</p>
            </div>
          ))}
        </div>
        {/* <div className="flex gap-[24px] mt-[30px] lg:mt-[60px]">
          <button className={styles.button1}>View Sample</button>
          <button className={styles.button2}>I’m intrested</button>
        </div> */}
      </div>
    </div>
  );
}
