import { useRef, useEffect, useState, useContext } from "react";
import "./influencerSponser.css";
import NavBar from "../HomeOfficial/Navbar";
import NumberOfInfkuencer from "./NumberOfInfkuencer";
import CardOfInfluencer from "./CardOfInfluencer";
import Footer from "../HomeForNew2.0/Footer";
import axios from "axios";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
function InfluencerCard() {
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);

 
  useEffect(() => {
    getbrandList();
  }, []);
  const getbrandList = async () => {
    setLoading(true);
    try {
      const data = await axios.get(`${process.env.REACT_APP_GET_BRANDS_LIST}`);
      setBrandList(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  return (
    <div className="bg-white w-[100%]">
      {/* <NavBar /> */}
      <div className="max-w-[1280px] mx-[auto] pt-[90px]">
        <div className=" md:mx-[auto] px-[20px] md:px-[0] max-w-max md:max-w-[573px]">
          <p className="clashFont  collabTxt">
            Why Influencers should Collaborate with us?
          </p>
          <p className="mb-[15px] mt-[10px] text-[#787878] font-normal text-[14px] md:text-[17px] leading-[20px] md:leading-[22px]">
            Influencers can provide brand experiences on various products and
            advise their followers to get the most benefits out of it.
          </p>
          <div class="scroller">
            <span className="clashFont mt-[20px] text-[#4E04C6] font-semibold text-[17px] md:text-[24px] leading-[20px] md:leading-[30px]">
              Increased Exposure
              <br />
              Art
              <br />
              Intruiging
              <br />
              Challenging
            </span>
          </div>

          <p className="mt-[20px] max-w-[513px] text-[#000000] font-medium text-[14px] md:text-[17px] leading-[20px] md:leading-[22px]">
            Let us assist you in discovering the most exceptional advisors to
            elevate your product to new heights.
          </p>
          <div className="talkToUs mt-[26px]">Talk to Us</div>
        </div>
      </div>
      <NumberOfInfkuencer />
      <div className="max-w-[1280px] mx-[auto] pt-[60px]  px-[20px] md:px-[0]">
        <p className=" text-[#000000] font-semibold text-[14px] md:text-[21px] leading-[20px] md:leading-[24px]">
          Brands looking for collaborate
        </p>
        <div className="flex justify-start flex-wrap items-center gap-[10px] mt-[20px] mb-[20px]">
          {/* <div className="fliterChip" style={{ color: "#035BDE" }}>
            All
          </div> */}
        </div>
        {loading && (
            <div className="w-[100%] h-[30px] flex justify-center my-[20px] bg-white">
              <div className="w-[30px] h-[100%]">
                <LoadingCircle />
              </div>
            </div>
          )}
        <div className="">
          {brandList?.map((obj, index) => {
           return <CardOfInfluencer data={obj} />;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default InfluencerCard;
