import React, { useState } from "react";
import quoteBanner from "../../assests/quoteBanner.jpg";
import styles from "./QuotePage.module.css";
export default function QuotePage() {
  const [type, setType] = useState("BRAND");
  const [step, setStep] = useState("ONE");
  const [selectedData, setSelectedData] = useState({});
  const data = {
    BRAND: {
      ONE: [
        {
          id: "Q1",
          question:
            "Which one of the below advise would you like to get from us?",
          options: [
            "We are not able to get the expected sales",
            "We want to start marketing of product",
            "Other",
          ],
        },
      ],
      TWO: [
        {
          id: "Q2",
          question: "How long is your product in market",
          options: [
            "less than 1 year",
            "less than 5 year",
            "less than 10 year",
            "less than 15 year",
            "More than 15 year",
          ],
        },
        {
          id: "Q3",
          question: "How much reach are you wanted on your social or brand?",
          options: ["0 - 50K", "50 - 100K", "above - 100K"],
        },
        {
          id: "Q4",
          question:
            "Do you need influencers to be associated from our side with a changed covered for brand",
          options: ["Yes", "NO"],
        },
      ],
    },
  };
  const solution = [
    {
      solution: "Create an engaging campaigns or  contests",
      description: "it can make a some engagements and entertainment ",
    },
    {
      solution: "Enhance the reach limit with influencers ",
      description: "We’ve the database of 200K+ influencers collection",
    },
    {
      solution:
        "We have the best tools to promote your products and it can bring more leads",
      description: "100000+ ways to promote",
    },
    {
      solution:
        "Convert your leads to customers by giving more offers or exciting gifts",
      description: "100000+ ways to promote",
    },
  ];
  const handleOptionSelect = (e) => {
    const id = e.target.id;
    const name = e.target.name;
    const value = e.target.value;
    setSelectedData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleNext = () => {
    if (step == "ONE") {
      setStep("TWO");
    } else if (step == "TWO") {
      setStep("THREE");
    }
  };
  console.log("selectedData", selectedData);

  return (
    <div className="bg-[#f0fbff]">
      <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto flex gap-[5%]">
        <div className="hidden sm:block">
          <img src={quoteBanner} />
        </div>
        <div className={styles.quote}>
          <h1>Find your Quote</h1>
          <div className={styles.switch}>
            <p>Are you a brand or Event </p>
            <div className="flex items-center gap-[10px]">
              <button
                className={`${
                  type == "BRAND" ? styles.selected : styles.notSelected
                }`}
                onClick={() => setType("BRAND")}
              >
                Brand
              </button>
              <button
                className={`${
                  type == "EVENT" ? styles.selected : styles.notSelected
                }`}
                onClick={() => setType("EVENT")}
              >
                Event
              </button>
            </div>
          </div>
          <div className="flex items-center my-[40px]">
            <div
              className="bg-[#6F49AF] rounded-full w-[16px] h-[16px] shrink-0 mx-[0.9%]"
              style={{
                opacity:
                  step == "ONE" || step == "TWO" || step == "THREE" ? 1 : 0.4,
              }}
              onClick={() => setStep("ONE")}
            />
            <div className="w-full bg-[#F3F0FF] rounded-[21px] h-[3px]" />
            <div
              className="bg-[#6F49AF] rounded-full w-[16px] h-[16px] shrink-0 mx-[0.9%]"
              style={{ opacity: step == "TWO" || step == "THREE" ? 1 : 0.4 }}
              onClick={() => setStep("TWO")}
            />
            <div className="w-full bg-[#F3F0FF] rounded-[21px] h-[3px]" />
            <div
              className="bg-[#6F49AF] rounded-full w-[16px] h-[16px] shrink-0 mx-[0.9%]"
              style={{ opacity: step == "THREE" ? 1 : 0.4 }}
              onClick={() => setStep("THREE")}
            />
          </div>
          <div className={styles.question}>
            {data &&
              data[type] &&
              data[type][step] &&
              data[type][step]?.map((obj) => (
                <div className="mb-[44px]">
                  <h3>{obj?.question}</h3>
                  <div className="flex gap-[20px] flex-wrap mt-[20px]">
                    {obj?.options?.map((el) => (
                      <div className={styles.option}>
                        <input
                          type="radio"
                          id={el}
                          name={obj?.id}
                          value={el}
                          onChange={handleOptionSelect}
                        />
                        <label htmlFor={el}>{el}</label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            {step !== "THREE" && (
              <div>
                <div className="bg-[#F3F0FF] w-full h-[3px] mb-[20px]" />
                <button onClick={handleNext}>
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3.0001 11.9999C3.0001 12.3978 3.15813 12.7793 3.43944 13.0606C3.72075 13.3419 4.10227 13.4999 4.5001 13.4999L16.3801 13.4999L12.4401 17.4399C12.1751 17.7243 12.0309 18.1004 12.0377 18.489C12.0446 18.8776 12.202 19.2484 12.4769 19.5232C12.7517 19.798 13.1225 19.9554 13.5111 19.9623C13.8997 19.9691 14.2757 19.8249 14.5601 19.5599L21.0601 13.0599C21.341 12.7787 21.4988 12.3974 21.4988 11.9999C21.4988 11.6024 21.341 11.2212 21.0601 10.9399L14.5601 4.43994C14.4228 4.29257 14.2572 4.17436 14.0732 4.09238C13.8892 4.0104 13.6905 3.96631 13.4891 3.96276C13.2877 3.9592 13.0877 3.99625 12.9009 4.0717C12.7141 4.14714 12.5445 4.25943 12.402 4.40186C12.2596 4.5443 12.1473 4.71397 12.0719 4.90075C11.9964 5.08752 11.9594 5.28758 11.9629 5.48899C11.9665 5.69039 12.0106 5.88902 12.0925 6.07302C12.1745 6.25702 12.2927 6.42262 12.4401 6.55994L16.3801 10.4999L4.5001 10.4999C3.6721 10.4999 3.0001 11.1719 3.0001 11.9999Z"
                      fill="#F6F6F6"
                    />
                  </svg>
                </button>
              </div>
            )}
            {step == "THREE" && (
              <>
                <div className={styles.solution}>
                  <h3 className="mb-[17px]">Solutions for “”</h3>
                  <p className="mb-[21px]">
                    We understand the situations your brand or event going
                    through. from your response we can help your brand or events
                    to gain more attention and leads
                  </p>
                  {solution?.map((obj, index) => (
                    <div className="flex gap-[20px] mb-[33px]">
                      <p className={styles.numbers}>{index + 1}</p>
                      <div>
                        <h4 className="mb-[12px]">{obj?.solution}</h4>
                        <p>{obj?.description}</p>
                      </div>
                    </div>
                  ))}
                  <h4 className="mb-[12px]">
                    Or let’s discuss with the way you wanted to bring more leads
                  </h4>
                  <button>Request a call</button>
                  <div className="bg-[#F3F0FF] w-full h-[3px] mb-[20px] mt-[60px] mb-[24px]" />
                </div>
                <div className="flex flex-col sm:flex-row gap-[12px] sm:gap-[50px] px-[30px]">
                  <p>
                    We understand the situations your brand or event going
                    through. Check the approx. price for this campaigns
                  </p>
                  <button className="mx-auto w-fit">
                    I understand{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3.0001 11.9999C3.0001 12.3978 3.15813 12.7793 3.43944 13.0606C3.72075 13.3419 4.10227 13.4999 4.5001 13.4999L16.3801 13.4999L12.4401 17.4399C12.1751 17.7243 12.0309 18.1004 12.0377 18.489C12.0446 18.8776 12.202 19.2484 12.4769 19.5232C12.7517 19.798 13.1225 19.9554 13.5111 19.9623C13.8997 19.9691 14.2757 19.8249 14.5601 19.5599L21.0601 13.0599C21.341 12.7787 21.4988 12.3974 21.4988 11.9999C21.4988 11.6024 21.341 11.2212 21.0601 10.9399L14.5601 4.43994C14.4228 4.29257 14.2572 4.17436 14.0732 4.09238C13.8892 4.0104 13.6905 3.96631 13.4891 3.96276C13.2877 3.9592 13.0877 3.99625 12.9009 4.0717C12.7141 4.14714 12.5445 4.25943 12.402 4.40186C12.2596 4.5443 12.1473 4.71397 12.0719 4.90075C11.9964 5.08752 11.9594 5.28758 11.9629 5.48899C11.9665 5.69039 12.0106 5.88902 12.0925 6.07302C12.1745 6.25702 12.2927 6.42262 12.4401 6.55994L16.3801 10.4999L4.5001 10.4999C3.6721 10.4999 3.0001 11.1719 3.0001 11.9999Z"
                        fill="#F6F6F6"
                      />
                    </svg>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
