import { useRef, useEffect, useState, useContext } from "react";
import "./influencerSponser.css";
import Linkdin from "../../assests/LinkdinBlue.png";
import WeBlue from "../../assests/WebBlue.png";
import Insta from "../../assests/InstaBlue.png";
import { Link } from "react-router-dom";

function CardOfInfluencer({ data }) {
  return (
    <Link to={`/collab/${data?.orgUniqueText}`}>
      <div className="w-[100%] min-h-[184px] p-[10px] mb-[10px] border-[1px] border-[#e6e6e6] cardOfinf relative">
        <div className="flex justify-start items-start ">
          <img
            className="hidden md:block md:h-[162px] md:w-[162px] object-cover"
            src={data?.logo}
            alt=""
          />
          <div className="flex items-start justify-between w-[100%]">
            <div className="md:ml-[12px]">
              <div className="flex justify-start items-start gap-[10px]">
                <img
                  className="w-[44px] h-[44px] block md:hidden object-cover"
                  src={data?.logo}
                  alt=""
                />
                <p className="text-[#000000] font-medium text-[16px] md:text-[16px] leading-[20px] md:leading-[24px]">
                  {data?.organizationName}
                </p>
              </div>
              <div className="flex items-center justify-start gap-[10px]">
                {data?.contact?.website && (
                  <a
                    className="text-[#5582C4] font-medium text-[10px] md:text-[10px] leading-[14px] md:leading-[14px]"
                    href={data?.contact?.website}
                  >
                    {data?.contact?.website}
                  </a>
                )}
                <p className="text-[#8F8F8F] font-medium text-[10px] md:text-[10px] leading-[14px] md:leading-[14px]">
                  {data?.location}
                </p>
              </div>
              <p className="mt-[12px] text-[#000000] font-semibold text-[16px] md:text-[17px] leading-[20px] md:leading-[23px] max-w-[399px]">
                {data?.tagLine}
              </p>
              <p className="mt-[5px] text-[#787878] font-normal text-[12px] md:text-[12px] leading-[18px] md:leading-[18px] max-w-[464px] text_Ell">
                {data?.description}
              </p>
            </div>
            <div className="md:flex items-start justify-center gap-[10px] hidden ">
              {data?.contact?.website && (
                <a href={data?.contact?.website} target="_blank">
                  <img
                    className="cursor-pointer object-cover w-[32px] h-[32px]"
                    src={WeBlue}
                    alt=""
                  />
                </a>
              )}
              {data?.contact?.instagram && (
                <a href={data?.contact?.instagram} target="_blank">
                  <img
                    className=" cursor-pointer object-cover w-[32px] h-[32px]"
                    src={Insta}
                    alt=""
                  />
                </a>
              )}
              {data?.contact?.linkedIn && (
                <a href={data?.contact?.linkedIn} target="_blank">
                  <img
                    className="cursor-pointer object-cover w-[32px] h-[32px]"
                    src={Linkdin}
                    alt=""
                  />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between h-[47px]">
          <p className=" block text-[#000000] font-semibold  text-[12px] leading-[14px] "></p>
          <div className="collabrator flex ">View</div>
        </div>
      </div>
    </Link>
  );
}

export default CardOfInfluencer;
