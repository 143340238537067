import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "./styles/ShowcaseSlider.module.css";
import TitleDescription from "./TitleDescription";
import Quotes from '../../Json/Quotes.json'

function ShowCaseSilder({ top10Url, title }) {
  const navigate = useNavigate();
  const [top10Showcase, setTop10Showcase] = useState("");
  const top10Fuction = async (campaign) => {
    try {
      const setApi = await axios.get(top10Url);
      setTop10Showcase(setApi.data);
    } catch (err) {
      console.log(err.message);
      setTop10Showcase("");
    }
  };
  useEffect(() => {
    top10Fuction();
  }, []);
  console.log(top10Showcase);
  const imgUrlBase = 'https://s3.ap-south-1.amazonaws.com/goviral.world/home'
  return (
    <div id="virals">
      <div className="homeWidthController leftPadding rytPadding">
        {/* <TitleDescription
          title={"Talent’s gone Viral"}
          Decription={
            " Meet the winners who participated in various contest,campaigns and showcased their talent to the world of opportunity and GoViral"
          }
        /> */}
           <div className="pb-[60px] md:pb-[130px]">
          <h1>Talent’s gone Viral</h1>
          <h2>Meet the winners who participated in various contest,campaigns and showcased their talent to the world of opportunity and GoViral</h2>
        </div>
      </div>
      <div className={styles.theSliderGrid}>
        {top10Showcase &&
          top10Showcase?.map((obj) => {
            return (
              <div>
                <div className={styles.theSliderEach} >
                  <img
                    src={imgUrlBase + obj?.thumbnailUrl}
                    alt=""
                  />
                  <p className={styles.campaign}>{obj?.campaignName}</p>
                  <p className={styles.decript}>{obj?.participantName}</p>
                </div>
              </div>
            );
          })}
        {/* {Quotes && Quotes?.quotes?.map((obj) => {
          return (
            <div className={styles.theSliderEach}>
              <div className={styles.theSliderEachTemplate} style={{ background: obj?.background }} >
                <p className={styles.quotes}>{obj?.quotes}</p>
              </div>
              <p className={styles.campaign}>{obj?.heading}</p>
              <p className={styles.decript}>{obj?.subtitle}</p>
            </div>

          )
        })} */}

      </div>

      {/* <div
        class="uk-position-relative homeWidthController leftPadding uk-visible-toggle uk-light"
        tabindex="-1"
        uk-slider="autoplay: true"
      >
        <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
          {top10Showcase &&
            top10Showcase?.map((obj) => {
              return (
                <li style={{ width: "calc(100% * 1 / 3)" }}>
                  <div className={`uk-panel ${styles.singleShow}`}>
                    <img
                      className={styles.showIMg}
                      src={imgBseUrl + obj?.thumbnailUrl}
                      alt=""
                    />
                    <div className="tint">
                    </div>
                    <div className={styles.absol}>
                      <p className={styles.contest}>{obj?.campaignName}</p>
                      <p className={styles.contestNmae}>
                        {obj?.participantName}
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>

        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-previous
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-next
          uk-slider-item="next"
        ></a>
      </div> */}
    </div>


  );
}

export default ShowCaseSilder;
