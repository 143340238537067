import React, { useEffect, useState } from "react";
import PriceBox from "../Components/PriceBox/PriceBox";
import axios from "axios";
import styles from "./styles/PricingPage.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackHeader from "../Components/BackHeader/BackHeader";

export default function PricingPage() {
  const [priceDetails, setPriceDetails] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadPrice();
  }, []);

  const loadPrice = async () => {
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/homeMedia/homeDatas/packDetails.json`
      );
      setPriceDetails(data?.data?.pack);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <div className="bg-white w-[100%] min-h-[60vh]">
      <BackHeader handleBack={handleNavigateToPrevLocation} heading="Pricing" />
      <div className="max-w-[1280px] mx-[auto] py-[30px] px-[20px] md:px-[80px]">
        <p class="text-large-clash text-[#161616] clashFont">
          We’ve got a pricing plan that's perfect for you.
        </p>
        <div className="mt-[20px] flex gap-[10px] flex-wrap overflow-auto">
          {priceDetails?.map((obj) => (
            <PriceBox data={obj} />
          ))}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div className="max-w-[1280px] w-full mx-[auto] flex justify-end ">
          <Link to="/campaigns">
            <button className={styles.button}>Create Campaign</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
