import React from 'react'
import styles from '../NavBar/NavBar.module.css'
import Logo from '../../assests/goViralWhiteLogo.svg'
import { useNavigate } from 'react-router'

function NavBar() {
  const navigate = useNavigate()
  return (
    <div className={styles.NavBar}>

      <div className='max-w-[1280px]  h-[40px] mx-auto mt-[20px] mb-[20px]  '>

        <img className={styles.loog} src={Logo} alt="" onClick={() => navigate("/")} />

      </div>
    </div>
  )
}

export default NavBar