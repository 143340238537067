import React, { createContext, useEffect, useState } from "react";
import styles from "./styles/OrganizerPage.module.css";
import axios from "axios";
import { isEmpty } from "lodash";
import minus from "../../assests/Cartminus.svg";
import plus from "../../assests/Cartadd.svg";

const CampaignTemplate = ({
  Campignkey,
  dataOfCatergory,
  setDataOfCatergory,
  handleAddtoMyCampaign,
  handleRemoveOtherContest,
  cart,
  cartMap,
}) => {
  const categoryTemplateFuction = async (Campignkey) => {
    try {
      const setdata = await axios.get(`${Campignkey?.url}`);

      setDataOfCatergory(setdata?.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (!isEmpty(Campignkey)) {
      categoryTemplateFuction(Campignkey);
    }
  }, [Campignkey]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[30px] hideScrollBar mt-[24px] md:mt-[30px] text-black px-[20px] md:px-[15%]">
        {dataOfCatergory?.contests?.map((obj, index) => {
          return (
            <div className={styles.campaignCard} key={index}>
              <div className={styles.fileType}>
                {obj?.contestType?.startsWith("QUIZ") ? (
                  <>
                    {obj?.contestType == "QUIZ_AW" ? "Awareness Quiz" : "Quiz"}{" "}
                    Contest
                  </>
                ) : (
                  <>{obj?.isPosterContest ? "Poster" : obj?.fileType} Contest</>
                )}
              </div>

              <img
                src={obj?.contestPoster}
                alt=""
                className="h-[275px] w-[100%] object-cover rounded-t-[16px]"
              />
              <div className="h-auto w-[100%] p-[12px] flex flex-col gap-[10px]">
                <p className="text-large-clash text_1Line">
                  {obj?.contestName}
                </p>
                <p className="text_Ell text-smallest">{obj?.description}</p>
                <div className="flex flex-wrap gap-[10px] justify-between items-center">
                  <div className="flex gap-[5px] items-center">
                    <img
                      onClick={() => handleRemoveOtherContest(obj)}
                      src={minus}
                      className={styles.plusOrMinusButton}
                    />
                    <p className="border-[1px] border-[#E8E8E8] py-[5px] px-[12px] text-regular-medium">
                      {cartMap.get(obj?._id)?.count
                        ? cartMap?.get(obj?._id)?.count
                        : 0}
                    </p>
                    <img
                      onClick={() => handleAddtoMyCampaign(obj)}
                      src={plus}
                      className={styles.plusOrMinusButton}
                    />
                  </div>

                  <button
                    onClick={() => handleAddtoMyCampaign(obj)}
                    className="px-[16px] py-[6px] text-[#4E04C6] border-[#4E04C6] border-[1px] rounded-[54px] text-small cursor-pointer md:hover:bg-[#4E04C6] md:hover:text-[#ffffff] transition-colors duration-300 ease-in"
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CampaignTemplate;
