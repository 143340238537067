import styles from "./styles/Navbar.module.css";
import Offcanvas from "../../assests/Offcanvas.svg";
import Plus from "../../assests/plus.svg";
import Logo from "../../assests/logotwo.png";
import Close from "../../assests/Union.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import "../../App.css";
import BlankUser from "../../assests/blackUser.png";
import Context from "../../context/Context";
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import LoggedUser from "../HomeForNew2.0/LoggedUser";
function Navbar() {
  const { currentUser, setPreviousLocation } = useUserAuth();
  const { isHomePage, calendlyLink } = useContext(Context);
  const [open, setOpen] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 80;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  return (
    <div className={styles.fluidNav}>
      <div className={styles.newNav}>
        <div className={styles.fullLine}>
          {/* <p
            className={styles.textToFolow}
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
          >
            Goviral
          </p> */}
          <img
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            className="w-[134px]"
            src={Logo}
            alt=""
          />
        </div>
        <div className="flex relative justify-evenly gap-[40px] items-center ">
          {/* <p className="hidden md:block cursor-pointer inline growtext text-[black] font-medium md:text-[15px] md:leading-[18px]  leading-[16px]">
            <span className="inline text-[#787878] font-semibold">
              Join as -{" "}
            </span>
            Are you a brand? or Influencer?
          </p>

          <p className="hidden md:block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
            Contest
          </p>
          <p className="hidden md:block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
            Contest
          </p>
          <p className="hidden md:block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
            Showcase
          </p>*/}
          {/* <button className="hidden md:block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
            <Link to="/collab/campaigns">Campaigns</Link>
          </button>
          <button className="hidden md:block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
            <Link to="/collab/sponser">Partner With Us</Link>
          </button> */}
          {/* <button className="hidden md:block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
            <Link
              to="/pricing"
              state={{ previousLocation: location?.pathname }}
            >
              Pricing
            </Link>
          </button> */}
          {/* <button className="hidden md:block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
            <Link
              to="/collab/sponser"
              state={{ previousLocation: location?.pathname }}
            >
              Join as sponsor
            </Link>
          </button> */}

          {/* <button className="hidden md:block cursor-pointer text-[#4E04C6] font-medium growtext md:leading-[21px]  leading-[16px]">
            <Link
              to="/campaigns"
              state={{ previousLocation: location?.pathname }}
            >
              Campaign Ideas
            </Link>
          </button> */}
          <button className="hidden md:block cursor-pointer text-[#000] font-medium growtext md:leading-[21px]  leading-[16px]">
            <Link
              to="/festival-campaigns"
              state={{ previousLocation: location?.pathname }}
            >
              Festivity-Campaigns
            </Link>
          </button>
          <button className="hidden md:block cursor-pointer text-[#000] font-medium growtext md:leading-[21px]  leading-[16px]">
            <Link
              to="/collaborations"
              state={{ previousLocation: location?.pathname }}
            >
              For-Sponsors
            </Link>
          </button>
          <button className="hidden md:block cursor-pointer text-[#000] font-medium growtext md:leading-[21px]  leading-[16px]">
            <Link
              to="/collab/influencer"
              state={{ previousLocation: location?.pathname }}
            >
              Collaborate
            </Link>
          </button>
          <button className="hidden md:block cursor-pointer text-white font-medium growtext md:leading-[21px] leading-[16px] bg-[#161616] rounded-[8px] px-[32px] py-[12px] ">
            <a href={calendlyLink} target="_blank">
              Book a Demo
            </a>
          </button>
          {console.log("open", open)}
          <div
            onClick={() => setOpen((prevState) => !prevState)}
            className={styles.userButtun}
          >
            <img
              className="w-[30px] object-cover h-[30px] rounded-full"
              src={currentUser === null ? BlankUser : currentUser?.photoURL}
              alt=""
            />
            <img src={Offcanvas} alt="" />
          </div>
          {open && <LoggedUser setOpen={setOpen} open={open} />}
        </div>
        {/* <img
          class={`${styles.menuplace} yesToTab yesToMobile noToTab`}
          type="button"
          uk-toggle="target: #offcanvas-flip"
          src={Offcanvas}
          alt=""
        /> */}
      </div>
      <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
        <div class="uk-offcanvas-bar">
          <div className="flex  justify-between items-center ">
            <div className={styles.fullLine}>
              {/* <p
                className={styles.textToFolow}
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
              >
                Goviral
              </p> */}
              {/* <img className="w-[134px]" src={Logo} alt="" /> */}
              <p
                onClick={() =>
                  navigate("/campaigns", {
                    state: { previousLocation: location?.pathname },
                  })
                }
                className="md:hidden block cursor-pointer text-[#4E04C6] font-medium growtext md:leading-[21px]  leading-[16px]"
              >
                Campaign Ideas
              </p>
            </div>

            <button
              class="uk-offcanvas-close"
              style={{ position: "relative" }}
              type="button"
              uk-close="true"
            >
              {" "}
              <img src={Close} alt="" />
            </button>
          </div>
          <div className=" flex flex-col justify-center items-start gap-[24px]">
            {/* <p className="md:hidden block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
              Contest
            </p>
            <p className="md:hidden block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
              Contest
            </p>
            <p className="md:hidden block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
              Showcase
            </p>
            <p className="md:hidden block cursor-pointer text-[black] font-medium growtext md:leading-[21px]  leading-[16px]">
              Collabration
            </p> */}
            {/* <p
              onClick={() => navigate("/campaigns")}
              className="md:hidden block cursor-pointer text-[#4E04C6] font-medium growtext md:leading-[21px]  leading-[16px]"
            >
              Create a Campaign
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
