import React, { useState, useEffect, useContext } from "react";
import styles from "./styles/InfluencerListing.module.css";
import axios from "axios";
import { useNavigate } from "react-router";

function InfulencerListing() {
  const [bioListing, setBioListing] = useState();
  const influAWSurl =
    "https://s3.ap-south-1.amazonaws.com/goviral.world/influencer";
  const bioFuction = async () => {
    try {
      const setApi = await axios.get(
        `https://s3.ap-south-1.amazonaws.com/goviral.world/influencer/data.json`
      );
      setBioListing(setApi.data);
    } catch (err) {
      console.log(err.message);
      setBioListing("");
    }
  };
  useEffect(() => {
    bioFuction();
  }, []);
  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      <div className="m-[60px] flex gap-x-1">
        {bioListing?.influencer?.map((obj, i) => {
          return (
            <div onClick={()=>navigate(`/${obj?.Urlname}`)}>
              <img className={styles.widthImg} src={obj?.image} alt="" />
              <p className={styles.name}>{obj?.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InfulencerListing;
