import React, { createContext, useEffect, useState } from "react";
import NavBar from "../HomeOfficial/Navbar";
import { Link, useParams } from "react-router-dom";
import Sections from "./Sections";
import Leaves from "../../assests/rightSideLeves.png";
import "./HomePageOne.css";
import CosmeticsWinner from "./CosmeticsWinner";
import axios from "axios";
import InfluencerListingSlider from "../InfluencerAndSponser/InfluencerListingSlider";
import HighlightAndExperince from "./HighlightAndExperince";
import Footer from "./Footer";
import { isEmpty } from "lodash";
function Costmetics() {
  let { domain } = useParams();
  const [type, setType] = useState();
  const [filterData, setFilterData] = useState([]);
  const [dataFromFilter, setDataFromFilter] = useState({});
  useEffect(() => {
    let dataFromFilter = filterData?.find((el) => el?.urlKey == domain);
    setDataFromFilter(dataFromFilter);
  }, [filterData]);
  const typeFuction = async (data) => {
    if (data && data.url) {
      const { url } = data;
      try {
        const setApi = await axios.get(`${url}`);
        setType(setApi.data);
      } catch (err) {
        console.log(err.message);
        setType("");
      }
    } else {
      console.error("Invalid data object:", data);
    }
  };
  useEffect(() => {
    if (!isEmpty(dataFromFilter)) {
      typeFuction(dataFromFilter);
    }
  }, [dataFromFilter, domain]);

  useEffect(() => {
    typeFuction();
  }, []);
  useEffect(() => {
    loadFilterData();
  }, []);
  const loadFilterData = async () => {
    try {
      const setdata = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/homeMedia/filterChips/filterChips.json`
      );
      setFilterData(setdata?.data?.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const [data, setData] = useState(null);
  // useEffect(() => {
  //   const fetchData = async (params) => {
  //     const result = await axios.get(
  //       `https://transform.dabler.app/api/test/getdbobjects?datawarehouse=${params}`
  //     );
  //     setData(result.data);
  //   };
  //   fetchData("SNOWFLAKE-DEV");
  // }, []);





  return (
    <div className="bg-[#FAECE4]  w-[100%]">
      {/* <NavBar />
      <Sections filterData={filterData} /> */}
      {/* <div className="max-w-[1280px] mx-[auto] pt-[70px]">
        <div className="w[100%] h-[390px] md:h-[547px] pt-[65px] relative">
          <img
            className="w-[100%] h-[100%] object-cover"
            src={type?.coverPicture}
            alt=""
          />
          <div className="theGrtAbsolute">
            <p className="text-[15px] font-semibold leading-[20px]">
              {type?.type}
            </p>
            <p className=" text-[21px] md:text-[32px] font-semibold leading-[24px md:leading-[39px] mt-[4px] clashFont">
              {type?.coverHeading}
            </p>
            <p className="text-[14px] md:text-[17px] font-semibold leading-[20px] md:leading-[24px] mt-[16px] md:mt-[20px]">
              {type?.coverSubtitle}
            </p>
          </div>
        </div>
      </div> */}
      <img className="hidden md:block absolute right-[0]" src={Leaves} alt="" />
      <div className="max-w-[1280px] mx-[auto] pt-[30px] md:pt-[70px]">
        {/* <CosmeticsWinner data={type?.campaignIdeas} /> */}
      </div>

      <div className="w-[100%] h-[217px] md:h-[170px] bg-[#FFE100]">
        <div className="px-[20px] md:px-[187px] mx-[auto] max-w-[1280px] bg-[#FFE100] mt-[55px] md:mt-[120px]  justify-cente w-[100%] flex-col md:flex-row flex items-end h-[170px] py-[31px]">
          <div className=" max-w-[649px]">
            <p className="max-w-[540px] text-[#000000] text-left text-[21px] md:text-[28px] font-semibold leading-[24px] md:leading-[32px] clashFont ">
              {type?.caseStudy?.title}
            </p>
            <p className="max-w-[649px] text-left  text-[#787878] text-[12px] md:text-[17px] font-noraml leading-[16px] md:leading-[24px] mt-[10px]">
              {type?.caseStudy?.subtitle}
            </p>
          </div>
          <Link to={`/case/${type?.caseStudy?.caseUniqueText}`}>
            <div className="viewMe">View Case Study</div>
          </Link>
        </div>
      </div>

      <div className="max-w-[1280px] mx-[auto] pt-[70px] px-[20px] md:px-[187px]">
        <p className="max-w-[540px] text-[#000000] text-left text-[28px] font-semibold leading-[32px] clashFont ">
          Goviral’s Influencers
        </p>
        <p className="max-w-[649px] text-left text-[#787878] text-[14px] md:text-[17px] font-noraml leading-[20px] md:leading-[24px] mt-[16px]">
          These influencers can use their large following on social media
          platforms like Instagram, YouTube, or TikTok to promote your product
          or service to their audience. they will take over the campaign to next
          level.
        </p>
        <InfluencerListingSlider data={type?.influencer?.influencers} />
        {/* <div className="flex items-center justify-start md:justify-start mt-[32px]">
          <div className="overMe">
            <img
              className="rounded-full w-[31px] h-[31px] object-cover"
              src="https://images.unsplash.com/photo-1491295079607-743739e45129?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
              alt=""
            />
          </div>
          <div className="rounded-full ml-[-10px] text-[#787878] font-medium text-[17px] leading-[22px] w-[31px] h-[31px] bg-[#414141] flex items-center justify-center">
            +6
          </div>
        </div> */}
        <p className="max-w-[608px] text-left text-[#787878] text-[14px] md:text-[17px] font-noraml leading-[20px] md:leading-[24px] mt-[16px]">
          The Influencers are frequently joining to Goviral community to
          collaborate with brand like yours, Goviral influencers community is
          growing day by day
        </p>
      </div>
      <HighlightAndExperince data={type} />
      <Footer />
    </div>
  );
}

export default Costmetics;
