import React, { useEffect, useState } from "react";
import styles from "./CampaignDetailsPage.module.css";
import axios from "axios";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import BackHeader from "../../Components/BackHeader/BackHeader";

export default function CampaignDetailsPage() {
  const [data, setdata] = useState({});
  const { type, campaign } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const setApi = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/campaignType/${type}/${campaign}/data.json`
      );
      setdata(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  var background = {
    backgroundImage: `url(${data?.background})`,
  };

  const formImageRender = (innerWidth) => {
    if (innerWidth <= 650) {
      return data?.contest?.mobileBanner;
    } else {
      return data?.contest?.desktopBanner;
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <div className="fixed top-[60px] sm:top-[84px] w-full">
        <BackHeader
          handleBack={handleNavigateToPrevLocation}
          heading=""
          background="transparent"
        />
      </div>
      <div className={styles.landing} style={background}>
        <div className="max-w-[1280px] h-full w-full px-[20px] lg:px-[78px] pt-[20px] pb-[40px] mx-auto flex flex-col items-center">
          <img src={data?.logo} />
          <h1 className="mb-[26px]">{data?.heading}</h1>
          <p className="opacity-60 w-full md:w-[50%]">{data?.description}</p>
        </div>
      </div>
      <div className={styles.contestContainter}>
        <div className="max-w-[1280px] h-full w-full px-[20px] lg:px-[78px] py-[30px] mx-auto flex flex-col items-center">
          <h2 className="mb-[26px]">{data?.contest?.heading}</h2>
          <a href={data?.contest?.url} target="_blank">
            <img
              src={formImageRender(window.innerWidth)}
              className="mb-[30px]"
            />
          </a>
          <Link
            to="/contact-us"
            state={{ previousLocation: location?.pathname }}
          >
            <button>Get This Contest</button>
          </Link>
        </div>
      </div>
      <div className={styles.facts}>
        <div className="max-w-[1280px] h-full w-full  lg:px-[78px] py-[20px] mx-auto flex flex-col items-center">
          <h2 className="mb-[26px] px-[20px]">{data?.facts?.heading}</h2>
          <p className="w-full md:w-[50%] px-[20px]">
            {data?.facts?.description}
          </p>
          <div className="flex lg:grid grid-cols-4 w-full gap-[24px] overflow-auto hideScrollBar p-[20px]">
            {data?.facts?.cards?.map((obj, index) => (
              <div
                className={styles.card}
                key={index}
                style={{ backgroundColor: obj?.bgColor }}
              >
                <div className="p-[22px]">
                  <h3 className="mb-[10px]">{obj?.heading}</h3>
                  <p>{obj?.description}</p>
                </div>

                <img src={obj?.graphics} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-[#fff] py-[24px] lg:py-[70px]">
        <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
          <h1 className={styles.usesHeading}>{data?.usesHeading}</h1>
          <div className={styles.uses}>
            {data?.uses?.map((obj, index) => (
              <div className="min-w-[80%]" key={index}>
                <div className="flex flex-col items-center gap-[16px] relative">
                  <div className="bg-[#6c6a90] rounded-full w-[24px] h-[24px] shrink-0 mx-[4%]" />
                  <h4>{obj?.heading}</h4>
                  {index + 1 < data?.uses?.length && (
                    <div className="w-[50%] bg-[#F3F0FF] rounded-[21px] h-[3px] absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2" />
                  )}
                </div>
                <div className="mt-[70px]">
                  {obj?.items?.map((el) => (
                    <div className="w-full p-[10px] bg-[#f3f0ff] text-[#161616] text-center mb-[16px] rounded-[8px]">
                      <p>{el}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-[#d3f2fe66] py-[24px] lg:py-[70px]">
        <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
          <div className={styles.videoSection}>
            <h2>{data?.video?.heading}</h2>
            <p className="my-[20px] lg:mb-[46px]">{data?.video?.subHeading}</p>
            <div className="w-full rounded-[24px] aspect-video">
              <ReactPlayer
                config={{
                  file: {
                    attributes: { controlsList: "nodownload" },
                  },
                }}
                playing={false}
                controls={true}
                url={data?.video?.url}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
