import React from 'react'
import TopGrid from '../../assests/topBanner.png'
import TopGridMobile from '../../assests/MobileView390.png'
import styles from './styles/TopGrid.module.css'

function TopBanner() {
  return (
    <div className=' mb-[60px] md:mb-[120px]'>
      <div className='relative lg:mt-[75px] md:mt-[59px]'>
        <img className={`${styles.topBanner} `} src={TopGrid} alt="" />
        <p className={styles.bannerTxt}>We help the Talents to Go Viral with the different types of contests launched by the organizers from various Industries like movie, fashion, travel, food etc.
          Participate to stand out from the crowd and Go Viral.</p>
      </div>
      <div>
        {/* <img className={`${styles.topBanner} mobileOnly`} src={TopGridMobile} alt="" /> */}
      </div>

    </div>
  )
}

export default TopBanner